import './workspace.scss';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import CanvasHeader from '../../../components/canvasHeader/CanvasHeader';
import CanvasSearch from '../../../components/canvasSearch/CanvasSearch';
import ContextMenu from '../../datamanage/storage/ContextMenu';
import CustomModal from '../../../common/CustomModal';
import { ShareModal } from '../../../rete/workspace-panel.component';

import { CustomThemeContext } from '../../../utils/Context';
import { useAPIwithCookies } from '../../../hooks/useApiCookies';

import { TCategoryType, IWorkspaceList, TWorkspaceID } from './workspace';
import EmptyDiv from '../../mlops/train-detail/EmptyDiv';
import LeftPanel from '../../../components/leftPanel/LeftPanel';
import PrimaryButton from '../../../components/button/PrimaryButton';

export default function MyWorkspace() {
    const navigate = useNavigate();
    const api = useAPIwithCookies();
    const categoryList: TCategoryType[] = ['All', 'My', 'Shared', 'Archived', 'Trashed'];
    
    const [category, setCategory] = useState<TCategoryType>('All');
    const [workspaceList, setWorkspaceList] = useState<IWorkspaceList[]>([]);
    const [contextTarget, setContextTarget] = useState<TWorkspaceID>('');
    const [contextOpen, setContextOpen] = useState<boolean>(false);
    const [contextEvent, setContextEvent] = useState<any>();
    const [shareOpen, setShareOpen] = useState<boolean>(false);
    const [shareTarget, setShareTarget] = useState<string>('');

    const { theme } = useContext(CustomThemeContext);

    const getWorkspaceList = async () => {
        const response = await api.get(`/workspace/list/${category.toLowerCase()}`);
        setWorkspaceList(response.data);
    }

    const createNewWorkspace = async () => {
        const response = await api.post('/workspace/new');
        navigate(`/vience-canvas/canvas/${response.data}`);
    }

    const copyWorkspace = async (target: string) => {
        await api.post(`/workspace/copy/${target}`);
        getWorkspaceList();
    }

    const deleteWorkspace = async (target: string) => {
        try {
            await api.delete(`/workspace/delete/${target}`);
            getWorkspaceList();
        } catch ({ detail }: any) {
            if (detail === 'Permission Denied') {
                alert('You don\'t have permission to delete project')
            }
        }
    }

    const archiveWorkspace = async (target: string) => {
        await api.post(`/workspace/archive/${target}`);
        getWorkspaceList();
    }

    useEffect(() => {
        getWorkspaceList();
    }, [category])

    const setContext = (e: any, wor_id: TWorkspaceID) => {
        setContextOpen(true);
        setContextEvent(e);
        setContextTarget(wor_id);
    }
    
    const [workspaceModalOpen, setWorkspaceModalOpen] = useState<boolean>(false);
    const handleWorkspaceModal = () => {
        setWorkspaceModalOpen(!workspaceModalOpen);
    }

    const [keyword, setKeyword] = useState('');
    const [filteredList, setFilteredList] = useState<any[]>([]);

    useEffect(() => {
        if (keyword.length) {
            setFilteredList( workspaceList.filter(v => v.title?.toLowerCase().includes(keyword.toLowerCase())) );
        } else {
            getWorkspaceList();
            setFilteredList([]);
        }
    }, [keyword])

    return (
        <div className='workspace-container'
            onContextMenu={(e) => { e.preventDefault(); }}
        >
            <CanvasHeader />
            <div className="main-content">
                <LeftPanel className='workspace-menu left'>
                    <PrimaryButton onClick={handleWorkspaceModal} id='workspace-new' className="btn-primary">New Workspace</PrimaryButton>

                    <CustomModal open={workspaceModalOpen} onClose={handleWorkspaceModal} bgShown='blur' bgColor='#010116'>
                        <div className='workspace-option-frame'>
                            <strong>Workspace option</strong>
                            {/* 링크 교체 */}
                            <Link to="/vience-canvas/template">
                                <button className='fill-white-outline'>template로 시작</button>
                            </Link>
                            <div className='workspace-link-btn' onClick={createNewWorkspace}>
                                <button className='fill-primary'>빈 workspace로 시작</button>
                            </div>
                        </div>
                    </CustomModal>

                    <div className='workspace-category-container'>
                        <ul>
                            {
                                categoryList.map(data =>
                                    <li 
                                        className={category === data ? 'selected' : ''}>
                                        <button
                                            className='workspace-category-card'
                                            id={`workspace-category-${data}`}
                                            onClick={() => { setCategory(data) }}>
                                            {data} Workspace
                                        </button>
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                </LeftPanel>

                <div className="workspace-card-right">
                    <div id="workspace-card-container" className="inner"
                        onClick={() => { setContextOpen(false); }}
                    >
                        <CanvasSearch 
                            setKeyword={setKeyword}
                        />
                        <div className="workspace-card-content">
                            <ul>
                                {
                                    keyword.length 
                                    ? 
                                        filteredList.length 
                                        ?
                                        filteredList.map(workspace => {
                                            let title;
                                            if (keyword) {
                                            const index = workspace.title.toLowerCase().indexOf(keyword.toLowerCase());
                                            title = (
                                                <strong>
                                                    {workspace.title.slice(0, index)}<span style={{color: '#8D59FC'}}>{workspace.title.slice(index, index + keyword.length)}</span>{workspace.title.slice(index + keyword.length)}
                                                </strong>
                                                )
                                            }
                                            return (
                                                <li onContextMenu={(e) => { setContext(e, workspace.wor_id) }}>
                                                    <Link to={`/vience-canvas/canvas/${workspace.wor_id}`}>
                                                        <div className="workspace-card-text">
                                                            <div className="workspace-card-title">
                                                                <img src={process.env.PUBLIC_URL + theme === 'dark' ? '/images/ico-tree.svg' : '/images/ico-tree-black.svg'} alt="" className="" />
                                                                <strong>
                                                                    { keyword.length ? title : workspace.title }
                                                                </strong>
                                                            </div>
                                                            <div className="workspace-card-date">
                                                                {workspace.updated_at && <span>{workspace.updated_at.slice(0, 10)} {workspace.updated_at.slice(11, 16)}</span>}
                                                                <span className='workspace-category'>{workspace.category}</span>
                                                            </div>
                                                        </div>
                                                        <div className="workspace-card-img">
                                                            <img src={process.env.PUBLIC_URL + theme === 'dark' ? '/images/test-img.png' : '/images/test-img-white.png'} alt="" className="" />
                                                        </div>
                                                    </Link>
                                                </li>
                                                )
                                            }
                                        )
                                        : <EmptyDiv label='검색결과 없음' /> 
                                    : workspaceList.length 
                                        ?
                                        workspaceList.map(workspace =>
                                            <li onContextMenu={(e) => { setContext(e, workspace.wor_id) }}>
                                                <Link to={`/vience-canvas/canvas/${workspace.wor_id}`}>
                                                    <div className="workspace-card-text">
                                                        <div className="workspace-card-title">
                                                            <img src={process.env.PUBLIC_URL + theme === 'dark' ? '/images/ico-tree.svg' : '/images/ico-tree-black.svg'} alt="" className="" />
                                                            <strong>{workspace.title}</strong>
                                                        </div>
                                                        <div className="workspace-card-date">
                                                            {workspace.updated_at && <span>{workspace.updated_at.slice(0, 10)} {workspace.updated_at.slice(11, 16)}</span>}
                                                            <span className='workspace-category'>{workspace.category}</span>
                                                        </div>
                                                    </div>
                                                    <div className="workspace-card-img">
                                                        <img src={process.env.PUBLIC_URL + theme === 'dark' ? '/images/test-img.png' : '/images/test-img-white.png'} alt="" className="" />
                                                    </div>
                                                </Link>
                                            </li>
                                        )
                                        : <EmptyDiv label='워크스페이스 없음' /> 
                                    }
                            </ul>
                            {contextOpen &&
                                <ContextMenu containerId='workspace-card-container' event={contextEvent} onClose={() => { setContextOpen(false) }}
                                    functions={[
                                        { label: 'delete', on: () => { deleteWorkspace(contextTarget) } },
                                        { label: 'copy', on: () => { copyWorkspace(contextTarget) } },
                                        { label: 'archive', on: () => { archiveWorkspace(contextTarget) } },
                                        { label: 'share', on: () => { setShareOpen(true) } }
                                    ]} />
                            }
                        </div>
                    </div>
                </div>

            </div>
            <ShareModal open={shareOpen} onClose={() => { setShareOpen(false) }} wor_id={shareTarget} />
        </div >
    )
}