// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button {
  background: transparent;
}
button > div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--10px);
}
button > div img {
  display: block;
}
button > div span {
  color: #F8F2FF;
  font-size: var(--16px);
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/components/mode/mode.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;AACJ;AAAI;EACI,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,gBAAA;AAER;AADQ;EACI,cAAA;AAGZ;AAAQ;EACI,cAAA;EACA,sBAAA;EACA,gBAAA;AAEZ","sourcesContent":["button {\n    background: transparent;\n    > div {\n        display: flex;\n        align-items: center;\n        justify-content: flex-start;\n        gap: var(--10px);\n        img {\n            display: block;\n    \n        }\n        span {\n            color: #F8F2FF;\n            font-size: var(--16px);\n            font-weight: 500;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
