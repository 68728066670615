import React from "react";
import { ClassicPreset, NodeId } from "rete";
import { CustomSocket, NodeSize, ResizeColor } from "../../style";
import CustomControl from "../../style/CustomControl";
import { ILayerOption, IMlopsflow } from "../flow";

export class ConvTranspose2dNode extends ClassicPreset.Node<
  { in: ClassicPreset.Socket },
  { out: ClassicPreset.Socket },
  { ctrl: ConvTranspose2dControl }
> {
  color = ResizeColor;
  width = NodeSize.width;
  height = NodeSize.height;
  error = '';
  
  constructor(
    process: () => void, 
    update: (control: ConvTranspose2dControl) => void, 
    nodeId: NodeId
  ) {
    super('ConvTranspose 2D');
    this.id = nodeId === "" ? this.id : nodeId;
    this.addInput('in', new ClassicPreset.Input(new CustomSocket()));
    this.addOutput('out', new ClassicPreset.Output(new CustomSocket()));
    this.addControl('ctrl', new ConvTranspose2dControl(
      process, 
      update,
      this.id
    ));
  }
  data(input): { out: IMlopsflow } {
    let output: IMlopsflow = {
      datasetPath: {},
      layer: {
        input: [],
        node: {
          "convTranspose2d": this.controls.ctrl.props.option
        },
      }
    };

    if (input.in) {
      const inputIn = input?.in ?? [undefined];
      const inputNode = inputIn[0];

      if (inputNode){
        const data1_key = Object.keys(inputNode.layer.node)[0];
        if(data1_key!=="dataset"){
          if(inputNode.layer.node[data1_key].outChannel){
            this.controls.ctrl.props.option.inChannel = inputNode.layer.node[data1_key].outChannel;
          }
          else if(inputNode.layer.node[data1_key].numFeatures){
            this.controls.ctrl.props.option.inChannel = inputNode.layer.node[data1_key].numFeatures;
          }
        }
      }

      if (inputNode && inputNode.datasetPath) {
        const data1_key = Object.keys(inputNode.layer.node)[0];

        let convertedResolution = (
          inputNode.layer.node[data1_key].datasetResolution 
          * Number(this.controls.ctrl.props.option.stride? this.controls.ctrl.props.option.stride : 2)
          ) - Number(this.controls.ctrl.props.option.kernelSize? this.controls.ctrl.props.option.kernelSize: 3) 
          + (2 * Number(this.controls.ctrl.props.option.padding? this.controls.ctrl.props.option.padding : 0)) + 1;
        
        if (Number.isNaN(convertedResolution)) {
          convertedResolution = 66
        }

        if (convertedResolution < inputNode.layer.node[data1_key].datasetResolution) {
          this.error = 'Resolution does not change as expected';
        } else {
          this.error = '';
        }
  
        this.controls.ctrl.props.option.datasetResolution = convertedResolution;
        this.controls.ctrl.datasetResolution = convertedResolution;
        if (localStorage.getItem('dynamic') === 'true') {
            this.width = (
            50 
            * Math.log2(Number(this.controls.ctrl.props.option.outChannel))) < 222 
          ? 222 
          : (50 * Math.log2(Number(this.controls.ctrl.props.option.outChannel)));
          this.height = convertedResolution < 66 ? 66 : convertedResolution;
        }
        output = {
          datasetPath: inputNode?.datasetPath ?? '',
          layer: {
            input: [
              inputNode?.layer
            ],
            node: {
              "convTranspose2d": this.controls.ctrl.props.option
            }
          }
        }

      } 
    }
    
    return {
      out: output
    }
  }
}

export class ConvTranspose2dControl extends ClassicPreset.Control {
  contextOpen: boolean;
  props: {
    option: ILayerOption;
  };
  modelFlow: IMlopsflow;
  datasetResolution: number;

  constructor(
    public onChange: () => void, 
    public update: (c: ConvTranspose2dControl) => void, 
    public nodeId: NodeId
  ) {
    super();
    this.contextOpen = false;
    this.props = {
      option: {
        nodeId,
        name: 'ConvTranspose2d',
        kernelSize: 3,
        stride: 2,
        padding: 0,
        bias: true,
        inChannel: 1,
        outChannel: 1,
      }
    };
    this.modelFlow = {
      datasetPath: {undefined},
      layer: undefined,
    };
    this.datasetResolution = 66;
  }

  setValue(data: ILayerOption) {
    this.props.option = data;
    this.onChange();
  }

  setContextOpen = (open: boolean) => {
    this.contextOpen = open;
    this.update(this);
  }
}

export function ConvTranspose2dComponent({ data }: { 
  data: ConvTranspose2dControl 
}) {
  if (!data.nodeId) return <p>Error</p>;

  return (
    <CustomControl 
      nodeId={data.nodeId}
      label="ConvTranspose 2D" 
      iconSource="denoising" 
      markerSource='node-effector' 
      contextOpen={data.contextOpen}
      setContextOpen={data.setContextOpen}
    />
  )
}