import React, { useEffect } from "react";
import { ClassicPreset } from "rete";
import { IDataflow } from "../../nodes/flow";
import { AddonSocket, CustomSocket } from "../../sockets";
import { SinkColor } from "../../style/CustomNode";
import CustomControl from "../../style/CustomControl";
import { updateControl } from "../../../reteUtils";
import { NodeSize } from "../../style/CustomNode";
import { GearNode, TGearProps } from "../addon/Gear";
import API from "../../../../utils/api";
import { useCookies } from "react-cookie";
import { editor } from "../../../rete";
import { useParams } from "react-router-dom";

export class ViewerNode extends ClassicPreset.Node<
    { in: ClassicPreset.Socket, addon: ClassicPreset.Socket },
    {},
    { ctrl: ViewerControl }
> {
    color = SinkColor;
    width = NodeSize.width;
    height = NodeSize.height;

    constructor(nodeId?: string) {
        super('Viewer');
        this.addInput('addon', new ClassicPreset.Input(new AddonSocket()));
        this.addInput('in', new ClassicPreset.Input(new CustomSocket()));
        this.addControl('ctrl', new ViewerControl(nodeId ? nodeId : this.id));
    }

    data(inputs: { in: IDataflow[], addon: IAddonflow[] }) {
        if (inputs['in']) {
            const value = inputs['in'][0];
            if (this.controls.ctrl.props.option !== value) {
                this.controls.ctrl.setValue(value);
            }
        }
        if (inputs['addon']) {
            const addon = inputs['addon'][0];
            this.controls.ctrl.setAddon({ ...addon, enable: true });
            console.log(addon);
            if (addon.name === 'gear' && (addon.option as TGearProps).save) {
                updateControl(this.controls.ctrl.id);
            }
        } else{
            this.controls.ctrl.setAddon({name: '', option: {}, enable: false });
        }
        return {}
    }
}

interface IAddonflow {
    name: string;
    option: {};
    enable: boolean;
}

export class ViewerControl extends ClassicPreset.Control {
    props: {
        option: IDataflow | undefined;
        [key: string]: any;
    }
    addon: IAddonflow;
    contextOpen: boolean;

    constructor(public nodeId: string) {
        super();
        this.props = {
            option: undefined
        };
        this.addon = {
            name: '',
            option: {},
            enable: false
        }
        this.contextOpen = false;
    }

    setValue(val: IDataflow) {
        this.props.option = val;
    }
    setAddon(val: IAddonflow) {
        this.addon = val;
    }
    setContextOpen = (open: boolean) => {
        this.contextOpen = open;
        updateControl(this.id);
    }
}

export function ViewerComp({ data }: { data: ViewerControl }) {
    const [cookies] = useCookies(['refresh']);
    const api = new API(cookies);
    const params = useParams();

    const createGear = async (addon: IAddonflow) => {
        const ctrlAddon = addon.option as TGearProps;
        if (!ctrlAddon.save) return;
        const response = await api.post(`/module/mod_seq`, {
            wor_id: params.wor_id,
            effector: data.props.option?.effector
        });
        try {
            await api.post('/gear/new', {
                mod_seq: response.data,
                name: ctrlAddon.name,
                description: ctrlAddon.description
            })
        } catch (err) {
            alert('이미 등록되어 있는 기어입니다.');
        } finally {
            const conns = editor.getConnections();
            conns.forEach(el => {
                if (el.sourceOutput === 'addon' && el.target === data.nodeId) {
                    const addonNode = editor.getNode(el.source) as GearNode;
                    addonNode.controls.ctrl.setValue({ ...data.addon.option as TGearProps, save: false })
                }
            })
        }
    }

    useEffect(() => {
        const addon = data.addon;
        if (addon.name === 'gear' && addon.enable) createGear(addon)
    }, [data, params])

    return (
        <CustomControl contextOpen={data.contextOpen} setContextOpen={data.setContextOpen} nodeId={data.nodeId} label="Viewer" iconSource="viewer" markerSource="node-sink" />
    )
}
