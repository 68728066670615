import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  position: fixed;
  top: 10vh;
  right: 0;
  z-index: 100;
  
  width: 20vw;
  height: 73vh;
  padding: var(--24px);
  background-color: #b47ef7;
  border-radius: var(--12px);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--24px);

  button.feedback-close-btn {
    display: block;
    width: 100%;
    padding: var(--12px);
    border-radius: var(--12px);

    font-weight: bold;
    background-color: white;
    color: black;
  }
`

export default function Feedback({feedback, setFeedback}) {
  
  return (
    <Container>
      {feedback}
      <button 
        className='feedback-close-btn' 
        onClick={() => setFeedback('')}
      >
        close
      </button>
    </Container>
  )
}
