import React, { useEffect, useState } from 'react';

import OptionInput from '../PanelOptionComponent/OptionInput';
import CustomSelect from '../PanelOptionComponent/CustomSelect';

export default function FVOptionSection({ nodeId, initOption, option, setOption, loading, setLoading }) {
  const [nodeName, setNodeName] = useState<string>('');
  const [method, setMethod] = useState('');
  const [criteria, setCriteria] = useState('');
  const [theme, setTheme] = useState('');
  
  // 각 input에 초기값 세팅되기 전까지 로딩상태여야함
  useEffect(() => {
    setLoading(true);
    setNodeName('');
    setMethod('');
    setCriteria('');
    setTheme('');
  }, [nodeId])

  useEffect(() => {
    if (!loading) {
      setOption({
        ...option, 
        name: nodeName,
        method,
        criteria,
        theme,
      })
    }
  }, [
    nodeName,
    method,
    criteria,
    theme,
  ]);

  return (
    <form>
      <OptionInput initOption={initOption} setValue={setNodeName} id={"name"} label='Name of Node' setLoading={setLoading} type='text'/>
      <CustomSelect
          id={'method'}
          setValue={setMethod}
          label={'Projection Method'}
          options={['PCA','T-SNE','UMAP',]}
          initOption={initOption}
          setLoading={setLoading} />
      <p>Color Mapping</p>
      <CustomSelect
          id={'criteria'}
          setValue={setCriteria}
          label={'Criteria'}
          options={['Label','Prediction','Accuracy',]}
          initOption={initOption}
          setLoading={setLoading} 
          flexDirection='row'/>
      <CustomSelect
          id={'theme'}
          setValue={setTheme}
          label={'theme'}
          options={['viridis','Plasma','Pastel','jet', 'prism']}
          initOption={initOption}
          setLoading={setLoading} 
          flexDirection='row'/>
    </form>
  )
}
