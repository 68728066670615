import React, { useEffect, useState } from 'react'
import OptionInput from '../PanelOptionComponent/OptionInput';
import OptionInputToggle from '../PanelOptionComponent/OptionInputToggle';
import { isInt, isPositive } from '../PanelOptionComponent/inputUtils';
import OptionInputArray from '../PanelOptionComponent/OptionInputArray';

export default function Convolution2dOptionSection({ nodeId, initOption, setOption, loading, setLoading }) {
  const [nodeName, setNodeName] = useState<string>('');
  const [inChannel, setInChannel] = useState<number>(1);
  const [outChannel, setOutChannel] = useState<number>(1);
  const [kernelSize, setKernelSize] = useState<number>(3);
  const [stride, setStride] = useState<number>(1);
  const [padding , setPadding] = useState<number>(1);
  const [bias , setBias] = useState<boolean>(false);
  
  // 각 input에 초기값 세팅되기 전까지 로딩상태여야함
  useEffect(() => {
    setLoading(true);
    setNodeName('');
    setInChannel(1);
    setOutChannel(1);
    setKernelSize(3);
    setStride(1);
    setPadding(1);
    setBias(false);
  }, [nodeId])

  useEffect(() => {
    if (!loading) {
      setOption((prev) => {
          return {
            ...prev,
            name: nodeName,
            inChannel, outChannel,
            stride, padding,
            kernelSize, bias
          }
      })
    }
  }, [
    nodeName,
    inChannel, outChannel, 
    kernelSize, bias,
    stride, padding, 
  ]);

  return (
    <form name='convolution2dOption'>
      <OptionInput
        initOption={initOption}
        setValue={setNodeName}
        id={"name"}
        label='Name of Node'
        setLoading={setLoading}
        type='text'
      />
      <OptionInput
        initOption={initOption}
        setValue={setInChannel}
        id={"inChannel"}
        label='In Channel'
        setLoading={setLoading}
        isValidInput={isInt}
      />
      <OptionInput
        initOption={initOption}
        setValue={setOutChannel}
        id={"outChannel"}
        label='Out Channel'
        setLoading={setLoading}
        isValidInput={isInt}
      />
      <OptionInput 
        id="kernelSize" label='Kernel Size' 
        initOption={initOption}
        setValue={setKernelSize} 
        setLoading={setLoading} 
        isValidInput={isPositive}
      />
      <OptionInput 
        id="stride" label='Stride' 
        initOption={initOption}
        setValue={setStride} 
        setLoading={setLoading} 
        isValidInput={isPositive}
      />
      <OptionInput 
        id="padding" label='Padding' 
        initOption={initOption}
        setValue={setPadding} 
        setLoading={setLoading} 
        isValidInput={isPositive}
      />
      <OptionInputToggle
        initOption={initOption}
        setValue={setBias}
        id={"bias"}
        label='Bias'
      />
    </form>
  )
}
