import React, { useState, useEffect } from "react";
import { ClassicPreset, NodeId } from "rete";
import { area } from "../../../rete";
import { INodeData } from "../../filter";
import { AddonSocket } from "../../sockets";
import { InputWithLabel } from "../../../../common/CustomInput";
import CustomTextarea from "../../../../common/CustomTextarea";
import { AddonColor } from "../../style/CustomNode";
import { NodeSize } from "../../style/CustomNode";
import CustomControl from "../../style/CustomControl";
import { updateControl, updateNode } from "../../../reteUtils";
import { useCookies } from "react-cookie";
import API from "../../../../utils/api";
import { IGearList } from "../../../../routes/datamanage/storage/storage.type";
import '../../../side-panel/side-panel.styles.scss';
import './Gear.scss';

export type TGearProps = {
    name: string;
    description: string;
    save: boolean;
}

export class GearNode extends ClassicPreset.Node
    <
        {},
        { addon: ClassicPreset.Socket },
        { ctrl: GearControl }
    >
{
    color = AddonColor;
    width = NodeSize.width;
    height = NodeSize.height;
    // nodeTheme = 'dark';

    constructor(process: () => void, nodeId?: NodeId) {
        super('Gear');
        this.addOutput('addon', new ClassicPreset.Input(new AddonSocket()));
        this.addControl('ctrl', new GearControl(process, nodeId ? nodeId : this.id));
    }
    data(): { addon: { name: string, option: TGearProps } } {
        // const in1 = inputs['in1'][0];
        // const in2 = inputs['in2'][0];
        // if (this.controls.ctrl.props.option1 !== in1 || this.controls.ctrl.props.option2 !== in2) {
        //     this.controls.ctrl.setValue({ option1: in1, option2: in2 });
        // }
        // if (this.update) this.update(this.controls.ctrl);
        console.log('addon output => ', this.controls.ctrl.props.option);
        return ({
            addon: {
                name: 'gear',
                option: {
                    name: this.controls.ctrl.props.option.name,
                    description: this.controls.ctrl.props.option.description,
                    save: this.controls.ctrl.props.option.save
                }
            }
        })
    }
    // setTheme(theme: 'dark' | 'light') {
    //     this.nodeTheme = theme;
    //     updateNode(this.id);
    // }
}

export class GearControl extends ClassicPreset.Control {
    props: {
        option: TGearProps
    }
    contextOpen: boolean;
    // theme: 'dark' | 'light';
    type: string;

    constructor(public onChange: () => void, public nodeId: NodeId) {
        super();
        this.props = {
            option: {
                name: 'untitled',
                description: '',
                save: false,
            }
        }
        this.contextOpen = false;
        this.type = 'GearControl';
        // this.theme = 'dark';
    }

    setValue({ name, description, save }: TGearProps) {
        this.props.option = {
            name: name,
            description: description,
            save: save
        }
        this.onChange();
    }

    setContextOpen = (open: boolean) => {
        this.contextOpen = open;
        updateControl(this.id);
    }
    // setTheme = (theme: 'dark' | 'light') => {
    //     this.theme = theme;
    //     updateControl(this.id);
    // }
}

export function GearComp({ data }: { data: GearControl }) {
    return (
        <CustomControl contextOpen={data.contextOpen} setContextOpen={data.setContextOpen} label="Gear" nodeId={data.nodeId} markerSource={'node-addon'} iconSource="gear" />
    )
}
interface GearPaenlProps {
    ctrl: GearControl;
    expand: boolean;
}



export const GearPanel = ({ ctrl, expand }: GearPaenlProps) => {
    const [cookies, setCookie] = useCookies(['refresh']);
    const api = new API(cookies);

    const [gearOption, setGearOption] = useState<TGearProps>({ name: '', description: '', save: false });
    const [gearList, setGearList] = useState<IGearList[]>([]);

    // Gear option 저장 (save : true)
    const saveGear = () => {
        ctrl.setValue({ ...gearOption, save: true });
    }

    // 생성되어 있는 Gear List
    const getGearList = async () => {
        const response = await api.get('/gear/list');
        setGearList(response.data);
    }

    // Node에 저장되어 있는 Option 불러오기
    const loadData = () => {
        setGearOption(ctrl.props.option);
    }

    useEffect(() => {
        loadData();
    }, [ctrl.id])


    useEffect(() => {
        getGearList();
    }, [])

    return (
        <>
            <div className="side-panel-normal">
                <div>
                    <p>Gear</p>
                    <div>
                        <InputWithLabel value={gearOption.name} label="Gear Name" onChange={(e) => { setGearOption({ ...gearOption, name: e }) }} />
                        <CustomTextarea value={gearOption.description} label="Gear Description" onChange={(e) => { setGearOption({ ...gearOption, description: e }) }} />
                        <button onClick={saveGear} className="save-gear">Save Gear</button>                    
                    </div>
                </div>
            </div>

            {/* Gear 삭제 추가 필요 */}
            <div className="side-panel-expand">
                <div id='gear-container'>
                    {gearList.map(data =>
                        <div className="gear-card">
                            {data.name}
                            {data.created_at.slice(0, 10)}
                            Gear 설명 ~ 추가 예정
                        </div>
                    )}
                </div>
            </div>
        </>

        // <InputWithLabel value={gearOption.name} label="Gear Name" onChange={(e) => { setGearOption({ ...gearOption, name: e }) }} />
        // <CustomTextarea value={gearOption.description} label="Gear Description" onChange={(e) => { setGearOption({ ...gearOption, description: e }) }} />
        // <button onClick={saveGear}>Save Gear</button>
    )
}