import React from 'react'
import FVScatterPlot from './FVGraph/FVScatterPlot';

const mockTrainData = [
  [10, 10],
  [-10, -10],
  [-10, -6],
  [1, 2],
  [0, -4],
  [-10, -6],
  [0,0]
];

export default function FVGraphSection() {
  return (
    <FVScatterPlot 
      width={310}
      height={400}
      data={mockTrainData.map(v => ({x: v[0], y: v[1]}))}
    />
  )
}
