import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const AnimationWrapper = styled.div`
  position: relative;
  flex-shrink: 0;
  width: var(--24px);
  aspect-ratio: 1/1;
  margin-left: calc(var(--24px) * (-1));

  @keyframes rotate {
    0% {
      transform: translateY(-50%) rotate(0);
    } 100% {
      transform: translateY(-50%) rotate(360deg);
    }
  }
  
  p#timer-state,
  div#timer-circle {
    position: absolute;
    display: inline-block;
    width: var(--24px);
    aspect-ratio: 1/1;
    top: 50%;
    transform: translateY(-50%);
  }

  p#timer-state {
    line-height: var(--24px);
    font-size: var(--12px);
    color: #686868;
  }

  div#timer-circle {
    border: 4px dashed #686868;
    border-radius: 100%;
    animation: rotate 2s linear forwards infinite;
  }
`

export default function TrainingAnimation() {
  const timerSec = useRef(0);
  const [timerState, setTimerState] = useState(0);

  useEffect(() => {
    let startTimer = setInterval(() => {
      timerSec.current = (timerSec.current + 1) % 3;
      setTimerState(3 - timerSec.current);
    }, 1_000)

    return () => clearInterval(startTimer);
  }, [])

  return (
  <AnimationWrapper className='timer-wrapper'>
    <div id="timer-circle" />
    <p id='timer-state'>{timerState}</p>
  </AnimationWrapper>)
}