import React from 'react'
import styled from 'styled-components'

const TemplateModalDescdiv = styled.div`
  align-self: flex-start;

  ul {
    margin-top: var(--8px);
  }
`

export default function TemplateModalDesc({workspace}) {
  const entries: (string[])[] = Object.entries(workspace?.description ?? [['', '']]) ;

  return (
    <>
      <TemplateModalDescdiv className='description-container'>
        <h4 className='a11y-hidden'>description</h4>
        <ul>
          {
            entries.map(([title, desc]) => {
              return (
                <li>
                  <h5>{title}</h5>
                  <p>{desc}</p>
                </li>
              ) 
            }) ?? <li>undefined description</li>
          }
        </ul>
      </TemplateModalDescdiv>
    </>
  )
}
