import './storage.styles.scss';

import React, { useContext, useState } from 'react';

import StorageLeftPanel from './StorageLeftPanel';
import StorageGearPanel from './StorageGearPanel';
import CanvasHeader from '../../../components/canvasHeader/CanvasHeader';
import StorageMain from './StorageMain';
import LoadingAnimation from '../../../components/loadingAnimation/LoadingAnimation';
import ProgressCircleDiv from '../../../components/progressCircle/ProgressCirclediv';

import { IGearList, TstorageType } from './storage.type';
import { StorageContext } from '../../../context/StorageContext';

export default function Storage() {
    //gear
    const [gearList, setGearList] = useState<IGearList[]>([]);
    const [storageType, setStorageType] = useState<TstorageType>('All');
    const storageTypeList: TstorageType[] = ['All', 'Favorite', 'My', 'Processing', 'Shared', 'Archived', 'Trahsed'];
    
    // storageContext
    const { 
        dirLoading, 
        loading, 
        progress 
    } = useContext(StorageContext)

    return (
        <div className="storage">
            <CanvasHeader />
            <div id='storage-grid'
                onContextMenu={(e) => {
                    e.preventDefault();
                }}
            >
                <StorageLeftPanel 
                    storageTypeList={storageTypeList}
                    storageType={storageType}
                    setStorageType={setStorageType}
                />
                {dirLoading && <LoadingAnimation label={'loading...'} />}
                <div className='strage-main-container'>
                    <StorageMain setGearList={setGearList} />
                </div>

                {/* 추후 정리 필요 Gear */}
                <StorageGearPanel gearList={gearList}/>
                {
                    loading 
                    &&
                    <ProgressCircleDiv 
                        label={progress?.filename ?? ''}
                        percentage={Number(progress?.progress.toFixed(2))}
                    />
                }
            </div>
        </div >

    )
}
