import React, { useContext, useState } from 'react';
import './storage.styles.scss';
import { IGearList, dirType } from './storage.type';
import { useCookies } from 'react-cookie';
import API from '../../../utils/api';
import '../../../rete/side-panel/side-panel.styles.scss'
import { useAPIwithCookies } from '../../../hooks/useApiCookies';
import { StorageContext } from '../../../context/StorageContext';

interface StorageModuelPanelProps {
    gearList: IGearList[];
}

export default function StorageGearPanel({ gearList }: StorageModuelPanelProps) {
    const api = useAPIwithCookies();
    const {curDir, getDirectory} = useContext(StorageContext);

    const [selectedGear, setSelectedGear] = useState<IGearList>({ name: '', gear_seq: 0, created_at: '' });
    const [processed, setProcessed] = useState<number>(0);

    const getTargetDirectory = async (target: dirType) => {
        const response = await api.get('/cloud/list', {
            cur_dir: target
        })
        setProcessed(response.data.length);
    }

    const runGear = async () => {
        const response = await api.post('/gear/run', {
            gear_seq: selectedGear.gear_seq,
            target: curDir
        })

        const interval = setInterval(() => {
            getTargetDirectory('');
        }, 1000)


        getDirectory();
    }

    return (
        <div id='storage-grid-gear-panel' className="right">
            <div className='side-panel-normal'>
                <p>Gear List</p>
                <div id='storage-gear-list'>
                    {
                        gearList.map(data => {
                            return (
                                <div className='storage-gear'
                                    style={{ background: selectedGear.name === data.name ? 'rgba(81, 0, 255, 0.5)' : 'transparent' }}
                                    onClick={() => { setSelectedGear(data) }}>
                                    {data.name}
                                </div>
                            )
                        })
                    }
                </div>
                <p></p>
                <p></p>
                <p>Selected Gear</p>
                <div id='storage-gear-info'>
                    <p>{selectedGear.name}</p>
                    {/* {selectedGear.description} */}
                    {/* {selectedGear.thumbnail && <div id='storage-gear-thumbnail'>thumbnail이 있을수도 없을수도 있음.</div>} */}
                </div>
                <p></p>
                <p></p>
                <p>Progress</p>
                <div id='storage-gear-handler'>
                    <p>4/5</p>
                    <div id='storage-gear-progress'>
                        <div id='storage-gear-progress-cur'></div>
                    </div>
                    <button onClick={runGear}>run</button>
                </div>
            </div>
        </div>

    )
}
