import React from 'react'

export default function MlopsSidePanelHeader({
  theme, 
  panelMode, 
  setPanelMode
}) {
  
  return (
    <header 
      className='side-panel-header' 
      style={{ width: panelMode === 'expand' ? '100%' : 350 }}
    >
      <p className='panel-title-comm'>Panel size</p>
      <div>
        <button onClick={() => { setPanelMode('expand') }}>
          {
            panelMode === 'expand' ?
              <img src={process.env.PUBLIC_URL + 
                theme === 'dark' 
              ? '/images/panel-icon-max-active.svg' 
              : '/images/panel-icon-max-white-active.svg'} alt="" />
              : <img src={process.env.PUBLIC_URL + theme === 'dark' 
                ? '/images/panel-icon-max.svg' 
                : '/images/panel-icon-max-white.svg'} alt="" />
          }
        </button>
        <button onClick={() => { setPanelMode('normal') }}>
          {
            panelMode === 'normal' ?
              <img src={process.env.PUBLIC_URL + theme === 'dark' 
                ? '/images/panel-icon-middle-active.svg' 
                : '/images/panel-icon-middle-white-active.svg'} 
                alt="" 
              />
              : <img src={process.env.PUBLIC_URL + theme === 'dark' 
                ? '/images/panel-icon-middle.svg' 
                : '/images/panel-icon-middle-white.svg'} 
                alt="" 
              />
          }
        </button>
        <button onClick={() => { setPanelMode('shrink') }}>
            {
              panelMode === 'shrink' ?
                <img src={process.env.PUBLIC_URL + theme === 'dark' 
                  ? '/images/panel-icon-minimum-active.svg' 
                  : '/images/panel-icon-minimum-white-active.svg'} 
                  alt=""
                />
                :
                <img src={process.env.PUBLIC_URL + theme === 'dark' 
                  ? '/images/panel-icon-minimum.svg' 
                  : '/images/panel-icon-minimum-white.svg'} 
                  alt=""
                />
            }
        </button>
      </div>
    </header>
  )
}
