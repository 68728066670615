import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { FindTrainer } from "../../icons/FindTrainer";
import { ResetView1 } from "../../icons/ResetView1";
import { DynamicTrue } from "../DynamicTrue";
import { AlignUnet1 } from "../../icons/AlignUnet";
import { AlignCenter1 } from "../../icons/AlignCenter";
import { DynamicFalse } from "../../icons/DynamicFalse";
import "./style.css";
import Tooltip from "../Tooltip/ToolTip";

interface Props {
  property1:
    | "dynamic-false"
    | "default"
    | "find-trainer"
    | "dynamic-true"
    | "dynamic-true-selected"
    | "reset-view"
    | "align-center"
    | "align-unet"
    | "dynamic-false-selected";
  override?: JSX.Element;
  handleClick?: any;
  checkedItem?: string;
  items?: any[];
}

export function IconBtn({
  property1,
  handleClick,
  checkedItem,
  items,
}: Props): JSX.Element {
  const [isHovering, setIsHovering] = useState(false);
  const [isTipHovering, setIsTipHovering] = useState(false);
  
  const closeToolTip = () => {
    setTimeout(() => {
      if (!isTipHovering) setIsHovering(false);
    }, 300)
  }
  
  useEffect(() => {
    if (!isTipHovering) setIsHovering(false);
  }, [isTipHovering])

  return (
      <>
        <button 
          className={`icon-btn property-1-${property1}`} 
          onClick={handleClick} 
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={closeToolTip}
        >
          {property1 === "default" && <div className="instance-node" />}
    
          {property1 === "align-unet" && <AlignUnet1 className="instance-node" />}
    
          {property1 === "align-center" && <AlignCenter1 className="instance-node" />}
    
          {property1 === "reset-view" && <ResetView1 className="instance-node" />}
    
          {property1 === "find-trainer" && <FindTrainer className="instance-node" />}
    
          {property1 === "dynamic-false" && <DynamicFalse className="dynamic-false" />}
    
          {property1 === "dynamic-true" && <DynamicTrue className="dynamic-true" />}
    
          {["dynamic-false", "dynamic-true"].includes(property1) && (
            <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L5 5L9 1" stroke="#65646A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          )}

          {isHovering && 
              <Tooltip 
                property={property1} 
                handleClick={handleClick} 
                checkedItem={checkedItem}
                setIsTipHovering={setIsTipHovering}
                items={items}
              />
          }
        </button>
      </>
  );
};

IconBtn.propTypes = {
  property1: PropTypes.oneOf([
    "dynamic-false",
    "default",
    "find-trainer",
    "dynamic-true",
    "dynamic-true-selected",
    "reset-view",
    "align-center",
    "align-unet",
    "dynamic-false-selected",
  ]),
};
