import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import DatasetOptionSection from './DatasetOptionSection';

import { type DatasetControl } from '../../nodes/DatasetNode';
import { ILayerOption } from '../../flow';


const StyledNormalDiv = styled.div`
    h3 {
        font-size: inherit;
    }

    .data-explorer {
        max-height: 300px;
        overflow: auto;
    }
`

export function DatasetPanelContent({ ctrl }: { 
    ctrl: DatasetControl 
}) {
    const [loading, setLoading] = useState(true);

    // down
    const initOption = useRef<ILayerOption>({});
    // up
    const [option, setOption] = useState<ILayerOption>({});
    
    // 선택된 ctrl.id 바뀔때마다 ctrl에 저장된 옵션으로 바꾸기
    useEffect(() => {
        setLoading(true);

        setOption(ctrl.props.option);
        initOption.current = ctrl.props.option;

        setLoading(false);
    }, [ctrl.nodeId]);

    // 옵션이 바뀌면 ctrl에 저장
    useEffect(()=>{
        if (!loading) {
        ctrl.setValue(option);
        }
    },[option]);
    
    return (
        <>
            <StyledNormalDiv className='side-panel-normal'>
                <DatasetOptionSection
                    nodeId={ctrl.nodeId} 
                    initOption={initOption} 
                    setOption={setOption} 
                    loading={loading} 
                    setLoading={setLoading}
                />
            </StyledNormalDiv >  
            <div className='side-panel-expand' id='#storage-grid'>
                <div className="inner">
                
                </div>
            </div> 
        </>
    )
}
