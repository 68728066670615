import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components';

import DataConvertTab from './data-convert/DataConvertTab';
import TrainResultTab from './train-result/TrainResultTab';
import TrainControl from './train-control/TrainControl';
import TrainOption from './train-option-setting/TrainOption';

import { type TrainerControl } from '../../nodes/TrainerNode'
import { ILayerOption } from '../../flow';

import { CustomThemeContext } from '../../../../utils/Context';
import { TrainContext } from '../../../../context/TrainContext';
import { useLocation } from 'react-router-dom';

const StyledDiv = styled.div`
  input:read-only {
    opacity: .5;
  }

  progress {
    display: block;
  }

  button[class^=trainer] {
    position: relative;

    width: 100%;
    padding: var(--12px);
    margin: var(--12px) 0 ;
    border-radius: 8px;

    background-color: ${(props) => props.$theme === 'light' ? "#fff" : "#181839"};
    font-weight: 700;
    transition: all 0.2s;
    
    &:disabled {
      ${({$theme})=> $theme === 'light' ? `
        color: #7b7b7b;
        background-color: #c1c1c1 ;
        ` : `
        color: #525252;
        background-color:#232335;
      `};
    }
  }

  .train-control button {
    background-color: #b472f7;
    color: white;
  }

  header.trainer-tab-header {
    display: flex;
    gap: 10px;
    padding: 0;
    ${(props) => props.$theme === 'light' ? `
    border: 2px solid #D9D9E5;
    ` : `
    border: 2px solid #1B1B47;
    `};

    button {
      background-color: transparent;
      margin: 0;
      transition: all 0.4s;
    }

    button.active {
      ${(props) => props.$theme === 'light' ? `
      background-color: #D9D9E5;
      ` : `
      background-color: #1B1B47;
      `};
      color: #b472f7;
      border-radius: 0;
    }
  }
`

export function TrainerPanelContent({ ctrl, setPanelMode }: {
  ctrl: TrainerControl;
  setPanelMode: any;
}) {
  // sample?
  const {pathname} = useLocation();
  const [isSample, setIsSample] = useState(pathname.includes("sample"));

  const {theme} = useContext(CustomThemeContext);
  const {setTrainerControl, functions} = useContext(TrainContext);
  const {resetTrainData} = functions;

  const [loading, setLoading] = useState(true);
  const [option, setOption] = useState<ILayerOption>({nodeId: ''});
  const initOption = useRef<ILayerOption>({nodeId: ''});
  
  const [selectedTab, setSelectedTab] = useState('trainResult');

  // 선택된 ctrl.id 바뀔때마다 ctrl에 저장된 옵션으로 바꾸기
  useEffect(() => {
    setLoading(true);

    setOption(ctrl.props.option);
    initOption.current = ctrl.props.option;
    setTrainerControl(ctrl);
    resetTrainData();

    setLoading(false);
  }, [ctrl.nodeId]);

  // 옵션이 바뀌면 ctrl에 저장
  useEffect(() => {
    if (!loading) {
      ctrl.setValue(option);
    }
  }, [option])

  // active 주기
  useEffect(() => {
    const elseButton = document.querySelectorAll('.trainer-tab-header-btn');
    elseButton.forEach((btn)=>{
      if (btn.classList.contains("active")) {
        btn.classList.remove("active");
      }
    })

    const activeButton = document.querySelector(`button.${selectedTab}`);
    activeButton?.classList.add("active");
  }, [selectedTab])

  return (
    <>
      <StyledDiv $theme={theme} className='side-panel-normal'>
        <h3 className='a11y-hidden'>Train Control & Option</h3>
        <TrainControl 
          setPanelMode={setPanelMode} 
          setSelectedTab={setSelectedTab}
        />
        <TrainOption 
          ctrl={ctrl} 
          initOption={initOption} 
          setOption={setOption} 
          loading={loading} 
          setLoading={setLoading} 
        />
      </StyledDiv>

      <StyledDiv className='side-panel-expand' $theme={theme}>
        {!isSample &&<header className='trainer-tab-header'>
          <button 
            type="button" 
            className='trainer-tab-header-btn dataStructureToPython' 
            onClick={() => setSelectedTab("dataStructureToPython")}
          >
            Converted Code
          </button>
          <button 
            type="button" 
            className='trainer-tab-header-btn trainResult'
            onClick={() => setSelectedTab("trainResult")}
          >
            Train Result
          </button>
        </header>}

        {
          selectedTab === "dataStructureToPython" 
          ? <DataConvertTab />
          : <TrainResultTab /> 
        }
      </StyledDiv>
    </>
  )
}
