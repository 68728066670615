import * as React from "react";
import styled from "styled-components";

import { ClassicPreset } from "rete";
import { editor } from "../editor";
import { AddonColor, TNodeColor } from "./CustomNode";

export class CustomSocket extends ClassicPreset.Socket {
  constructor() {
      super('Custom');
  }

  isCompatibleWith(socket: ClassicPreset.Socket) {
      return socket instanceof CustomSocket;
  }
}

const Styles = styled.div<{ color: TNodeColor, dynamic: boolean }>`
  /* socket 인식 범위 */
  display: inline-block;
  cursor: pointer;
  position: relative;
  z-index: 10;

  width: ${props => props.dynamic ? '66px': '12px'};
  aspect-ratio: 1/1;
  
  background-color: transparent;

  /* socket 외양 */
  &::after {
    content: '';
    position: absolute;
    display: block;

    top: calc(50% - 6px);
    left: calc(50% - 6px);

    box-sizing: border-box;
    width: 12px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    border: 2px ${props => props.color.sub1} solid;
    background: ${props => props.color.sub2};
  }
`;

export function CustomSocketComp<T extends ClassicPreset.Socket>(props: {
  data: T;
  nodeId: string;
}) {
  const parentNode = editor.getNode(props.nodeId);
  const dynamic = localStorage.getItem('dynamic') === 'true' ? true : false; 
  return <Styles color={parentNode.color} title={props.data.name} dynamic={dynamic}/>;
}

const StylesAddon = styled.div<{ color: string }>`
  display: inline-block;
  cursor: pointer;
  z-index: 2;
  box-sizing: border-box;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px ${AddonColor.sub1} solid;
  background: ${AddonColor.sub2};
  transform: rotate(90deg);
`;


export function AddonSocketComp<T extends ClassicPreset.Socket>(props: { data: T }) {
  return <StylesAddon color="white" title={props.data.name} />
}