import { styled } from "styled-components";

export const Toolbar = styled.div<{$theme: 'light' | 'dark'}>`
  display: inline-block;
  position: absolute;

  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  margin-top: var(--12px);

  height: var(--72px);

  &,
  .toolbar {
    display: flex;
    justify-content: center;
    gap: var(--8px);
  }

  .icon-btn {
    background-color: ${({$theme})=> $theme === 'light' ? "#E9EBF8" : "#080821"};
    border-radius: var(--16px);
    box-shadow: 0 4px 4px #22222225;
  }
`
