// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/images/toolbar/dynamic-true-3.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dynamic-true {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: 100% 100%;
  height: 44px;
  width: 44px;
}
`, "",{"version":3,"sources":["webpack://./src/routes/mlops/editor/toolbar/components/DynamicTrue/style.css"],"names":[],"mappings":"AAAA;EACE,yDAAgE;EAChE,0BAA0B;EAC1B,YAAY;EACZ,WAAW;AACb","sourcesContent":[".dynamic-true {\n  background-image: url(/public/images/toolbar/dynamic-true-3.svg);\n  background-size: 100% 100%;\n  height: 44px;\n  width: 44px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
