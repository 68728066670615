import React, { Dispatch, SetStateAction, useContext } from 'react'
import { filedirName } from '../../../../routes/datamanage/storage/storage.type';
import { CustomThemeContext } from '../../../../utils/Context';
import styled from 'styled-components';

type StyleProps = {
  $depth: number;
  $selected: boolean;
  $theme: 'light' | 'dark';
}

const Container = styled.li<StyleProps>`
  ${props=> `
  margin-left: ${26 * props.$depth + 4}px;
  background-color: ${props.$selected ? props.$theme === 'dark' ? `#181837` : `#E3e3F1` : ''};
  `}
`

interface FileProps {
  name: string;
  setSelected: Dispatch<SetStateAction<{ path: filedirName, name: filedirName, thumbnail: string }>>;
  depth: number;
  path: filedirName;
  thumbnail: string;
  selected?: { path: filedirName, name: filedirName, thumbnail: string };
}

export default function File({ name, setSelected, depth, path, thumbnail, selected }: FileProps) {
  const { theme } = useContext(CustomThemeContext);

  const handleFileClick = () => {
    setSelected({ 
      path: path, 
      name: name, 
      thumbnail: thumbnail 
    });
  };

  return (
    <Container  
      $depth={depth}
      $selected={`${selected?.path}/${selected?.name}` === `${path}/${name}`}
      $theme={theme}
      >
        <div onClick={handleFileClick} className="depth-file">
            {/* <img src={process.env.PUBLIC_URL + '/images/datamanage-icon-arrow-open.svg'} alt="" /> */}
            <div className="datamanage-file-name">
                <img src={process.env.PUBLIC_URL + '/images/datamanage-icon-file.svg'} alt="" />
                <span>{name}</span>
            </div>
        </div>
    </Container>
)
}
