import React, { useEffect, useState } from 'react'
import OptionInput from '../PanelOptionComponent/OptionInput';
import { isInt } from '../PanelOptionComponent/inputUtils';
import OptionInputToggle from '../PanelOptionComponent/OptionInputToggle';

export default function FullyConnectedOption({ nodeId, initOption, option, setOption, loading, setLoading }) {
  const [nodeName, setNodeName] = useState<string>('');
  const [inFeatures, setInFeatures] = useState<number>();
  const [outFeatures, setOutFeatures] = useState<number>();
  const [bias, setBias] = useState<boolean>(true);
  
  // 각 input에 초기값 세팅되기 전까지 로딩상태여야함
  useEffect(() => {
    setLoading(true);
    setNodeName('');
    setBias(true);
  }, [nodeId])

  useEffect(() => {
    if (!loading) {
      setOption({
        ...option, 
        name: nodeName,
        inFeatures,
        outFeatures,
        bias,
      })
    }
  }, [
    nodeName,
    inFeatures,
    outFeatures,
    bias,
  ]);

  return (
    <form name='convolution2dOption'>
      <OptionInput initOption={initOption} setValue={setNodeName} id={"name"} label='Name of Node' setLoading={setLoading} type='text'/>
      <OptionInput initOption={initOption} setValue={setInFeatures} id={"inFeatures"} label='In Features' setLoading={setLoading} isValidInput={isInt}/>
      <OptionInput initOption={initOption} setValue={setOutFeatures} id={"outFeatures"} label='Out Features' setLoading={setLoading} isValidInput={isInt}/>
      <OptionInputToggle initOption={initOption} setValue={setBias} id={"bias"} label='Bias' />
    </form>
  )
}
