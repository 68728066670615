import React, { useEffect, useState } from 'react'
import OptionInput from '../PanelOptionComponent/OptionInput';
import { isPositive } from '../PanelOptionComponent/inputUtils';
import OptionInputArray from '../PanelOptionComponent/OptionInputArray';

export default function MaxPool2dOptionSection({ nodeId, initOption, option, setOption, loading, setLoading }) {
  const [nodeName, setNodeName] = useState<string>('');
  const [kernelSize, setKernelSize] = useState<number>(2);
  
  // 각 input에 초기값 세팅되기 전까지 로딩상태여야함
  useEffect(() => {
    setLoading(true);
    setNodeName('');
    setKernelSize(2);
  }, [nodeId])

  useEffect(() => {
    if (!loading) {
      setOption({
        ...option,
        name: nodeName,
        kernelSize,
      })
    }
  }, [
    nodeName,
    kernelSize,
  ]);

  return (
    <form name='convolution2dOption'>
      <OptionInput initOption={initOption} setValue={setNodeName} id={"name"} label='Name of Node' setLoading={setLoading} type='text'/>

      <OptionInput 
        id="kernelSize" label='Kernel Size' placeholder="int or tuple"
        initOption={initOption} setValue={setKernelSize} 
        loading={loading} setLoading={setLoading} 
        isValidInput={isPositive}
      />
    </form>
  )
}

