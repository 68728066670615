import React from "react";
import { ClassicPreset, NodeId } from "rete";
import { CustomSocket } from "../../../sockets";
import { IDataflow } from "../../flow";
import { EffectorColor } from "../../../style/CustomNode";
import CustomControl from "../../../style/CustomControl";
import { updateControl } from "../../../../reteUtils";
import { NodeSize } from "../../../style/CustomNode";

export class ClassificationNode extends ClassicPreset.Node<
    { in: ClassicPreset.Socket },
    { out: ClassicPreset.Socket },
    { ctrl: ClassificationControl }
> {
    color = EffectorColor;
    width = NodeSize.width;
    height = NodeSize.height;
    type = 'classification';

    constructor(process: () => void, nodeId?: NodeId) {
        super('Classification');
        this.addInput('in', new ClassicPreset.Input(new CustomSocket()));
        this.addOutput('out', new ClassicPreset.Output(new CustomSocket()));
        this.addControl('ctrl', new ClassificationControl(process, nodeId ? nodeId : this.id));
    }

    data(inputs: { in: IDataflow[] }): { out: IDataflow } {
        const value = inputs['in'][0];
        return {
            out: {
                img_paths: value.img_paths,
                effector: {
                    input: [value.effector],
                    node: {
                        classification: this.controls.ctrl.props.option.classification
                    }
                }
            }
        }
    }
}

export class ClassificationControl extends ClassicPreset.Control {
    contextOpen: boolean;
    props: {
        option: { [key: string]: any }
    }
    model: string;
    weight: string;

    constructor(public onChange: () => void, public nodeId: NodeId) {
        super();
        this.contextOpen = false;
        this.props = {
            option: {}
        }
        this.model = '';
        this.weight = '';
    }

    setValue(val: { [key: string]: any }) {
        this.props.option = val;
        this.onChange();
    }

    setModel(val: string) {
        this.model = val;
    }

    setWeight(val: string) {
        this.weight = val;
    }

    setContextOpen = (open: boolean) => {
        this.contextOpen = open;
        updateControl(this.id);
    }
}

export function ClassificationComp({ data }: { data: ClassificationControl }) {
    return (
        <CustomControl contextOpen={data.contextOpen} setContextOpen={data.setContextOpen} label="Classification" nodeId={data.nodeId} markerSource={'node-effector'} iconSource="denoising" />
    )
}