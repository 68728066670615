import React from 'react'
import { IContextFnc } from '../../../routes/datamanage/storage/ContextMenu';
import { NodeId } from 'rete';

interface NodeContextMenuProps {
  functions: IContextFnc[];
  onClose: (e: boolean) => void;
  nodeId: NodeId;
}

export default function NodeContextMenu({ 
  functions, 
  onClose, 
  nodeId 
}: NodeContextMenuProps) {

  return (
    <div
        className='node-context-menu'
        onPointerDown={(e) => e.stopPropagation()}
        onDoubleClick={(e) => e.stopPropagation()}
        onClick={() => { onClose(false) }}>
        {
            functions.map((data) =>
                <p 
                key={data.label}
                className='node-context-menu-item' 
                onMouseDown={(e) => { e.stopPropagation(); }} 
                onMouseUp={(e) => { e.stopPropagation(); }} 
                onClick={(e) => {
                    e.stopPropagation();
                    if (data.on) data.on(); onClose(false);
                }}>
                    {data.label}
                </p>
            )
        }
    </div>
  )
}
