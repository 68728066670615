import React, { Dispatch, ReactNode, SetStateAction, createContext, useEffect, useState } from 'react';

// 선택한 NodeId 관리 Context

export const NodeContext = createContext<{ 
    nodeId?: string, 
    setNodeId?: (id: string) => void,
    dynamic?: boolean,
    setDynamic?: Dispatch<SetStateAction<boolean>>,
}>({
    dynamic: true
});

export function NodeProvider({ children }: { children: ReactNode }) {
    const [nodeId, setId] = useState<string>('');
    const setNodeId = (id: string): void => {
        setId(id)
    };

    const [dynamic, setDynamic] = useState(true);
    const [isReady, setIsReady] = useState(false);
    
    const goback = () => {
        window.history.back();
    }
    const goforward = () => {
        window.history.forward();
    }

    useEffect(() => {
        localStorage.setItem('dynamic', dynamic.toString());
        if (isReady) {
            goback();
            setTimeout(()=>{
                goforward()
            }, 100)
        } 
    }, [dynamic])

    useEffect(() => {
        setIsReady(true);
    }, [])

    return (
        <NodeContext.Provider value={{ 
            nodeId, setNodeId, 
            dynamic, setDynamic 
        }}>
            {children}
        </NodeContext.Provider>
    );
}
