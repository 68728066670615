import { Cookies } from "react-cookie";
import API from "../utils/api";
import { editor, NodeTypes, ControlTypes, area } from "./rete";
import { ClassicPreset } from "rete";
import { DataManageControl, DataManageNode } from "./components/nodes/source/DataManagement";
import { ImageCropControl, ImageCropNode } from "./components/nodes/effector/ImageCrop";
import { EdgeDetectControl, EdgeDetectNode } from "./components/nodes/effector/EdgeDectection";
import { DenoisingControl, DenoisingNode } from "./components/nodes/effector/deeplearning/Denoising";
import { ViewerControl, ViewerNode } from "./components/nodes/sink/Viewer";
import { Position } from "rete-area-plugin/_types/types";
import { process } from "./reteUtils";
import { AnnotationControl } from "./components/nodes/addon/Annotation";
import { BlenderControl, BlenderNode } from "./components/nodes/effector/Blender";
import { GearControl, GearNode } from "./components/nodes/addon/Gear";
import { Segmentation2DControl, Segmentation2DNode } from "./components/nodes/effector/deeplearning/Segmentation2D";
import { ClassificationControl, ClassificationNode } from "./components/nodes/effector/deeplearning/Classification";
import { FeatureControl, FeatureNode } from "./components/nodes/addon/Feature";

function serializePort(
    port:
        | ClassicPreset.Input<ClassicPreset.Socket>
        | ClassicPreset.Output<ClassicPreset.Socket>
) {
    return {
        id: port.id,
        label: port.label,
        socket: {
            name: port.socket.name
        }
    };
}

function serializeControl(control: ClassicPreset.Control) {
    if (control instanceof DataManageControl) {
        return {
            type: "DataManageControl",
            id: control.id,
            option: control.props.option
        }
    }
    else if (control instanceof ImageCropControl) {
        return {
            type: "ImageCropControl",
            id: control.id,
            option: control.props.option
        }
    }
    else if (control instanceof EdgeDetectControl) {
        return {
            type: "EdgeDetectControl",
            id: control.id,
            option: control.props.option
        }
    }
    else if (control instanceof DenoisingControl) {
        return {
            type: "Denoising",
            id: control.id,
        }
    }
    else if (control instanceof Segmentation2DControl) {
        return {
            type: "Segmentation2DControl",
            id: control.id,
            option: control.props.option,
            model: control.model,
            weight: control.weight
        }
    }
    else if (control instanceof ClassificationControl) {
        return {
            type: "ClassificationControl",
            id: control.id,
            option: control.props.option,
            model: control.model,
            weight: control.weight
        }
    }
    else if (control instanceof ViewerControl) {
        return {
            type: "ViewerControl",
            id: control.id,
            option: control.props.option
        }
    }
    else if (control instanceof AnnotationControl) {
        return {
            type: "AnnotationControl",
            id: control.id,
            name: control.props.name,
            patches: control.props.patches,
            size: control.props.size
        }
    }
    else if (control instanceof BlenderControl) {
        return {
            type: "BlenderControl",
            id: control.id,
            option: control.props.option
        }
    }
    else if (control instanceof FeatureControl) {
        return {
            type: "FeatureControl",
            id: control.id,
            option: control.props.option
        }
    }
    else if (control instanceof GearControl) {
        return {
            type: "GearControl",
            id: control.id,
            option: control.props.option
        }
    }
    else return {
        id: control.id
    }
}

export async function createToJson() {

    const data: any = { nodes: [], connections: [] };
    const nodes = editor.getNodes();
    const connections = editor.getConnections();

    for (const node of nodes) {
        const position = area.nodeViews.get(node.id)?.position;
        const inputsEntries = Object.entries(node.inputs).map(([key, input]) => {
            return [key, input && serializePort(input)];
        });
        const outputsEntries = Object.entries(node.outputs).map(([key, output]) => {
            return [key, output && serializePort(output)];
        });
        const controlsEntries = Object.entries(node.controls).map(
            ([key, control]) => {
                return [key, control && serializeControl(control)];
            }
        );
        data.nodes.push({
            id: node.id,
            label: node.label,
            outputs: Object.fromEntries(outputsEntries),
            inputs: Object.fromEntries(inputsEntries),
            controls: Object.fromEntries(controlsEntries),
            position: position
        });
    }
    for (const connection of connections) {
        data.connections.push({
            id: connection.id,
            source: connection.source,
            sourceOutput: connection.sourceOutput,
            target: connection.target,
            targetInput: connection.targetInput
        })
    }

    const json = JSON.stringify(data);
    return json;
}

export async function getWorkspace() {
    const cookie = new Cookies();
    const api = new API(cookie);

    try {
        const response = await api.get('/workspace/');
        await createByJson(JSON.parse(response.data));
    }
    catch (err) {
        console.log(err);
    }
}

interface INode {
    controls: ControlTypes;
    id: string;
    inputs: {
        [key: string]: any
    };
    label: string;
    outputs: {
        [key: string]: any
    };
    position: Position;
}

interface IConnection {
    id: string;
    source: string;
    sourceOutput: string;
    target: string;
    targetInput: string;
}

export const createByJson = async (nodes: { nodes: INode[], connections: IConnection[] }) => {
    const nodeArr = nodes['nodes'];
    const ConnArr = nodes['connections'];
    const nodeList = [];
    for (let node of nodeArr) {
        const newNode = createNode(node);
        if (newNode) {
            await editor.addNode(newNode);
            await area.translate(newNode.id, node.position)
        }
    }
    for (let connection of ConnArr) {
        const source = editor.getNode(connection.source);
        const target = editor.getNode(connection.target);
        await editor.addConnection(new ClassicPreset.Connection(source, connection.sourceOutput as never, target, connection.targetInput as never));
    }
}

const createNode = (node: INode): NodeTypes | undefined => {
    let newNode;
    if ('ctrl' in node.controls) {
        const ctrl: any = node.controls['ctrl'];
        if (node.label == 'DataManage') {
            newNode = new DataManageNode(process, node.id);
            newNode.id = node.id
            newNode.controls.ctrl.setValue(ctrl.option);
        }
        else if (node.label == 'ImageCrop') {
            newNode = new ImageCropNode(process, node.id);
            newNode.id = node.id;
            newNode.controls.ctrl.setValue(ctrl.option)
        }
        else if (node.label == 'Edge Detection') {
            newNode = new EdgeDetectNode(process, node.id);
            newNode.id = node.id;
            newNode.controls.ctrl.setValue(ctrl.option)
        }
        else if (node.label == 'Denoising') {
            newNode = new DenoisingNode(process, node.id);
            newNode.id = node.id;
        }
        else if (node.label == 'Segmentation2D') {
            newNode = new Segmentation2DNode(process, node.id);
            newNode.id = node.id;
            newNode.controls.ctrl.setValue(ctrl.option);
            newNode.controls.ctrl.setModel(ctrl.model);
            newNode.controls.ctrl.setWeight(ctrl.weight);
        }
        else if (node.label == 'Classification') {
            newNode = new ClassificationNode(process, node.id);
            newNode.id = node.id;
            newNode.controls.ctrl.setValue(ctrl.option);
            newNode.controls.ctrl.setModel(ctrl.model);
            newNode.controls.ctrl.setWeight(ctrl.weight);
        }
        else if (node.label == 'Viewer') {
            newNode = new ViewerNode(node.id);
            newNode.id = node.id;
            newNode.controls.ctrl.setValue(ctrl.option);
        }
        else if (node.label === 'Blender') {
            newNode = new BlenderNode(process, node.id);
            newNode.id = node.id;
            newNode.controls.ctrl.setValue(ctrl.option)
        }
        else if (node.label === 'Feature') {
            newNode = new FeatureNode(process, node.id);
            newNode.id = node.id;
        }
        else if (node.label === 'Gear') {
            newNode = new GearNode(process, node.id);
            newNode.id = node.id;
            newNode.controls.ctrl.setValue(ctrl.option);
        }
    }
    else {
    };
    return newNode;
}
