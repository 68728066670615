import React from "react";

export const AlignCenter1 = ({ className }) => {
  return (
    <svg
      className={`align-center-1 ${className}`}
      fill="none"
      height="44"
      viewBox="0 0 44 44"
      width="44"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect className="rect" height="9" rx="1.5" stroke="#65646A" width="3" x="23" y="17.6992" />
      <rect className="rect" height="9" rx="1.5" stroke="#65646A" width="3" x="17.5" y="17.6992" />
      <path className="path" d="M15 22.1992H17" stroke="#65646A" />
      <path className="path" d="M20.5 22.1992H22.5" stroke="#65646A" />
      <path className="path" d="M26.5 22.1992H28.5" stroke="#65646A" />
      <rect className="rect" height="19" rx="1.5" stroke="#65646A" width="3" x="11.5" y="12.6992" />
      <rect className="rect" height="19" rx="1.5" stroke="#65646A" width="3" x="29" y="12.6992" />
    </svg>
  );
};
