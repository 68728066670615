import React, { FormEventHandler, useCallback, useContext, useEffect, useState } from 'react'
import { handleImgError } from '../../utils/function'
import styled from 'styled-components'
import { useAPIwithCookies } from '../../hooks/useApiCookies';
import SecondaryButton from '../../components/button/SecondaryButton';
import { SmcContext } from '../../context/SmcContext';
import { StorageContext } from '../../context/StorageContext';

const Container = styled.div`
  img {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: var(--16px);
    margin-bottom: var(--16px);
  }

  table {
    width: 100%;
    * {
      padding: var(--4px);
    }
    input {
      width: 100%;
    }
  }
`

type MetaData = {
  [key in string]: string;
}

export default function SmcSidePanel() {
  const api = useAPIwithCookies();
  const [metaData, setMetaData] = useState<MetaData>();
  const [userMeta, setUserMeta] = useState<MetaData>();
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [selectedPath, setSelectedPath] = useState('');
  const { smcModal } = useContext(SmcContext);
  const { selectedFile } = useContext(StorageContext);

  // 파일에 대한 메타 데이터를 받아온다.
  const getMetaData = useCallback(async () => {
    const path = selectedFile.path === '' 
      ? selectedFile.name 
      : selectedFile.path + '/' + selectedFile.name;
    setSelectedPath(path);
    const { data } = await api.get(`/smc/meta_data/${path}`)
    setMetaData(data);
    setUserMeta(data);
  }, [selectedFile.path, selectedFile.name])
  
  // 선택된 파일이 달라질때마다 실행시킨다.
  useEffect(() => {
    if (selectedFile.name 
      && selectedFile.thumbnail !== 'filled'
      && selectedFile.thumbnail !== 'empty'
    ) {
      getMetaData();
    } else {
      setSelectedPath('');
      setIsUpdated(false);
      setUserMeta({});
      setMetaData({});
    }
  }, [selectedFile.name, getMetaData])

  // 선택된 파일에 대한 메타데이터를 수정하라는 요청을 보낸다.
  const updateData = async () => {
    const { data } = await api.post(`/smc/update_meta_data`, {
      path: selectedPath, 
      meta_data: userMeta
    })
    console.log(data);
  }

  useEffect(() => {
    getMetaData();
  }, [smcModal])

  // form 제출
  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault()
    if (!selectedPath || !isUpdated) return;
    updateData();
  }

  // value를 받아오기 위한 onChange 함수 
  const handleChange = (e) => {
    if (!isUpdated) setIsUpdated(true);
    const { name, value } = e.target;
    setUserMeta(prev => ({
      ...prev,
      [name]: value 
    }))
  }

  // input 렌더링 자동화를 위해 key 세팅~
  const [metaKeys, setMetaKeys] = useState<string[]>([]);
  const META_KEYS_KOREAN = {
    title: '제목',
    count: '개수',
    type: '미생물 타입',
    date: '실험 날짜',
    note: '비고',
    memo: '메모',
  }
  useEffect(() => {
    const newMetaKeys = Object.keys(metaData ?? {});
    setMetaKeys(newMetaKeys);
  }, [metaData])  

  return (
    <Container className="right">
      <img 
        src={`data:image/jpeg;base64,${selectedFile.thumbnail}`} 
        alt={selectedFile.name+' thumbnail'} 
        onError={handleImgError}
        style={{objectFit: 'cover'}}
      />
      <form onSubmit={handleSubmit}>
        <table>

          {metaKeys.map(key => {
            return (
              <tr>
                <th>
                  {META_KEYS_KOREAN[key]}
                </th>
                <td>
                  <input 
                    type="text" 
                    name={key} 
                    defaultValue={metaData && metaData[key]}
                    value={userMeta && userMeta[key]} 
                    onChange={handleChange}
                  />
                </td>
              </tr>
            )
          })}
          
        </table>
        <SecondaryButton 
          type='submit' 
          disabled={!isUpdated}
        >
          저장
        </SecondaryButton>
      </form>
      
    </Container>
  )
}
