import { vh, vw } from "../App";

// ImageCrop 에서 사용하는 Viewport <=> Px 변환 함수

export function AddVxPxToPx(type: 'height' | 'width', viewport: number, px: number): number {
    if (type === 'height') {
        return vh * viewport + px;
    }
    else return vw * viewport + px;

}

export function AddVxPxToVx(type: 'height' | 'width', viewport: number, px: number): number {
    if (type === 'height') {
        return px / vh + viewport;
    }
    else return px / vw + viewport;
}

export const handleImgError = (e) => {
    const imgEl = e.target;
    imgEl.setAttribute('src', `${process.env.PUBLIC_URL}/images/image-error.png`);
    // imgEl.setAttribute('style', 'object-fit: contain');
}