import React from "react";
import { createRoot } from "react-dom/client";
import { NodeEditor, GetSchemes, ClassicPreset } from "rete";
import { AreaPlugin, AreaExtensions } from "rete-area-plugin";
import { ConnectionPlugin, Presets as ConnectionPresets } from "rete-connection-plugin";
import { ReactPlugin, Presets, ReactArea2D } from "rete-react-plugin";
import { AutoArrangePlugin, Presets as ArrangePresets, ArrangeAppliers } from "rete-auto-arrange-plugin";
import { DataflowEngine } from "rete-engine";
import { ContextMenuExtra, ContextMenuPlugin } from "rete-context-menu-plugin";
import { MinimapExtra, MinimapPlugin } from "rete-minimap-plugin";

import { copyNode, deleteNode } from "./reteMlopsUtils";
import { useMagneticConnection } from "../rete/magnetic-connection";

import { CustomConnection, CustomNode, CustomSocketComp, CustomSocket } from "./style"
import { 
  DatasetComponent, DatasetControl, DatasetNode, 
  Convolution2dComponent, Convolution2dControl, Convolution2dNode,
  TrainerComponent, TrainerControl, TrainerNode,
  MaxPool2dNode, MaxPool2dComponent, MaxPool2dControl,
  ReluActivationComponent, ReluActivationControl, ReluActivationNode,
  BatchNorm2dComponent, BatchNorm2dControl, BatchNorm2dNode,
  ConvTranspose2dControl, ConvTranspose2dNode, ConvTranspose2dComponent, 
  CatControl, CatNode, CatComponent, 
  FullyConnectedComponent, FullyConnectedControl, FullyConnectedNode
} from "./components";
import { FineTunerComponent, FineTunerControl, FineTunerNode } from "./components/nodes/FineTunerNode";
import { FeatureVisualizerComponent, FeatureVisualizerControl, FeatureVisualizerNode } from "./components/nodes/FeatureVisualizerNode";
import { StaticNode } from "./style/StaticNode";

// 리트 설정
export class Connection<
  A extends NodeTypes,
  B extends NodeTypes,
> extends ClassicPreset.Connection<A, B> { }

export type ControlTypes = 
  DatasetControl | Convolution2dControl | TrainerControl | 
  MaxPool2dControl | ReluActivationControl | BatchNorm2dControl | 
  ConvTranspose2dControl | CatControl | FullyConnectedControl |
  FineTunerControl | FeatureVisualizerControl;
export type NodeTypes = 
DatasetNode | Convolution2dNode | TrainerNode | 
MaxPool2dNode | ReluActivationNode | BatchNorm2dNode | 
ConvTranspose2dNode | CatNode | FullyConnectedNode |
FineTunerNode | FeatureVisualizerNode;
export type ConnProps = Connection<NodeTypes, NodeTypes>;
export type Schemes = GetSchemes<NodeTypes, ConnProps>;
export type AreaExtra = ReactArea2D<any> | MinimapExtra | ContextMenuExtra;

// 초기 설정
export let editor: NodeEditor<Schemes>;
export let engine: DataflowEngine<Schemes>;
export let area: AreaPlugin<Schemes, AreaExtra>;
export const arrange = new AutoArrangePlugin<Schemes>();

// 에디터 만들기 함수 (기본)
export async function createEditor(container: HTMLElement) {
  editor = new NodeEditor<Schemes>();
  area = new AreaPlugin<Schemes, AreaExtra>(container);
  engine = new DataflowEngine<Schemes>();
  const connection = new ConnectionPlugin<Schemes, AreaExtra>();
  const render = new ReactPlugin<Schemes, AreaExtra>({ createRoot });
  const dynamic = localStorage.getItem('dynamic') === 'true' ? true : false;  

  const contextMenu = new ContextMenuPlugin<Schemes>({
    items(context, plugin) {
      // 바닥을 우클릭 한 경우
      if (context === 'root') {
        return {
          searchBar: false,
          list: [
            {
              label: 'hi', key: '1', handler: ()=>{alert('hi')}
            }
          ]
        }
      }
      // Node를 우클릭 한 경우,
      return {
        searchBar: false,
        list: [
          {
            label: 'Delete', key: '1', handler: async () => {
              deleteNode(context.id);
            }
          }, {
            label: 'Copy', key: '2', handler: async () => {
              copyNode(context.id);
            }
          },]
      }
    }
  })

  // @ts-ignore
  area.use(contextMenu);

  // minimap 생성
  const minimap = new MinimapPlugin<Schemes>({
    boundViewport: true
  });

  AreaExtensions.selectableNodes(area, AreaExtensions.selector(), {
    accumulating: AreaExtensions.accumulateOnCtrl()
  });

  // @ts-ignore
  render.addPreset(Presets.contextMenu.setup());
  render.addPreset(Presets.minimap.setup({ size: 200 }));
  render.addPreset(
    Presets.classic.setup({
      customize: {
        control(context: any) {
          if (context.payload instanceof DatasetControl) {
            return () => <DatasetComponent data={context.payload as DatasetControl} />;
          } else if (context.payload instanceof Convolution2dControl) {
            return () => <Convolution2dComponent data={context.payload as Convolution2dControl} />;
          } else if (context.payload instanceof TrainerControl) {
            return () => <TrainerComponent data={context.payload as TrainerControl} />;
          } else if (context.payload instanceof MaxPool2dControl) {
            return () => <MaxPool2dComponent data={context.payload as MaxPool2dControl} />;
          } else if (context.payload instanceof ReluActivationControl) {
            return () => <ReluActivationComponent data={context.payload as ReluActivationControl} />;
          } else if (context.payload instanceof BatchNorm2dControl) {
            return () => <BatchNorm2dComponent data={context.payload as BatchNorm2dControl} />;
          } else if (context.payload instanceof ConvTranspose2dControl) {
            return () => <ConvTranspose2dComponent data={context.payload as ConvTranspose2dControl} />;
          } else if (context.payload instanceof CatControl) {
            return () => <CatComponent data={context.payload as CatControl} />;
          } else if (context.payload instanceof FullyConnectedControl) {
            return () => <FullyConnectedComponent data={context.payload as FullyConnectedControl} />;
          } else if (context.payload instanceof FineTunerControl) {
            return () => <FineTunerComponent data={context.payload as FineTunerControl} />;
          } else if (context.payload instanceof FeatureVisualizerControl) {
            return () => <FeatureVisualizerComponent data={context.payload as FeatureVisualizerControl} />;
          }
          return null;
        }, 
        // @ts-ignore
        node(context: any) {
          // 기본 Node가 아닌 CustomNode return
          if (!dynamic) {
            return StaticNode;
          } else {
            return CustomNode;
          }
        },
        connection(context: any) {
          return CustomConnection;
        },
        // @ts-ignore
        socket(data: any) {
          if (data.payload instanceof CustomSocket) {
            return () => <CustomSocketComp data={data.payload} nodeId={data.nodeId} />;
          }
        },
      }
    })
  );

  const applier = new ArrangeAppliers.TransitionApplier<Schemes, never>({
    duration: 500,
    timingFunction: (t) => t,
  });

  arrange.addPreset(ArrangePresets.classic.setup());
  connection.addPreset(ConnectionPresets.classic.setup());
  editor.use(engine);
  editor.use(area);
  area.use(connection);
  area.use(render);
  area.use(minimap);
  area.use(arrange);
  AreaExtensions.simpleNodesOrder(area);
  AreaExtensions.showInputControl(area);
  AreaExtensions.restrictor(area, {
    scaling: () => ({ min: 0.005, max: 1 }),
  });
  AreaExtensions.snapGrid(area, {
    size: 100
  });
  
  // Connection Magnetic 등록
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useMagneticConnection(connection, {
    async createConnection(from, to) {
      if (from.side === to.side) return;
      const [source, target] = from.side === "output" ? [from, to] : [to, from];
      const sourceNode = editor.getNode(source.nodeId);
      const targetNode = editor.getNode(target.nodeId);

      await editor.addConnection(
        new ClassicPreset.Connection(
          sourceNode,
          source.key as never,
          targetNode,
          target.key as never
        )
      );
    },
    display(from, to) {
      return from.side !== to.side;
    },
    offset(socket, position) {
      const socketRadius = 10;

      return {
        x:
          position.x + (socket.side === "input" ? -socketRadius : socketRadius),
        y: position.y
      };
    }
  });

  return {
    layout: async (animate: boolean,type: string = 'simple') => {
      await arrange.layout({ applier: animate ? applier : undefined, options: {
        // @ts-ignore
        'elk.layered.spacing.nodeNodeBetweenLayers': 100,
        'elk.layered.nodePlacement.strategy': 'SIMPLE', // 한 줄일 때 가운데 정렬
      }}).then(()=>{
        if (type==='simple') {
          return;
        } else {
          editor.getNodes().forEach( async (node: any) => {
            let nodev = area.nodeViews.get(node.id);
            if(nodev){
              await area.translate(node.id, { x: nodev.position.x, y: -node.height * 4 });
            }
          });
        }
      });  
    },

    zoomAt: () => {
      AreaExtensions.zoomAt(area, editor.getNodes(), {scale: 0.5});
    },

    zoomAtTrainer: (inputNode = null) => {
      if (inputNode) {
        AreaExtensions.zoomAt(area, inputNode, {scale: 1});
        return;
      }
      const trainer = editor.getNodes().filter(n => n.label === 'Trainer');
      if (trainer.length !== 1) return trainer;
      AreaExtensions.zoomAt(area, trainer, {scale: 1});
    },

    destroy: () => area.destroy(),
  };
}
