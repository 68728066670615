import React, { useContext } from 'react'
import LeftPanel from '../../components/leftPanel/LeftPanel'
import { Link } from 'react-router-dom'
import { CustomThemeContext } from '../../utils/Context'
import styled from 'styled-components'

const Container = styled(LeftPanel)`
  > strong {
    display: block;
    text-align: left;
    padding: var(--14px) var(--20px) var(--15px);
    border-bottom: 1px solid #664c9e;
    color: #AD86FF;
    font-size: var(--18px);
    font-weight: 700;
    letter-spacing: -0.5px;
  }
  ul {
    li {
        
        > span {
            color: #EEE;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -0.5px;
        }
        > a {
            display: block;
            margin-top: 15px;
            &:hover {
                span {
                    text-decoration: underline;
                }
            }
            p {
                display: flex;
                justify-content: space-between;
                align-items: center;
                > div {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 10px;
                }
            }
        }
        
    }
  }
`

export default function OverviewLeftPanel() {
  const { theme } = useContext(CustomThemeContext);
  return (
    <Container className='left'>
        <strong>Recent Data</strong>
        <ul>
            <li>
                <span>Favorite data</span>
                <Link to="/">
                    <p>
                        <div>
                            <img src={process.env.PUBLIC_URL + '/images/ico-folder.svg'} alt="" className="" />
                            <span>Data 01</span>
                        </div>
                        <img src={process.env.PUBLIC_URL + theme === 'dark' ? '/images/ico-overviewArrow.svg' : '/images/ico-overviewArrow-white.svg'} alt="" className="" />
                    </p>
                </Link>
                <Link to="/">
                    <p>
                        <div>
                            <img src={process.env.PUBLIC_URL + '/images/ico-folder.svg'} alt="" className="" />
                            <span>Data 02</span>
                        </div>
                        <img src={process.env.PUBLIC_URL + theme === 'dark' ? '/images/ico-overviewArrow.svg' : '/images/ico-overviewArrow-white.svg'} alt="" className="" />
                    </p>
                </Link>
            </li>
            <li>
                <span>My data</span>
                <Link to="/">
                    <p>
                        <div>
                            <img src={process.env.PUBLIC_URL + '/images/ico-folder.svg'} alt="" className="" />
                            <span>Data 03</span>
                        </div>
                        <img src={process.env.PUBLIC_URL + theme === 'dark' ? '/images/ico-overviewArrow.svg' : '/images/ico-overviewArrow-white.svg'} alt="" className="" />
                    </p>
                </Link>
                <Link to="/">
                    <p>
                        <div>
                            <img src={process.env.PUBLIC_URL + '/images/ico-folder.svg'} alt="" className="" />
                            <span>Data 04</span>
                        </div>
                        <img src={process.env.PUBLIC_URL + theme === 'dark' ? '/images/ico-overviewArrow.svg' : '/images/ico-overviewArrow-white.svg'} alt="" className="" />
                    </p>
                </Link>
            </li>

            <li>
                <span>Processing data</span>
                <Link to="/">
                    <p>
                        <div>
                            <img src={process.env.PUBLIC_URL + '/images/ico-folder.svg'} alt="" className="" />
                            <span>Data 05</span>
                        </div>
                        <img src={process.env.PUBLIC_URL + theme === 'dark' ? '/images/ico-overviewArrow.svg' : '/images/ico-overviewArrow-white.svg'} alt="" className="" />
                    </p>
                </Link>
                <Link to="/">
                    <p>
                        <div>
                            <img src={process.env.PUBLIC_URL + '/images/ico-folder.svg'} alt="" className="" />
                            <span>Data 06</span>
                        </div>
                        <img src={process.env.PUBLIC_URL + theme === 'dark' ? '/images/ico-overviewArrow.svg' : '/images/ico-overviewArrow-white.svg'} alt="" className="" />
                    </p>
                </Link>
            </li>

            <li>
                <span>Shared data</span>
                <Link to="/">
                    <p>
                        <div>
                            <img src={process.env.PUBLIC_URL + '/images/ico-folder.svg'} alt="" className="" />
                            <span>Data 07</span>
                        </div>
                        <img src={process.env.PUBLIC_URL + theme === 'dark' ? '/images/ico-overviewArrow.svg' : '/images/ico-overviewArrow-white.svg'} alt="" className="" />
                    </p>
                </Link>
                <Link to="/">
                    <p>
                        <div>
                            <img src={process.env.PUBLIC_URL + '/images/ico-folder.svg'} alt="" className="" />
                            <span>Data 08</span>
                        </div>
                        <img src={process.env.PUBLIC_URL + theme === 'dark' ? '/images/ico-overviewArrow.svg' : '/images/ico-overviewArrow-white.svg'} alt="" className="" />
                    </p>
                </Link>
            </li>
        </ul>
    </Container>
  )
}
