/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

interface Props {
  className: any;
}

export const FindTrainer = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={`find-trainer ${className}`}
      fill="none"
      height="44"
      viewBox="0 0 44 44"
      width="44"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_375_417)">
        <path className="path" d="M1 10.6002V1H10.3333" stroke="#65646A" />
        <path className="path" d="M1 33.3998V43H10.3333" stroke="#65646A" />
        <path className="path" d="M33.6667 1L43 1L43 10.6002" stroke="#65646A" />
        <path className="path" d="M33.6667 43L43 43L43 33.3998" stroke="#65646A" />
        <rect className="rect" fill="#65646A" height="24" rx="2" width="9" x="17" y="10" />
        <path className="path" d="M10 22L17 22" stroke="#65646A" />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_375_417">
          <rect className="rect" fill="white" height="44" width="44" />
        </clipPath>
      </defs>
    </svg>
  );
};
