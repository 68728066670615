import React, { useContext, useEffect, useState, MouseEvent } from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";

import './canvasHeader.scss';

import Mode from '../mode/Mode';
import { CustomThemeContext } from '../../utils/Context';
import { useLogout } from '../../hooks/useLogout';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useAPIwithCookies } from "../../hooks/useApiCookies";

function CanvasHeader( ) {
    const { theme } = useContext(CustomThemeContext);
    const { logout } = useLogout();
    const { user } = useAuthContext();
    
    const [show, setShow] = useState(false);
    
    let { pathname } = useLocation();
    pathname = pathname.split("/")[2];

    useEffect(()=>{
        const current = document.querySelector(`li.${pathname}`);
        current?.classList.add("active");
    }, []);

    const api = useAPIwithCookies();
    const [isSmc, setIsSmc] = useState(false);
    const check_account = async () => {
      const response = await api.get('/smc_proofreading/check_account/'+user?.email);
      if (response.data === 1) {
        setIsSmc(true);
      }
      else{
        setIsSmc(false);
      }
    }
    useEffect(() => {
      check_account();
    }, [user])
  

    return (
        <div className='canvasHeader'>
            <div className='left-header'>
                <h1>
                    <Link to="/vience-canvas">
                        <img src={process.env.PUBLIC_URL + theme === 'dark' ? '/images/logo.svg' : '/images/logo-white.svg'} alt="vience" />
                    </Link>
                </h1>

                <nav className='canvas-header-nav'>
                    <ul>
                        <li className="disabled">
                            <Link to="/vience-canvas/overview">Overview</Link>
                        </li>
                        <li className="management storage">
                            <Link to="/vience-canvas/storage">Data Management</Link>
                        </li>
                        {
                            !isSmc &&
                            <li className="canvas workspace">
                                <Link to="/vience-canvas/workspace">CANVAS</Link>
                            </li>
                        }
                        {
                            !isSmc &&
                            <li className="mlops">
                                <Link to="/vience-canvas/mlops">MLOps</Link>
                            </li>
                        }
                        {
                            isSmc &&
                            <li className="smc"> 
                                <Link to="/vience-canvas/smc">SMC</Link>
                            </li>
                        }
                        <li className="disabled">
                            <Link to="/vience-canvas">Module Market</Link>
                        </li>
                        <li className="disabled">
                            <Link to="/vience-canvas">Documents</Link>
                        </li>
                    </ul>
                </nav>
            </div>

            <div className="right-header">
                <div className='modeFrame'>
                    <Mode />
                </div>
                <div className="login-user">
                    <div className="user-name" onClick={() => setShow(prev => !prev)}>
                        <img src={process.env.PUBLIC_URL + '/images/user.svg'} alt="" />
                        <span>{user.email}</span>
                    </div>
                    {
                        show &&
                        (<div className={`login-content-frame`}>
                            <ul>
                                <li onClick={() => {  }}>
                                {/* <li onClick={() => { navigate('/vience-canvas/overview') }}> */}
                                    <span>My page</span>
                                </li>
                                <li onClick={logout}>
                                    <span>Logout</span>
                                </li>
                            </ul>
                        </div>)
                    }
                </div>

            </div>
        </div>
    )
}

export default React.memo( CanvasHeader );
