import React from "react";
import { ClassicPreset, NodeId } from "rete";
import { AddonColor, CustomSocket, NodeSize } from "../../style";
import CustomControl from "../../style/CustomControl";
import { ILayerOption, IMlopsflow } from "../flow";

export class CatNode extends ClassicPreset.Node<
  { in: ClassicPreset.Socket, in2: ClassicPreset.Socket},
  { out: ClassicPreset.Socket },
  { ctrl: CatControl }
> {
  color = AddonColor;
  width = NodeSize.width;
  height = NodeSize.height; 
  error;
  
  constructor(
    process: () => void, 
    update: (control: CatControl) => void, 
    nodeId: NodeId
  ) {
    super('Cat');
    this.id = nodeId === "" ? this.id : nodeId;
    this.addInput('in', new ClassicPreset.Input(new CustomSocket()));
    this.addInput('in2', new ClassicPreset.Input(new CustomSocket()));
    this.addOutput('out', new ClassicPreset.Output(new CustomSocket()));
    this.addControl('ctrl', new CatControl(
      process, 
      update,
      this.id
    ));
  }
  data(input): { out: IMlopsflow } {
    let output: IMlopsflow = {
      datasetPath: {},
      layer: {
        input: [],
        node: {
          "cat": this.controls.ctrl.props.option
        },
      }
    };

    if (input.in && input.in2) {
      const inputNode = input.in[0];
      const inputNode2 = input.in2[0];

      var val1 = 0;
      var val2 = 0;
      const data1_key = Object.keys(inputNode.layer.node)[0];
      const data2_key = Object.keys(inputNode2.layer.node)[0];

    if (inputNode && inputNode2){
        if(data1_key !== "dataset"){
          if(inputNode.layer.node[data1_key].outChannel){
            val1 = inputNode.layer.node[data1_key].outChannel;
          }
          else if(inputNode.layer.node[data1_key].numFeatures){
            val1 = inputNode.layer.node[data1_key].numFeatures;
          }
        }
        if(data2_key !== "dataset"){
          if(inputNode2.layer.node[data2_key].outChannel){
            val2 = inputNode2.layer.node[data2_key].outChannel;
          }
          else if(inputNode2.layer.node[data2_key].numFeatures){
            val2 = inputNode2.layer.node[data2_key].numFeatures;
          }
        }
        this.controls.ctrl.props.option.numFeatures = val1 + val2;
      }
      
      if (
        inputNode && inputNode2 
        && inputNode.datasetPath && inputNode2.datasetPath 
      ) {
        const gap = inputNode.layer.node[data1_key].datasetResolution - inputNode2.layer.node[data2_key].datasetResolution;
        if (Math.abs(gap) > 50 && localStorage.getItem('dynamic') === 'true') {
          this.error = 'Data Resolution does not match';
        } else {
          this.error = undefined;
        }

        let convertedResolution = 
          inputNode.layer.node[data1_key].datasetResolution;
        
        if (Number.isNaN(convertedResolution)) {
          convertedResolution = 66
        } 

        this.controls.ctrl.props.option.datasetResolution = convertedResolution;
        this.controls.ctrl.datasetResolution = convertedResolution;
        if (localStorage.getItem('dynamic') === 'true') {
          this.height = convertedResolution < 66 ? 66 : convertedResolution;
          this.width = (
            50 * Math.log2(Number(this.controls.ctrl.props.option.numFeatures))) < 222 
          ? 222 
          : (50 * Math.log2(Number(this.controls.ctrl.props.option.numFeatures)));
        }

        let datasetPath = inputNode?.datasetPath ?? "";
        let datasetPath2 = inputNode2?.datasetPath ?? "";
        datasetPath = {
          ...datasetPath,
          ...datasetPath2
        };

        output = {
          datasetPath, 
          layer: {
            input: [
              inputNode?.layer, 
              inputNode2?.layer
            ],
            node: {
              "cat": this.controls.ctrl.props.option
            }
          }
        }

        if (this.controls.ctrl.modelFlow !== output) {
          this.controls.ctrl.modelFlow = output;
        }
      } 
    }

    return {
      out: output
    }
  }
}

export class CatControl extends ClassicPreset.Control {
  contextOpen: boolean;
  props: {
    option: ILayerOption;
  };
  modelFlow: IMlopsflow;
  datasetResolution: number;

  constructor(
    public onChange: () => void, 
    public update: (c: CatControl) => void, 
    public nodeId: NodeId
  ) {
    super();
    this.contextOpen = false;
    this.props = {
      option: {
        nodeId,
        name: 'torch.Cat',
        dim: 1,
        numFeatures: 1,
      }
    };
    this.modelFlow = {
      datasetPath: {undefined},
      layer: undefined,
    };
    this.datasetResolution = 66;
  }

  setValue(data: ILayerOption) {
    this.props.option = data;
    this.onChange();
  }

  setContextOpen = (open: boolean) => {
    this.contextOpen = open;
    this.update(this);
  }
}

export function CatComponent({ data }: { 
  data: CatControl 
}) {
  if (!data.nodeId) return <p>Error</p>;

  return (
    <CustomControl 
      nodeId={data.nodeId}
      label="Cat" 
      iconSource="gear" 
      markerSource='node-addon' 
      contextOpen={data.contextOpen}
      setContextOpen={data.setContextOpen}
    />
  )
}