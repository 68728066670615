import React from 'react'
import styled from 'styled-components';

const ControlDiv = styled.div`
  width: 100%;
  display: flex;
  gap: var(--8px);
  justify-content: space-between;
  padding: var(--8px);

  button.input-tuple-control-btn {
    width: 50%;
    padding: var(--4px);
    background-color: #181837;
    transition: all 0.3s;
    border-radius: var(--4px);

    &:hover {
      background-color: #7b56cb;
    }
  }
`

type InputControl = {
  inputRef: React.RefObject<HTMLInputElement>;
  handleChange: () => void;
}

export default function OptionInputItemControl({
  inputRef,
  handleChange
}: InputControl ) {
  
  const increase = () => {
    if (inputRef.current) inputRef.current.value = (Number(inputRef.current.value) + 1).toString();
    handleChange();
  }

  const decrease = () => {
    if (inputRef.current) inputRef.current.value = (Number(inputRef.current.value) - 1).toString();
    handleChange();
  }

  return (
  <ControlDiv className="input-tuple-control">
    <button className="input-tuple-control-btn" type="button" onClick={increase}>+</button>
    <button className="input-tuple-control-btn" type="button" onClick={decrease}>-</button>
  </ControlDiv>
  )
}
