import React, { useContext, useState } from 'react';

import { CustomThemeContext } from '../../../utils/Context';
import { StorageContext } from '../../../context/StorageContext';
import { useLocation } from 'react-router-dom';
import CanvasSearch from '../../../components/canvasSearch/CanvasSearch';

export default function StorageHeader({baseDir, setKeyword}) {
  const location = useLocation();
  const { theme } = useContext(CustomThemeContext);
  const { 
    curDir, 
    setCurDir,
    renameCurDirectory,
    createDirectory
  } = useContext(StorageContext);

  //dir select
  const [selectOpen, setSelectOpen] = useState<boolean>(false);

  return (
    <>  {
      !location.pathname.includes('smc') &&
        <div className="process-root">
          <div onClick={() => setCurDir('')} className='dir-name'>
            <span>내 드라이브</span>
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.5791 18.7734L15.5791 12.7734L9.5791 6.77344" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
          {
            curDir !== baseDir 
            &&
            curDir.split('/').map((dir, index) => {
            if (index !== curDir.split('/').length - 1) {
              let targetPath = '';

              for (let i = 0; i <= index; i++) {
                targetPath += `${i === 0 ? '' : '/'}${curDir.split('/')[i]}`;
              }

              return (
                <div onClick={() => setCurDir(targetPath)} className='dir-name'>
                  <span>{dir}</span>
                  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.5791 18.7734L15.5791 12.7734L9.5791 6.77344" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
              )
            }
            else
              return (
                <div className='dir-name'>
                  <span>{dir}</span>
                  <img 
                    id="root-selector" 
                    src={process.env.PUBLIC_URL + theme === 'dark' ? '/images/data-root-down.svg' : '/images/data-root-down-white.svg'} 
                    onClick={(e) => { 
                      e.stopPropagation(); 
                      setSelectOpen(!selectOpen);
                    }} 
                    alt="" 
                    />
                  {
                    selectOpen &&
                    <ul id="root-select">
                      <li className='root-option' onClick={createDirectory}>Create New Folder</li>
                      <li className='root-option' onClick={renameCurDirectory}>Rename Current Folder</li>
                      <li className='root-option'>Delete Current Folder</li>
                    </ul>
                  }
                </div>
              )
            })
          }
        </div>
      }
      <CanvasSearch setKeyword={setKeyword}/>
    </>
  )
}
