import React, { Dispatch, SetStateAction, useContext, useEffect, useRef } from 'react';
import { ILayerOption } from '../../flow';
import { styled } from 'styled-components';
import { CustomThemeContext } from '../../../../utils/Context';

const Container = styled.div`
  display: flex;
  flex-direction: ${(props) => props.$flexDirection === 'column' ? 'column' 
  : `
  row;
  gap: var(--16px);
  align-items: center;
  `};

  label.custom-select-label {
    display: block;
    margin-bottom: var(--8px) !important;
  }

  &.custom-select select {
    box-sizing: border-box;
    border: 4px solid ${(props) => props.$theme === 'light' ? '#D9D9E5' : '#131336'} !important;
    padding: 0 var(--12px) !important; 
    border-radius: var(--12px) !important;
    margin-bottom: var(--16px);
    height: var(--60px);
  }
`

interface CustomSelectProps extends React.InputHTMLAttributes<HTMLSelectElement> {
  id: string;  
  label: string;
  options: string[];
  keyword?: string;
  initOption: { current: ILayerOption };
  setValue: Dispatch<SetStateAction<string>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  flexDirection?: string;
}

export default function CustomSelect({ 
  id, label, 
  options, keyword, 
  setValue, initOption, 
  setLoading,  
  flexDirection='column',
  ...rest 
}: CustomSelectProps) {
  const {theme} = useContext(CustomThemeContext);
  const selectRef = useRef<HTMLSelectElement>(null);

  // 초기설정
  useEffect(() => {
    if (!selectRef.current) return;
    
    // initValue 가져오기
    let initValue = initOption.current[`${id}`] || options[0];

    // 가져온 initValue 설정하기
    if (initValue) {
      selectRef.current.value = initValue;
      setValue(initValue);
    }
    
    setLoading(false);
  }, [initOption.current])

  return (
    <Container className='custom-select' $theme={theme} $flexDirection={flexDirection}>
      <label className='custom-select-label' htmlFor={id}>{label}</label>
      <select ref={selectRef} id={id} onChange={(e) => setValue(e.target.value)} style={{ width: '100%' }} {...rest}>
        {options.map(data => <option key={data.toString()}>{keyword ? data[keyword] : data}</option>)}
      </select>
    </Container>
  )
}
