import React, { useRef } from 'react'
import { useNavigate } from 'react-router-dom';

export default function NavigateButton({ data, handleEnter, handleLeave }) {
  const { title, version, desc, btnBG, btnColor, url } = data;
  const btn = useRef();
  const navigate = useNavigate();
  
  const handleOver = () => {
    btn.current.setAttribute("style", `background-image: url(./${btnBG});`)
  }

  const resetStyle = () => {
    handleLeave();
    btn.current.setAttribute("style", `background: ${btnColor}`)
  }

  const handleBtnClick = () => {
    navigate(url);
  }

  const splitDesc = desc.split("\n").map((v, i)=>{
    return (
      <>
        {v}
        <br key={i}/>
      </>
    )
  });

  return (
    <button
      ref={btn} 
      type="button" 
      className={`navigate-btn ${title}`}
      onMouseEnter={handleEnter} 
      onMouseLeave={resetStyle}
      onMouseOver={handleOver}
      onTouchStart={handleEnter}
      onTouchEnd={resetStyle}
      onClick={handleBtnClick}
      style={{backgroundColor: btnColor}}
    >
      <p className={`${title} desc`}>
        {splitDesc}
      </p>
      <div className={`${title} divide-line`}/> 
      <div className={`${title} title-container`}>
        <h3 className={title}>{title}</h3>
        <p className={title}>{version}</p>
      </div>
    </button>
  )
}
