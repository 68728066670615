import { ClassicPreset } from "rete";


export class CustomSocket extends ClassicPreset.Socket {
    constructor() {
        super('Custom');
    }

    isCompatibleWith(socket: ClassicPreset.Socket) {
        return socket instanceof CustomSocket;
    }
}

export class AddonSocket extends ClassicPreset.Socket {
    constructor() {
        super('Addon');
    }

    isCompatibleWith(socket: ClassicPreset.Socket) {
        return socket instanceof AddonSocket;
    }
}