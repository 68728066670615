import React, { Dispatch, SetStateAction, useContext } from 'react'
import { Link } from 'react-router-dom';

import { CustomThemeContext } from '../../../utils/Context';

type WorkspaceBtnsProps = {
  setContext: any;
  workspaceList: any[];
  setTemplateModalOpen?: Dispatch<SetStateAction<boolean>>;
  keyword?: string;
}

export default function WorkspaceBtns({
  setContext,
  workspaceList,
  setTemplateModalOpen,
  keyword,
}: WorkspaceBtnsProps ) {
  
  const { theme } = useContext(CustomThemeContext);

  const handleClickBtn = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, workspace) => {
    if (workspace.thumbnail && setTemplateModalOpen) { // 추천 template이면
      e.preventDefault();
      setTemplateModalOpen(workspace);
    } 
  }

  const handleRightClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, workspace) => {
    if (workspace.thumbnail && setTemplateModalOpen) { // 추천 template이면
      e.preventDefault();
    } else {
      setContext(e, workspace.wor_id);
    }
  }

  return (
    <>
      {
        workspaceList.map(workspace => {
          let title;
          if (keyword) {
            const index = workspace.title.toLowerCase().indexOf(keyword.toLowerCase());
            title = (
              <strong>
                {workspace.title.slice(0, index)}
                <span style={{color: '#8D59FC'}}>
                  {workspace.title.slice(index, index + keyword.length)}
                </span>
                {workspace.title.slice(index + keyword.length)}
              </strong>
            )
          }
          return (
            <li 
              key={workspace.wor_id}
              id={workspace.wor_id} 
              draggable='false'
              className='workspace-btn'
            >
              <Link 
                to={`/vience-canvas/mlops/${workspace.wor_id}`}
                onClick={(e) => handleClickBtn(e, workspace)}
                onContextMenu={(e) => handleRightClick(e, workspace)}
                draggable='false'
              >
                <div className="workspace-card-text">
                  <div className="workspace-card-title">
                    <img 
                      src={
                        process.env.PUBLIC_URL + theme === 'dark' 
                          ? '/images/ico-tree.svg' 
                          : '/images/ico-tree-black.svg'
                      } 
                      alt="icon-branch" 
                    />
                    <strong>{keyword ? title : workspace.title}</strong>
                  </div>

                  <div className="workspace-card-date">
                    <span>
                      {workspace.updated_at?.slice(0, 10)} 
                      &nbsp;{workspace.updated_at?.slice(11, 16)}
                    </span>
                    <span className='workspace-category'>{workspace.category}</span>
                  </div>
                </div>
      
                <div className="workspace-card-img">
                    <img src={
                      workspace.thumbnail 
                        ? `data:image/jpeg;base64,${workspace.thumbnail}`
                        : (
                          process.env.PUBLIC_URL + theme === 'dark' 
                          ? '/images/test-img.png' 
                          : '/images/test-img-white.png'
                        )
                      } 
                      alt="thumbnail-workspace-preview" 
                    />
                </div>
      
              </Link>
            </li>
          )
        })
      }
    </>
  )
}
