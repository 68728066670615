import React, { Dispatch, SetStateAction, useContext, useEffect, useRef } from 'react'

import { CustomThemeContext } from '../../../utils/Context';

import { Editor, useMonaco } from '@monaco-editor/react';
import styled from 'styled-components';

interface PlaygroundProps {
  code: string;
  setCode?: Dispatch<SetStateAction<string>>;
  height?: string;
  readOnly?: boolean;
}

const PlaygroundDiv = styled.div`
  @font-face {
    font-family: "codicon";
    src: url(/src/assets/font/codicon.ttf);
  }
  
  * {
    font-family: 'codicon' !important;
  }

  width: '100%';
  .monaco-list .monaco-list-rows .monaco-list-row {
    > .contents {
      .left {
        padding: var(--0px);
        height: var(--24px);
      }
    }
  }
`

export default function Playground({ 
  code, 
  setCode,
  height = '100vh',
  readOnly,
 }: PlaygroundProps) {
  // 테마 설정
  const { theme } = useContext(CustomThemeContext);

  const monaco = useMonaco();
  
  useEffect(() => {
    if (monaco) {
      // 모나코 다크 테마
      monaco.editor.defineTheme("mlops-dark", {
        base: "vs-dark",
        inherit: true,
        rules: [
          { token: "keyword", foreground: "b47ef7" },
        ],
        colors: {
          "editor.foreground": "#EDF9FA",
          "editor.background": "#010115",
          "editorCursor.foreground": "#b47ef7",
          "editor.lineHighlightBackground": "#0000FF20",
          "editorLineNumber.foreground": "#b47ef777",
          "editorLineNumber.activeForeground": "#b47ef7",
          "editor.selectionBackground": "#b47ef799",
          "editor.inactiveSelectionBackground": "#b47ef777",
          "editor.wordHighlightBackground": "#b47ef755",
        },
      })
  
      // 모나코 라이트 테마
      monaco.editor.defineTheme("mlops-light", {
        base: "vs",
        inherit: true,
        rules: [
          { token: "keyword", foreground: "b47ef7" },
        ],
        colors: {
          "editor.foreground": "#202021", // 폰트 컬러
          "editor.background": "#f0f2fd", // 배경
          "editorCursor.foreground": "#b47ef7", // 커서
          "editor.lineHighlightBackground": "#0000FF20", // 현재 작업 줄 표시 배경색
          "editorLineNumber.foreground": "#b47ef777", // 줄 수 폰트 컬러
          "editorLineNumber.activeForeground": "#b47ef7", // 현재 작업 줄 수 폰트 컬러
          "editor.selectionBackground": "#b57ef75c", // 드래그 영역
          "editor.inactiveSelectionBackground": "#d6b3ff5f", // 드래그 영역 - playground 비활성화
          "editor.wordHighlightBackground": "#969adf7b", // 커서 가까운 단어 강조 배경색
        },
      })

      monaco.editor.setTheme(theme === "dark" ? "mlops-dark" : "mlops-light")
    }
  }, [monaco]);

  const prevValue = useRef('');
  const handleCodeChange = (value) => {
    prevValue.current = value;
    setTimeout(() => {
      if (prevValue.current === value) {
        setCode && setCode(value);
      }
    }, 400);
  }
  
  // theme 변경
  useEffect(() => {
    monaco?.editor.setTheme(theme === "dark" ? "mlops-dark" : "mlops-light")
  }, [theme]);

  return (
    <PlaygroundDiv style={{
      height: height,
    }}>
      <Editor 
        defaultLanguage='python'
        value={code}
        theme={theme === "dark" ? "mlops-dark" : "mlops-light"}
        options={{
          fontSize: 16,
          lineHeight: 24,
          roundedSelection: true,
          contextmenu: true,
          wordWrap: "on",
          readOnly: readOnly,
          fontLigatures: true,
        }}
        onChange={handleCodeChange}
      />
    </PlaygroundDiv>
  )
}
