import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import OptionInput from '../PanelOptionComponent/OptionInput';
import { isInt } from '../PanelOptionComponent/inputUtils';

const StyledDiv = styled.div`
  height: 100vh;
  padding-top: var(--20px);

  label {
    padding: var(--20px) 0 0 0 !important;
    color: #E3D8F1;
    font-size: var(--16px);
    font-weight: 600;
    letter-spacing: var(--spacingDefalt);
  }
`

export default function DatasetOptionSection({ nodeId, initOption, setOption, loading, setLoading }) {
  const [nodeName, setNodeName] = useState<string>('');
  const [datasetResolution, setDatasetResolution] = useState<number>(0);
  const [outChannel, setOutChannel] = useState<number>(1);
  
  // 각 input에 초기값 세팅되기 전까지 로딩 상태여야함
  useEffect(() => {
    setLoading(true);
    setNodeName('');
    setOutChannel(1);
    setDatasetResolution(0);
  }, [nodeId])

  useEffect(() => {
    if (!loading) {
      setOption((prev) => ({
        ...prev, 
        name: nodeName,
        datasetResolution, 
        outChannel
      }))
    }
  }, [
    nodeName,
    datasetResolution, 
    outChannel
  ]);

  useEffect(() => {
    console.log(initOption.current)
  }, [initOption.current.name])
  
  return (
    <StyledDiv>
      <OptionInput initOption={initOption} setValue={setNodeName} id={"name"} label='Name of Node' setLoading={setLoading} type='text'/>
      <OptionInput initOption={initOption} setValue={setOutChannel} id={"outChannel"} label='Out Channel' setLoading={setLoading} isValidInput={isInt}/>
      <OptionInput initOption={initOption} setValue={setDatasetResolution} id={"datasetResolution"} label='Image Resolution' setLoading={setLoading} isValidInput={isInt}/>
    </StyledDiv>
  )
}
