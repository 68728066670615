import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colorArr } from './TrainResultGraph';

const Container = styled.div`
  position: absolute;
  font-size: var(--12px);
  padding: var(--4px);
  border-radius: var(--4px);
  transform: translate(1em, -1em);
  &.tooltip {
    color: ${props => props.$color};
  }
  
  &.hovered {
    background-color: ${props => props.$color};
    color: white;
    z-index: 10;
  }
`

// Information needed to build the tooltip
export type InteractionData = {
  x: number;
  y: number;
  label: string;
};

type TooltipProps = {
  interactionData: string[];
  hovered: string;
};

export const GraphLabel = ({ interactionData, hovered }: TooltipProps) => {
  const [positions, setPositions] = useState<(Element | null)[]>([document.body]);
  useEffect(() => {
    setPositions(interactionData.map(datum => document.querySelector(`.${datum}`)));
  }, [interactionData])

  const graphLabels = interactionData.map((datum, index) => {
    return (
      <Container
        key={datum}
        id={`tooptip`}
        className={`tooltip ${datum === hovered ? 'hovered' : ''}`}
        $color = {colorArr[index]}
        style={{
          left: positions[index]?.getAttribute('cx')?.toString() + 'px',
          top: `calc(${positions[index]?.getAttribute('cy')}px - ${index}em)`
        }}
      >
        {datum}
      </Container>
    )
  })
  
  return (
    <>
      { interactionData && positions && graphLabels }
    </>
  );
};
