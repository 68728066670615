import React, { Dispatch, SetStateAction, useContext } from 'react'
import { IfileNdirList, filedirName } from '../../../../routes/datamanage/storage/storage.type';
import { CustomThemeContext } from '../../../../utils/Context';

interface StorageCardProps {
    selected: { path: filedirName, name: filedirName, thumbnail: string };
    setSelected: Dispatch<SetStateAction<{ path: filedirName, name: filedirName, thumbnail: string }>>;
    curDir: string;
    setCurDir: Dispatch<SetStateAction<string>>;
    info: IfileNdirList;
}

export default function StorageCard ({ selected, setSelected, curDir, setCurDir, info }: StorageCardProps) {
    const { theme } = useContext(CustomThemeContext);
    return (
        <div 
            className={`storage-card${
                selected.path === curDir 
                && selected.name === info.name 
                    ? '-selected' 
                    : ''
            }`}
            draggable={true}
            onClick={(e) => {
                // if (info.type === 'dir') return;
                setSelected({
                    path: curDir, 
                    name: info.name,
                    thumbnail: info.thumbnail
                });
            }}
            onDoubleClick={(e) => {
                if (info.type === 'dataset' || info.type === 'dir' || info.type === 'model') {
                    if (info.name == '..') {
                        let newDir = ``;
                        for (let i = 0; i < curDir.split('/').length - 1; i++) {
                            newDir += `${i === 0 ? '' : '/'}${curDir.split('/')[i]}`;
                        }
                        setCurDir(newDir);
                        return;
                    }
                    if (curDir === '') setCurDir(info.name);
                    else setCurDir(`${curDir}/${info.name}`);
                }
                else {
                    if (curDir === '') window.open(`/image/${info.name}`, '_blank')
                    else window.open(`/image/${curDir}/${info.name}`, '_blank')
                }
            }}
        >
            < div className='storage-card-title' >
                <div className="storage-card-title-left">
                    {
                        {
                            dir: <img src={process.env.PUBLIC_URL + theme === 'dark' ? '/images/datamanage/card/title/data-title-folder.svg' : '/images/datamanage/card/title/data-title-folder-black.svg'} alt="" className='data-title-folder' />,
                            dataset: <img src={process.env.PUBLIC_URL + '/images/datamanage/card/title/data-title-folder.svg'} alt="" className='data-title-folder' />,
                            model: <img src={process.env.PUBLIC_URL + '/images/datamanage/card/title/data-title-folder.svg'} alt="" className='data-title-folder' />,
                            image: <img src={process.env.PUBLIC_URL + '/images/datamanage/card/title/data-title-img.svg'} alt="" className='data-title-img' />,
                            weight: <img src={process.env.PUBLIC_URL + '/images/datamanage/card/title/data-title-file.svg'} alt="" className='data-title-file' />,
                            file: <img src={process.env.PUBLIC_URL + '/images/datamanage/card/title/data-title-file.svg'} alt="" className='data-title-file' />,
                        }[info.type]
                    }
                    <span>{info.name}</span>
                </div>
                <div className="storage-card-title-right">{info.updated_at.slice(0, 10)}</div>
            </div >
            <div className='storage-card-icon-frame'>
                {
                    {
                        dir: info.thumbnail === 'empty' ? <img src={process.env.PUBLIC_URL + '/images/datamanage/card/thumbnail/data-folder.svg'} alt="" className='data-type-folder' /> : <img src={process.env.PUBLIC_URL + '/images/datamanage/card/thumbnail/data-folder-file.svg'} alt="" className='data-type-folder-file' />,
                        dataset: <img src={process.env.PUBLIC_URL + '/images/datamanage/card/thumbnail/data-folder-file.svg'} alt="" className='data-type-folder-file' />,
                        model: <img src={process.env.PUBLIC_URL + '/images/datamanage/card/thumbnail/data-folder-file.svg'} alt="" className='data-type-folder-file' />,
                        image: <img src={`data:image/jpeg;base64,${info.thumbnail}`} alt="" className='data-type-img' />,
                        weight: <img src={process.env.PUBLIC_URL + '/images/datamanage/card/thumbnail/data-file.svg'} alt="" className='data-type-file' />,
                        file: <img src={process.env.PUBLIC_URL + '/images/datamanage/card/thumbnail/data-file.svg'} alt="" className='data-type-file' />,
                    }[info.type]
                }
                <p className="storage-card-name">{info.name}</p>
            </div>
        </div >
    )
}