import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { SmcContext } from '../../context/SmcContext'

import OpenSeaDragon, { type Viewer } from "openseadragon";
import OpenSeadragon from 'openseadragon';
import LabelOpacitySlider from './LabelOpacitySlider';
import { useAPIwithCookies } from "../../hooks/useApiCookies";
import { StorageContext } from '../../context/StorageContext';

const Container = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  background-color: black;

  #openSeaDragon-proofreader {
    width: 100%;
    height: 100%;
  }

  .overlay-box {
    border: 4px dashed rgb(0,255,255);
    position: absolute;
    pointer-events: auto;
  }

  .overlay-box:hover,
  .overlay-box:focus {
    filter: alpha(opacity=40);
    opacity: 0.4;
    background-color: white;
  }

  .opacity-slider {
    width: 50%;
    position: absolute; /* 부모 요소를 기준으로 위치 지정 */
    top: 5%;
    left: 50%;
    transform: translate(-50%, 0%); /* 중앙 정렬 */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    background-color: rgba(255,255,255,0.5);
  }

`
  
export default function SmcProofreader() {
  const [viewer, setViewer] = useState<Viewer | null>(null);
  const token = localStorage.getItem('access');
  const api = useAPIwithCookies();

  const { selectedFile } = useContext(StorageContext);

  const [blendFactor, setBlendFactor] = useState(0.0);

  const [boundingBoxes,setBoundingBoxes] = useState<any>([]);

  const [ind,setInd] = useState(0);
  const [isFocus,setIsFocus] = useState(false);
  const [focusedOverlay,setFocusedOverlay] = useState<any>();
  const [isDrawMode,setIsDrawMode] = useState(false);

  const [isViewerReady, setIsViewerReady] = useState(false);
  const [isInitialBoundingBoxReady, setIsInitialBoundingBoxReady] = useState(false);

  const handleOnKeyDown = (event) => {
    if (event.key === 'Shift')setIsDrawMode(true);
  }
  const handleOnKeyUp = (event) => {
    if (event.key === 'Shift') setIsDrawMode(false);
  };

  useEffect(()=>{
    document.addEventListener('keydown', handleOnKeyDown);
    document.addEventListener('keyup', handleOnKeyUp);

    return () => {
      document.removeEventListener('keydown', handleOnKeyDown);
      document.removeEventListener('keyup', handleOnKeyUp);
    }
  }, []);

//@router.post('/romove_bounding_box/{index:str}/{path:path}')

  const removeOverlayAPI = async (id, path) =>{
    var response = await api.post(
      "/smc/remove_bounding_box/" + id + "/" + path
    );
    console.log(path);
    console.log('remove bounding box: ', response.data);
  };

  //@router.post('/add_bounding_box/{index:str}/{min_x:int}/{min_y:int}/{width:int}/{height:int}/{path:path}')

  const addOverlayAPI = async (index, newBoundingBox, path) =>{
    var response = await api.post(
      "/smc/add_bounding_box/" + index + "/" + Math.round(newBoundingBox[0]) + "/" + Math.round(newBoundingBox[1]) + "/" + Math.round(newBoundingBox[2]) + "/" + Math.round(newBoundingBox[3]) + "/" + path
    );
    console.log('add bounding box: ', response.data);
  };


  const handleRemoveOverlay = (event) => {
    if(!viewer)return;

    removeOverlayAPI(focusedOverlay.id, selectedFile.path + "/" + selectedFile.name);
    delete boundingBoxes[focusedOverlay.id];
    setBoundingBoxes({...boundingBoxes});
    viewer.removeOverlay(focusedOverlay);
    focusedOverlay.remove();
    setIsFocus(false);

  };

  useEffect(() => {
    if (viewer && isFocus && !isDrawMode) {
      viewer.zoomPerClick = false;
      viewer.addHandler('canvas-press', handleRemoveOverlay);
    } 

    return () => {
      if(viewer){
        viewer.zoomPerClick = true;
      viewer.removeHandler('canvas-press', handleRemoveOverlay);
      }
    }
  }, [isFocus, isDrawMode, focusedOverlay, handleRemoveOverlay, viewer]);


  const [isDrawing,setIsDrawing] = useState(false);
  const [startX,setStartX] = useState(0);
  const [startY,setStartY] = useState(0);
  const [overlayElement,setOverlayElement] = useState<any>();

  


  const handleMousePress = (event) => {
    if(!viewer)return;
    const tile = viewer.world.getItemAt(0);
    if (!isDrawing) {
        var viewportPoint = viewer.viewport.pointFromPixel(event.position);
        var imagePoint = tile.viewportToImageCoordinates(viewportPoint);
          // Start drawing
        setStartX(imagePoint.x);
        setStartY(imagePoint.y);
        var newOverlayElement = document.createElement('div');
        newOverlayElement.id = String(ind);
        setInd(ind+1);
        newOverlayElement.className = 'overlay-box';
        newOverlayElement.style.left = startX + 'px';
        newOverlayElement.style.top = startY + 'px';
        document.getElementById('openSeaDragon-proofreader')?.appendChild(newOverlayElement);
        setIsDrawing(true);
        setOverlayElement(newOverlayElement);
        viewer.addOverlay({
          element: newOverlayElement,
          location: tile.imageToViewportRectangle(startX, startY, 0, 0)
        });
    }
  };

  const handleMouseDrag = (event) => {
    if(!viewer)return;
    const tile = viewer.world.getItemAt(0);
    var viewportPoint = viewer.viewport.pointFromPixel(event.position);
    var imagePoint = tile.viewportToImageCoordinates(viewportPoint);
    var width = Math.abs(imagePoint.x - startX);
    var height = Math.abs(imagePoint.y - startY);
    // overlayElement.style.width = width + 'px';
    // overlayElement.style.height = height + 'px';

    // var overlay = osd.getOverlayById(overlayElement);
    // overlay.update(tile.imageToViewportRectangle(startX, startY, width, height),OpenSeadragon.Placement.CENTER);
    var x = startX;
    if(imagePoint.x<startX){
      x = imagePoint.x;
    }
    var y = startY;
    if(imagePoint.y<startY){
      y = imagePoint.y;
    }
    viewer.updateOverlay(overlayElement,tile.imageToViewportRectangle(x, y, width, height));
  }; 

  const handleMouseRelease = (event) => {
    if(!viewer)return;
    if(!overlayElement)return;
      setIsDrawing(false);
      overlayElement.addEventListener('mouseenter', function() {
        setIsFocus(true);
        setFocusedOverlay(this);
      });
      overlayElement.addEventListener('mouseleave', function() {
        setIsFocus(false);
      });


      const tile = viewer.world.getItemAt(0);
      var viewportPoint = viewer.viewport.pointFromPixel(event.position);
      var imagePoint = tile.viewportToImageCoordinates(viewportPoint);
      var width = Math.abs(imagePoint.x - startX);
      var height = Math.abs(imagePoint.y - startY);
      var x = startX;
      if(imagePoint.x<startX){
        x = imagePoint.x;
      }
      var y = startY;
      if(imagePoint.y<startY){
        y = imagePoint.y;
      }
      var newBoundingBox = [x,y,width,height];
      var cur_ind = overlayElement.id;
      setBoundingBoxes({...boundingBoxes, [cur_ind]: newBoundingBox});
      addOverlayAPI(cur_ind,newBoundingBox,selectedFile.path + "/" + selectedFile.name);
  };


  useEffect(() => {
    if (viewer && isDrawMode) {
      viewer.gestureSettingsMouse.dragToPan = false;
      viewer.addHandler('canvas-press', handleMousePress);
    } 

    return () => {
      if(viewer){
        viewer.gestureSettingsMouse.dragToPan = true;
        viewer.removeHandler('canvas-press', handleMousePress);
      }
    }
  }, [isDrawMode, handleMousePress, viewer]);

  useEffect(() => {
    if (viewer && isDrawMode) {
      viewer.gestureSettingsMouse.dragToPan = false;
      viewer.addHandler('canvas-release', handleMouseRelease);
      viewer.addHandler('canvas-drag', handleMouseDrag);
    } 

    return () => {
      if(viewer){
        viewer.gestureSettingsMouse.dragToPan = true;
        viewer.removeHandler('canvas-release', handleMouseRelease);
        viewer.removeHandler('canvas-drag', handleMouseDrag);
      }
    }
  }, [isDrawing, startX, startY, handleMouseRelease, handleMouseDrag, viewer]);




  const InitOpenseadragon = async () => {
    viewer && viewer?.destroy();

    const src = [
      process.env.REACT_APP_VIENCE_API_KEY + "/smc_proofreading/" + token + "/" +  selectedFile.path + "/" + selectedFile.name + "/img.dzi",
      process.env.REACT_APP_VIENCE_API_KEY + "/smc_proofreading/" + token + "/" +  selectedFile.path + "/" + selectedFile.name + "/label.dzi"
    ];

    const osd: Viewer = OpenSeaDragon({
        id: "openSeaDragon-proofreader",
        prefixUrl: "openseadragon-images/",
        tileSources: src,
        crossOriginPolicy: "Anonymous",
        animationTime: 0.5,
        blendTime: 0.1,
        constrainDuringPan: true,
        maxZoomPixelRatio: 10,
        minZoomLevel: 0.1,
        visibilityRatio: 1,
        zoomPerScroll: 1.4,
        showNavigator: false,
        zoomInButton: "pv_zoom-in",
        zoomOutButton: "pv_zoom-out",
        homeButton: "pv_home",
        alwaysBlend: true,
    });
    osd.addHandler('open', 
      function() {
        setIsViewerReady(true);
        osd.removeHandler('open');
      });  

  
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });

    setViewer(osd);
  };

  useEffect(() => {
    if (viewer === null){
        InitOpenseadragon();
    } 
    else if (viewer) {
      const src = [
        process.env.REACT_APP_VIENCE_API_KEY + "/smc_proofreading/" + token + "/" + selectedFile.path + "/" + selectedFile.name + "/img.dzi",
        process.env.REACT_APP_VIENCE_API_KEY + "/smc_proofreading/" + token + "/" +  selectedFile.path + "/" + selectedFile.name + "/label.dzi"
      ];
      viewer.open(src);
      viewer.addHandler('open', 
        function() {
          const tiledImage_region = viewer.world?.getItemAt(1);
          if(tiledImage_region){
            tiledImage_region.setOpacity(blendFactor);
            viewer.world?.update();
          }
          setIsViewerReady(true);
          viewer.removeHandler('open');
      });  
    }
  }, [selectedFile, viewer]);

  useEffect(() => {
    if(viewer){
      var tiledImage = viewer.world.getItemAt(1);
      tiledImage?.setOpacity(blendFactor);
      viewer.world.update();
    }
  }, [blendFactor]);

  const getBoundingBox = async () => {
    var response = await api.get(
      "/smc/bounding_box/" + selectedFile.path + "/" + selectedFile.name
    );
    var max_v = 0;
    for (const key in response.data) {
      if(Number(key)>max_v){
        max_v = Number(key);
      }
    }
    setInd(max_v+1);
    setBoundingBoxes(response.data);
    setIsInitialBoundingBoxReady(true);
  };

  useEffect(()=>{
    // get bounding box
    getBoundingBox(); 
  },[]);

  useEffect(()=>{
    // apply bounding box
    // clear overlays
    // and add overlays
    // redraw label tiles
    console.log(Object.entries(boundingBoxes).length);
    
  },[boundingBoxes]);


  useEffect(()=>{
    // apply bounding box
    // clear overlays
    // and add overlays
    // redraw label tiles
    if(isViewerReady && isInitialBoundingBoxReady){
      viewer.clearOverlays();
      const tile = viewer.world.getItemAt(0);
      for(const key in boundingBoxes){
        var  startX = boundingBoxes[key][0]-10;
        var  startY = boundingBoxes[key][1]-10;
        var  width = boundingBoxes[key][2]+20;
        var  height = boundingBoxes[key][3]+20;
        const newOverlayElement = document.createElement('div');
        newOverlayElement.id = String(key);
        newOverlayElement.className = 'overlay-box';
        document.getElementById('openSeaDragon-proofreader')?.appendChild(newOverlayElement);
        viewer.addOverlay({
          element: newOverlayElement,
          location: tile.imageToViewportRectangle(startX, startY, width, height)
        });
        newOverlayElement.addEventListener('mouseenter', function() {
          setIsFocus(true);
          setFocusedOverlay(this);
        });
        newOverlayElement.addEventListener('mouseleave', function() {
          setIsFocus(false);
        });
      }
    }
  },[isViewerReady, isInitialBoundingBoxReady]);


  return (
    <Container id="openSeaDragon-proofreader">
      <div className='opacity-slider'>
        <LabelOpacitySlider 
          setOpacity={setBlendFactor}
          opacity={ blendFactor }
          label={'Label Opacity'}
        />
      </div>

    </Container>
  )
}
