import React, { useCallback, useEffect, useState } from "react";
import { debounce } from 'lodash';
import OpenSeaDragon from "openseadragon";
import Overlay from "./Overlay";
import styled from "styled-components";

const Container = styled.div`
  &#osd-annotator {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none !important;
    overflow: hidden;
  }
`;

export default function Annotator ({ 
  osd, 
  shapeList, 
  setShapeList, 
  fb_type
}) {
  const [inDrawMode, setInDrawMode] = useState(false);

  const handleOnKeyDown = (event) => {
    if (event.key === 'Shift') setInDrawMode(true);
  }
  const handleOnKeyUp = (event) => {
    if (event.key === 'Shift') setInDrawMode(false);
  };

  const [currentShape, setCurrentShape] = useState([]);

  const handleOnMouseDown = useCallback(() => {
    setCurrentShape([]);
  }, [setCurrentShape]);

  const handleOnMouseUp = useCallback(() => {
    if ( currentShape.length === 0 ) return;

    setShapeList(shapeList => {
      const newShapeList_type = [
        ...shapeList[fb_type], 
        currentShape
      ];

      const newShapeList = {
        'foreground':shapeList['foreground'],
        'background':shapeList['background']
      };

      newShapeList[fb_type] = newShapeList_type;
      return newShapeList;
    });
    setCurrentShape([]);
  }, [setShapeList, setCurrentShape, currentShape, fb_type]);

  const getImageViewport = () => {
    if (osd === null) {
      return{
        originX: 0,
        originY: 0,
        width: 0,
        height: 0  
      };
    }

    const tile = osd.world.getItemAt(0);

    if (tile === undefined) {
      return{
        originX: 0,
        originY: 0,
        width: 0,
        height: 0  
      };
    }

    const imageOrigin = tile.imageToViewerElementCoordinates(
      new OpenSeaDragon.Point(0, 0)
    );
    
    const imageSize = tile.imageToViewerElementCoordinates(tile.getContentSize());

    return {
      originX: imageOrigin.x,
      originY: imageOrigin.y,
      width: imageSize.x - imageOrigin.x,
      height: imageSize.y - imageOrigin.y
    };
  };

  const handleOnMouseMove = debounce((event) => {
    const viewport = getImageViewport();
    const x = ((event.position.x - viewport.originX) / viewport.width) * 100;
    const y = ((event.position.y - viewport.originY) / viewport.height) * 100;
    setCurrentShape(prev => [ ...prev, {x, y}]);
  }, 10, { maxWait: 50 });

  useEffect(() => {
    if (inDrawMode) {
      osd.gestureSettingsMouse.dragToPan = false;
      osd.addHandler('canvas-press', handleOnMouseDown);
      osd.addHandler('canvas-release', handleOnMouseUp);
      osd.addHandler('canvas-drag', handleOnMouseMove);
    } 

    return () => {
      osd.gestureSettingsMouse.dragToPan = true;
      osd.removeHandler('canvas-press', handleOnMouseDown);
      osd.removeHandler('canvas-release', handleOnMouseUp);
      osd.removeHandler('canvas-drag', handleOnMouseMove);
    }
  }, [inDrawMode, handleOnMouseDown, handleOnMouseUp, handleOnMouseMove, osd]);

  useEffect(()=>{
    window.addEventListener('keydown', handleOnKeyDown);
    window.addEventListener('keyup', handleOnKeyUp);

    return () => {
      window.removeEventListener('keydown', handleOnKeyDown);
      window.removeEventListener('keyup', handleOnKeyUp);
    }
  }, []);

  return (
    <Container id="osd-annotator">
      <Overlay
        shapeList={shapeList['foreground']}
        currentShape={fb_type === 'foreground' ? currentShape:[]}
        getImageViewport={getImageViewport}
        openSeadragon={osd}
        color='rgb(0,0,255)' 
      />
      <Overlay
        shapeList={shapeList['background']}
        currentShape={fb_type === 'background' ? currentShape:[]}
        getImageViewport={getImageViewport}
        openSeadragon={osd}
        color='rgb(255,0,0)'
      />
    </Container>
  );
};

