import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components';

import { TNodeColor } from '../../../../rete-mlops/style';
import { CustomThemeContext } from '../../../../utils/Context';

const NodeListItemContainer = styled.li<{$color : TNodeColor}>`
  position: relative;
  padding: var(--8px) var(--24px);
  display: flex;
  align-items: center;
  gap: var(--8px);
  box-sizing: border-box;

  object{
    width: 22px;
    height: 22px;
  }

  p.node-name {
    font-size: var(--12px);
    line-height: var(--20px);
    color: ${props => props.$color.base};
  }

  .node-type {
    font-weight: 700;
  }
  
  .tooltip {
    display: none;
  }

  &:hover {
    background-color: ${props => props.$color.sub2}33;

    .tooltip {
      display: block;
      top: 0;
      left: 70%;
    }
  }
`

export default function NodeListItem({findNode, iconSource='viewer', data}) {
  const [isHovering, setIsHovering] = useState(false);
  const { theme } = useContext(CustomThemeContext);
  
  return (
    <>
      <NodeListItemContainer 
        $color={data.color}
        onClick={findNode}
        onMouseOver={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <img 
          alt={'icon-'+iconSource}
          src={process.env.PUBLIC_URL + `/images/rete/node/icon/${theme === 'light' ? iconSource+'-black': iconSource}.svg`} 
        />
        <p className='node-name'><span className='node-type'>{data.label}</span> {data.controls.ctrl.props.option.name}</p>
      </NodeListItemContainer>
      
    </>
  )
}
