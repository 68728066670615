import React, { useContext, useEffect, useState } from 'react';

import { TrainResultGraph } from './TrainResultGraph';

import styled from 'styled-components';
import { CustomThemeContext } from '../../../../../../utils/Context';
import { useLocation } from 'react-router-dom';
import { TrainContext } from '../../../../../../context/TrainContext';
import { mockData } from './mockData';
import { editor } from '../../../../../editor';

const Container = styled.div<{$theme: 'light' | 'dark'}>`
  header {
    padding: 0;

    display: flex;
    justify-content: center;
    column-gap: var(--16px);
    margin-bottom: var(--12px);

    button {
      min-width: var(--86px);
      padding: var(--12px);
      ${props => props.$theme === 'light' ?  `
        background-color:#bdbdbd;
        color: #d3d3d3;

        &.selected {
          background-color: #ffffff;
          color: black;
        }
      `: `
        background-color:'#232334';
        color: #525252;

        &.selected {
          background-color: #181837;
          color: white;
        }
      `}
    }
  }

  ul.metric-list{
    max-width: 100%;
    display: grid;
    gap: var(--12px);

    li {
      display: flex;
      flex-direction: column;
      align-items: center;

      padding: var(--12px) 0;
      border: 1px solid white;
      border-radius: var(--12px);
    }
  }
`

function areArraysEqual(array1, array2) {
  if (array1?.length !== array2?.length) {
    return false;
  }
  for (let i = 0; i < array1?.length; i++) {
    if (array1[i] !== array2[i]) {
      return false;
    }
  }
  return true;
}

export default function TrainResultGraphList() {
  const [selectedDataMetric, setSelectedDataMetric] = useState<string[]>([]);
  const [trainResultData, setTrainResultData] = useState<any>([[{x:1, y:0},]]);
  const [trainMetricList, setTrainMetricList] = useState<string[]>(['']);

  const { theme } = useContext(CustomThemeContext);
  const { trainData, stepIndex, setStepIndex, selected } = useContext(TrainContext);
  
  // ---- sample page용 변수들 ---- 
  const {pathname} = useLocation();
  const isSample = pathname.includes('sample');
  const [error, setError] = useState(false);

  const cat = editor?.getNode('8e9f1049571a8c39');
  const convTranspose2d = editor?.getNode('110c21c743d9c5f5');
  const relu = editor?.getNode('d1e20d1b0a51ac34');

  useEffect(() => {
    if ( cat?.error || convTranspose2d?.error || relu?.error ) {
      setError(true);
    }
  }, [cat?.error, convTranspose2d?.error, relu?.error])
  // ---- sample page용 끝 ---- 

  const handleDataMetric = (e) => {
    const buttonText = e.target.textContent;
    setSelectedDataMetric(prev => 
      prev.includes(buttonText) 
      ?  prev.filter(v => v !== buttonText) 
      :[...prev, buttonText]
    );
    e.target.classList.toggle("selected");
  }

  useEffect(() => {
    if (isSample && !error) {
      setTrainResultData(mockData);
      setTrainMetricList(mockData.metric_list);
      if(!areArraysEqual(trainMetricList, mockData.metric_list)){
        setSelectedDataMetric(mockData.metric_list);
      }
    } else if (selected?.status) { // detail page selected로 전달받음
      setTrainResultData(selected?.status);
      setTrainMetricList(selected?.status.metric_list);
      if(!areArraysEqual(trainMetricList, selected?.status.metric_list)){
        setSelectedDataMetric(selected?.status.metric_list);
      }
    } else if (trainData) { // trainPanel 에서 trainContext 에서 받아옴
      setTrainResultData(trainData?.trainResultData);
      setTrainMetricList(trainData?.trainMetricList);
      if(!areArraysEqual(trainMetricList, trainData?.trainMetricList)){
        setSelectedDataMetric(trainData?.trainMetricList);
      }
    } 
  }, [trainData?.trainResultData, selected?.key, selected?.status?.progress])

  useEffect(() => {
    setStepIndex(trainResultData['loss']?.[0]?.['data']?.length ?? 1);
  }, [trainResultData])

  return (
    <Container $theme={theme}>
      <header>
        <h5 className='a11y-hidden'>훈련 결과 data 종류 filter</h5>
        {trainMetricList?.map((metricList) => 
          <button type='button' className={selectedDataMetric.includes(metricList) ? 'selected' : ''} onClick={handleDataMetric}>{metricList}</button>)}
      </header>

      <ul className='metric-list'>
        <h5 className='a11y-hidden'>훈련 결과 data에 따른 graph</h5>
        {selectedDataMetric?.map(metricList => {
          const metricListData = trainResultData[`${metricList}`];
          return (
            <li className='metric-list-item'>
              <h6>{metricList}</h6>
              <TrainResultGraph
                originalData={metricListData ?? mockData} width={100} height={45}
              />
              <p>{metricListData ? metricListData[0].label : ''}: {metricListData ? metricListData[0].data[stepIndex-1]?.y : 1}</p>
              <p>{metricListData ? metricListData[1].label : ''}: {metricListData ? metricListData[1].data[stepIndex-1]?.y : 1}</p>
            </li>
          )
        })}
      </ul>
    </Container>
  )
}
