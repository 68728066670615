// library
import React, { useContext, useEffect, useState } from 'react';
import { useRete } from 'rete-react-plugin';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { NodeTypes, editor as mlopsEditor } from '../../../rete-mlops/editor';

// component
import CanvasHeader from '../../../components/canvasHeader/CanvasHeader';
import MlopsControlPanel from './mlops-control-panel/MlopsControlPanel';
import MlopsSidePanel from './mlops-side-panel/MlopsSidePanel';
import WorkspacePanel from '../../../rete/workspace-panel.component';
import LoadingAnimation from '../../../components/loadingAnimation/LoadingAnimation';

// type
import { type IWorkspaceSetting } from '../../canvas/workspace/workspace';

// utils
import { createEditor } from '../../../rete-mlops/editor';
import { 
  closeAllContext, 
  createByDataStructure, 
  createByJson, 
  process,
} from '../../../rete-mlops/reteMlopsUtils';
import { CustomThemeContext, handleReteTheme } from '../../../utils/Context';

// data
import { getTimeString } from '../../../rete-mlops/components/panel-contents/PanelOptionComponent/inputUtils';
import { NodeContext } from '../../../rete/NodeContext';
import { mockNode } from './mockNode';
import { WorkspaceContext } from '../../../context/WorkspaceContext';
import { IconBtn } from './toolbar/components/IconBtn';
import { Toolbar } from './toolbar/Toolbar';

const TempBtnDiv = styled.div`
  padding: var(--16px);
  display: flex;
  justify-content: center;
  gap: var(--8px);
  height: var(--72px);
  
  background-color: ${({$theme})=> $theme === 'light' ? "#E9EBF8" : "#080821"};
  border-bottom: 1px solid ${({$theme})=> $theme ==='light' ? "#D9D9E5" : "#181839"};

  button {
    padding: var(--8px) var(--16px);
    background-color: ${({$theme})=> $theme ==='light' ? "#fff" : "#181839"};
    border-radius: var(--8px);
    font-weight: 700;
  }
`

export default function MlopsEditorSample() {
  let [ref, editor] = useRete(createEditor);
  const [title, setTitle] = useState<string>('');
  const [setting, setSetting] = useState<IWorkspaceSetting>({ category: '', description: '' , ipAddress: '' });
  const [editable, setEditable] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  
  const location = useLocation();

  const { theme } = useContext(CustomThemeContext);
  const { setSelectedWorkspace } = useContext(WorkspaceContext);

  const { setDynamic } = useContext(NodeContext);

  const getCanvas = async () => {
    const data = mockNode; 
    if (data) {
      setTitle(data.title);
      setEditable(data.edit_yn);
      setSetting({ 
        category: data.category ?? '', 
        description: data.description ?? '', 
        ipAddress: data.ipAddress ?? '' 
      });

      if (data.nodes !== undefined) {
        await createByJson(JSON.parse(data.nodes));
      }
    } 
    handleReteTheme();
  }

  const setCanvas = async () => {
    try {
      // BE에 저장된 Workspace일 때
      await getCanvas();
    } catch (error) {
      //  BE에 저장된 Workspace 가 아니고
      if (location.state) { // Template일 때
        await createByDataStructure(location.state.dataStructure);
        const workspace = location.state.workspace;
        const entries: (string[])[] = Object.entries(workspace?.description ?? [['', '']]);
        const desc = entries.map(([key, value]) => `${key}: ${value}`).join('\n');
        
        setSelectedWorkspace(workspace);
        console.log(workspace);
        setTitle(`${workspace?.title ?? location.state.title}`);
        setSetting({ 
          category: workspace?.category, 
          description: desc, 
          ipAddress: '0.0.0.0' 
        });

      } else { // Template도 아니고 아예 New
        setTitle(`untitled_workspace_${getTimeString()}`);
        setSetting({ 
          category: 'category', 
          description: 'description', 
          ipAddress: '0.0.0.0' 
        });
      }
      
      setEditable(true);
      setTimeout(() => {
        editor?.layout(true, 'simple')
      }, 0)
    }
  }

  const setInit = async () => {
    setLoading(true);
    try {
      await setCanvas();
      await process();
      mlopsEditor.addPipe(async (context) => {
        if (["connectioncreated", "connectionremoved"].includes(context.type)) {
          await process();
        }
        return context;
      });
    } finally {
      setLoading(false);
    }
  }

  // 실행
  useEffect(() => {
    if (editor) {
      setInit();
    }
  }, [editor]);

  useEffect(() => {
    editor?.zoomAt();
  }, [loading])

  const [trainers, setTrainers] = useState<NodeTypes[]>();
  const handleFindTrainer = (e, node = null)=>{
    const newTrainers = editor?.zoomAtTrainer(node);
    setTrainers(newTrainers);
  }

  return (
    <>
      {loading && <LoadingAnimation label={'loading...'} />}
      <div className="canvas-edit" onContextMenu={(e)=>e.preventDefault()}>
        <CanvasHeader />
        <WorkspacePanel title={title} setTitle={setTitle} save={() => {alert('This is Sample Workspace!')}} setSetting={setSetting} setting={setting} />
        
        <Toolbar $theme={theme}>
          <IconBtn property1="align-unet"  handleClick={()=>{editor?.layout(true,'unet')}}/>
          <IconBtn property1="align-center" handleClick={()=>{editor?.layout(true,'simple')}}/>
          <IconBtn property1="reset-view" handleClick={()=>{editor?.zoomAt()}}/>
          <IconBtn 
            property1="find-trainer" 
            handleClick={handleFindTrainer}
            items={trainers}
          />
        </Toolbar>

        <div 
          className="main-canvas-content" 
          style={{
            height: 'calc(100vh - var(--72px) - 55px)'
          }}
        >
          <MlopsControlPanel />
          <div ref={ref} className="rete main-canvas-middle" onClick={closeAllContext}/>
          <MlopsSidePanel />
        </div> 
      </div >
    </>
  )
}
