import * as React from "react";
import { ClassicScheme, Presets } from "rete-react-plugin";
import styled from "styled-components";
import { editor } from "../../rete";


const { useConnection } = Presets.classic;

const Svg = styled.svg`
  overflow: visible !important;
  position: absolute;
  pointer-events: none;
  width: 9999px;
  height: 9999px;
`;

const Path = styled.path < { styles?: (props: any) => any, selected: boolean } > `
  fill: none;
  // stroke-dasharray: ${props => props.selected ? '5px' : '0px'};
  stroke-width: 2px;
  stroke: #464B64;
  pointer-events: auto;
  
  //animation: dash 1.5s linear infinite;
  
  ${(props) => props.styles && props.styles(props)}

  @keyframes dash {
    0% {
      stroke-dashoffset: 100px;
    }
    100% {
      stroke-dashoffset: 0px;
    }
  }
`;

export function CustomConnection(props: {
  data: ClassicScheme["Connection"] & { isLoop?: boolean };
  styles?: () => any;
}) {
  const { path } = useConnection();

  if (!path) return null;
  let selected = editor.getNode(props.data.source)?.selected || editor.getNode(props.data.target)?.selected;
  if (selected === undefined) {
    selected = false;
  }

  return (
    <Svg data-testid="connection">
      <Path styles={props.styles} d={path} selected={selected} />

    </Svg>
  );
}
