import './storage.styles.scss';

import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import StorageCard from './StorageCard';
import EmptyDiv from '../../mlops/train-detail/EmptyDiv';
import ContextMenuContextProvider from '../../../context/ContextMenuContext';

import { StorageContext } from '../../../context/StorageContext';
import StorageHeader from './StorageHeader';
import { IfileNdirList } from './storage.type';
import DropContextProvider from '../../../context/DropContext';

type StorageMainProps = {
  selectedInPanel?: any,
  setSelectedInPanel?: any
  baseDir?: string
  setStep?: any
  setSelectedFile?: any;
}

export default function StorageMain({
  selectedInPanel,
  setSelectedInPanel,
  baseDir = '',
  setStep = () => {},
  setSelectedFile = () => {},
}: StorageMainProps) {
  const location = useLocation();
  const { 
    fileNdirList, 
    getDirectory, 
    curDir, 
    setEndIndex,
    selected,
  } = useContext(StorageContext);

  useEffect(() => {
    getDirectory();
  }, [curDir])

  useEffect(() => {
    if (!selectedInPanel) return;
    const selectedFile = fileNdirList.filter(v=> v.name === selected[0]);
    if (selectedFile.length) setSelectedInPanel(selectedFile[0]);
  }, [selected]);

  //keyword
  const [keyword, setKeyword] = useState('');
  const [filteredFileNdirList, setFilteredFileNdirList] = useState<IfileNdirList[]>([]);

  useEffect(() => {
    const newFileNdirList = fileNdirList.filter(v => v.name.toLowerCase().includes(keyword.toLowerCase()));
    setFilteredFileNdirList(newFileNdirList);
  }, [keyword])

  return (
    <DropContextProvider>
      <div 
        id='storage-grid-main' 
        className={`center`}
        onClick={() => {
          setSelectedFile({path: '', name: '', thumbnail: ''})
        }}
      >
        <div className="inner">
          {
          (location.pathname.includes('storage') || location.pathname.includes('smc')) 
          &&
          <StorageHeader baseDir={baseDir} setKeyword={setKeyword}/>
          }
          <ContextMenuContextProvider >
              <div id="storage-card-frame"
                onClick={(e) => { 
                  setEndIndex(undefined); 
                }}
              >
                {
                  curDir !== baseDir && 
                  <StorageCard
                    keyword={keyword}
                    index={-1}
                    info={{ name: '..', type: 'dir', is_dir: true, thumbnail: 'empty', updated_at: '', path: '' }}
                  />
                }
                {
                  keyword.length 
                    ?
                    filteredFileNdirList.length 
                      ?
                      filteredFileNdirList.map((data, index) =>
                        <StorageCard
                          key={index}
                          info={data}
                          keyword={keyword}
                          index={index}
                          setStep={setStep}
                        />) 
                      :
                      <EmptyDiv label='검색결과 없음' />
                    :
                    fileNdirList.length 
                      ?
                      fileNdirList.map((data, index) =>
                        <StorageCard
                          key={index}
                          info={data}
                          keyword={keyword}
                          index={index}
                          setStep={setStep}
                        />
                      ) 
                      :
                    <EmptyDiv label='빈 폴더입니다' />
                  }
              </div>
          </ContextMenuContextProvider>
        </div>
      </div>
    </DropContextProvider>
  )
}
