import React, { useEffect, useRef, useState } from 'react'
import { FullyConnectedControl } from '../../nodes/FullyConnectedNode'
import { ILayerOption } from '../../flow';
import FullyConnectedOption from './FullyConnectedOptionSection';

export function FullyConnectedPanelContent({ ctrl }: {
  ctrl: FullyConnectedControl
}) {
  const [loading, setLoading] = useState(true);

  // down
  const initOption = useRef<ILayerOption>({});
  // up
  const [option, setOption] = useState<ILayerOption>({});
  
  // 선택된 ctrl.id 바뀔때마다 ctrl에 저장된 옵션으로 바꾸기
  useEffect(() => {
    setLoading(true);

    setOption(ctrl.props.option);
    initOption.current = ctrl.props.option;

    setLoading(false);
  }, [ctrl.nodeId]);

  // 옵션이 바뀌면 ctrl에 저장
  useEffect(()=>{
    if (!loading) {
      ctrl.setValue(option);
    }
  },[option]);

  return (
    <>
      <div className='side-panel-normal'>
        <FullyConnectedOption 
          nodeId={ctrl.nodeId} 
          initOption={initOption} 
          option={option} 
          setOption={setOption} 
          loading={loading} 
          setLoading={setLoading} 
        />
      </div >

      <div className='side-panel-expand' id='#storage-grid'>
        <div className="inner">
        </div>
      </div>
    </>
  )
}
