import * as React from "react";
import { ClassicScheme, Presets, RenderEmit } from "rete-react-plugin";

import styled from "styled-components";

const { RefSocket, RefControl } = Presets.classic;

const ErrorMsg = styled.div`
  @keyframes moveUpForwards {
    0% {
      transform: translateY(30px);
      opacity: 0.5;
    } 100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }

  content: '';
  display: block;
  position: absolute;

  animation: moveUpForwards 0.3s ease-in;
  top: -120px;
  left: calc(50% - 150px);

  width: 300px;
  border-radius: 10px;
  background-color: #d0153b;

  color: white !important;
  font-size: 24px;
  text-align: center;
  text-transform: uppercase;
`

export type TNodeColor = { base: string, sub1: string, sub2: string };

export const SourceColor: TNodeColor = { base: '#5A3B82', sub1: '#8C60C5', sub2: '#6923C3' };
export const EffectorColor: TNodeColor = { base: '#4C7DCC', sub1: '#5C7DCC', sub2: '#729BFC' };
export const EffectorColor_step1: TNodeColor = { base: '#296871', sub1: '#2F7781', sub2: '#226D78' };
export const EffectorColor_step2: TNodeColor = { base: '#2F7781', sub1: '#48AEBC', sub2: '#58AEBC' };
export const EffectorColor_step3: TNodeColor = { base: '#48AEBC', sub1: '#80C8D2', sub2: '#90C8D2' };
export const SinkColor: TNodeColor = { base: '#DB686E', sub1: '#ED959A', sub2: '#B92931' };
export const AddonColor: TNodeColor = { base: '#197C47', sub1: '#45D286', sub2: '#62f5a6' };
export const ResizeColor: TNodeColor = { base: '#ADAA5D', sub1: '#D5D052', sub2: '#FFF500' };
export const NodeSize = { height: 60, width: 222 };

type NodeExtraData = { 
  color?: TNodeColor;
  theme: 'dark' | 'light';
  height: number; 
  width: number; 
  error?: string;
};

export const NodeStyles = styled.div<
  NodeExtraData & 
  { 
    selected: boolean; 
    styles?: (props: any) => any; 
    height: number; 
    width: number;  
  }
>`
  ${props => props.error ? `
    @keyframes blink {
      0% {
        background-color: #d0153b;
      } 100% {
        background-color: ${props.color?.sub1};
        box-shadow: inset 20px 0 0 ${props => props.color?.base};
      }
    }

    animation: blink 1s infinite alternate linear;
    border: 2px red solid;
  ` : ''}

  border-radius: 20px;
  border: 2px ${props =>  props.color?.base} solid;
  box-shadow: inset 20px 0 0 ${props => props.color?.base};
  box-sizing: border-box;
  background: ${props => props.selected ? props.color?.sub2 : props.color?.sub1};

  ${props => props.selected ? `
    box-shadow: 0 0 100px ${props.theme === 'dark' ? '#F4F4FB' : '#010116'};
    animation: none;
    ${props.error ? `
      border: 2px solid #d0153b;
      box-shadow: 0 0 100px #d0153b;
    ` : ``}
  ` : ``};

  width: auto;
  height: auto;

  cursor: pointer;
  position: relative;
  
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;

  .title {
    width: 100%;
    position: absolute;
    top: 0;
    transform: translateY(-100%);
    color: black;
    font-family: sans-serif;
    font-size: 18px;
    text-align: left;
    padding: 8px 0px;
  }
  .wrapper {
    position: absolute;
    width: 31px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  .output {
    display: flex;
    align-items: center;
    transform: translateX(6px);
  }
  .input {
    display: flex;
    align-items: center;
    transform: translateX(-6px);
  }
  .output-socket {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .input-socket {
    text-align: left;
    margin-left: -1px;
    display: flex;
    align-items: center;
  }
  .input-title,
  .output-title {
    vertical-align: middle;
    color: white;
    display: inline-block;
    font-family: sans-serif;
    font-size: 14px;
    margin: 10px;
    line-height: 10px;
  }
  .input-control {
    z-index: 1;
    width: calc(100% - ${10 + 2 * 10}px);
    vertical-align: middle;
    display: inline-block;
  }
  .control {
    height:100%;
    width: 100%;
    display: flex;
    flex-grow: 0;
  }
  ${(props) => props.styles && props.styles(props)}
`;

function sortByIndex<T extends [string, undefined | { index?: number }][]>(
  entries: T
) {
  entries.sort((a, b) => {
    const ai = a[1]?.index || 0;
    const bi = b[1]?.index || 0;

    return ai - bi;
  });
}

type Props<S extends ClassicScheme> = {
  data: S["Node"] & NodeExtraData;
  styles?: () => any;
  emit: RenderEmit<S>;
};
export type NodeComponent<Scheme extends ClassicScheme> = (
  props: Props<Scheme>
) => JSX.Element;

export function CustomNode<Scheme extends ClassicScheme>(props: Props<Scheme>) {
  const inputs = Object.entries(props.data.inputs);
  const outputs = Object.entries(props.data.outputs);
  const controls = Object.entries(props.data.controls);
  const selected = props.data.selected || false;
  const { id, label, color, height, width, error } = props.data;
  sortByIndex(inputs);
  sortByIndex(outputs);
  sortByIndex(controls);
  let theme = localStorage.getItem('theme') as 'light' | 'dark';
  if (theme === null) theme = 'dark';

  return (
    <>
      <NodeStyles
        selected={selected}
        color={color}
        theme={theme}
        height={height}
        width={width}
        error={error}
        style={{
          width: `${width}px`,
          height: `${height}px`
        }}
        data-testid="node"
      >
        {error && selected && <ErrorMsg>
          {error} 
        </ErrorMsg>}
        <div 
          className="wrapper" 
          style={{ left: 0 }}
        >
          {/* Inputs */}
          {inputs.map(
            ([key, input]) =>
              input && (
                <div className="input" key={key} data-testid={`input-${key}`}>
                  <RefSocket
                    name="input-socket"
                    emit={props.emit}
                    side="input"
                    socketKey={key}
                    nodeId={id}
                    payload={input.socket}
                  />
                  {input && (!input.control || !input.showControl) && (
                    <div className="input-title" data-testid="input-title">
                      {input?.label}
                    </div>
                  )}
                  {input?.control && input?.showControl && (
                    <span className="input-control">
                      <RefControl
                        key={key}
                        name="input-control"
                        emit={props.emit}
                        payload={input.control}
                      />
                    </span>
                  )}
                </div>
              )
          )}
        </div>
        {/* Controls */}
        {controls.map(([key, control]) => {
          return control ? (
            <RefControl
              key={key}
              name="control"
              emit={props.emit}
              payload={control}
            />
          ) : null;
        })}
        <div 
          className="wrapper" 
          style={{ right: 0 }}
        >
          {/* Outputs */}
          {outputs.map(
            ([key, output]) =>
              output && (
                <div className="output" key={key} data-testid={`output-${key}`}>
                  <div className="output-title" data-testid="output-title">
                    {output?.label}
                  </div>
                  <RefSocket
                    name="output-socket"
                    side="output"
                    emit={props.emit}
                    socketKey={key}
                    nodeId={id}
                    payload={output.socket}
                  />
                </div>
              )
          )}
        </div>
      </NodeStyles>
    </>
  );
}