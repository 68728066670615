import React from 'react'
import styled from 'styled-components';

type EmptyDivProps = {
  label: string;
}

const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--12px);

  img {
    width: var(--150px);
    aspect-ratio: 1/1;
    object-fit: contain;
  }

  p {
    width: 100%;
    font-weight: 700;
    text-align: center;
    color: #7f7fa3;
  }
`

export default function EmptyDiv({ label }: EmptyDivProps) {
  return (
    <Container className='empty-div'>
      <img src={`${process.env.PUBLIC_URL}/images/empty_div_2.png`} alt="empty div" />
      <p>{label}</p>
    </Container>
  )
}
