import React, { FormEventHandler, useContext, useState } from 'react'
import styled from 'styled-components'
import { IfileNdirList } from '../datamanage/storage/storage.type';
import { CustomThemeContext } from '../../utils/Context';
import LeftPanel from '../../components/leftPanel/LeftPanel';
import PrimaryButton from '../../components/button/PrimaryButton';
import { StorageContext } from '../../context/StorageContext';

const Container = styled(LeftPanel)<{$theme: 'light' | 'dark'}>`
  header {
    width: 100%;
    padding: var(--12px);
    border-bottom: 1px solid ${(props) => props.$theme === 'light' ? '#DCDAE8' : '#322686'};
    font-size: var(--14px);
    font-weight: 700;
    color: #424d51;
  }

  .folder-item-btn {
    display: flex;
    gap: var(--8px);
  }

  .folder-item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover .btn-delete {
      display: inline-block;
    }

    .btn-delete {
      display: none;
      width: 24px;
      height: 24px;
    }
  }

  .folder-item.selected {
    p {
      color: #BE82FF;
    }
  }
`

type Props = {
  folderList: IfileNdirList[];
  createDirectory: () => void;
  deleteDirectory: (a: string) => void;
}

export default function SmcFolderList({
  folderList,
  createDirectory,
  deleteDirectory
}: Props) {
  const { theme } = useContext(CustomThemeContext);
  const [isHovered, setIsHovered] = useState(false);
  const { handleUpload, curDir, setCurDir } = useContext(StorageContext);

  return (
    <Container className='left' $theme={theme}>
      <PrimaryButton>
        <input id='storage-upload' multiple type='file' onChange={(e) => handleUpload(e, true)} />
        <label htmlFor='storage-upload' className='btn-primary'>Data Upload</label>
      </PrimaryButton>
      <div className='folder-list-container'>
        <header>
          <p>folder list</p>
          <button className="btn-create-folder" type='button' onClick={createDirectory}>
            <img draggable='false' src={process.env.PUBLIC_URL + theme === 'dark' ? '/images/datamanage/icon-create-folder.png' : '/images/datamanage/icon-create-folder-black.png'} alt="" className='data-title-folder' />
          </button>
        </header>
        <ul className='folder-list'>
          {
            folderList.map(v => {
              const imgSrc = v?.name === curDir ? process.env.PUBLIC_URL + '/images/datamanage/data-title-folder-primary.png' : 
                theme === 'dark' ? '/images/datamanage/card/title/data-title-folder.svg' 
                  : '/images/datamanage/card/title/data-title-folder-black.svg';
              const deleteSrc = isHovered ? '/images/datamanage/icon-delete-primary.svg' 
                : theme === 'dark' ? '/images/datamanage/icon-delete-white.svg' 
                  : '/images/datamanage/icon-delete-black.svg';
              return (
                <li className={`folder-item ${v?.name === curDir ? 'selected' : ''}`}>
                  <button type="button" className={'folder-item-btn'} onClick={() => setCurDir(v?.name)}>
                    <img draggable='false' src={imgSrc} alt="" className='data-title-folder' />
                    <p>{v?.name}</p>
                  </button>
                  <button className="btn-delete" type="button" onClick={() => {deleteDirectory(v?.name)}} onMouseOver={()=>setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                    <img draggable='false' src={deleteSrc} alt="" className='data-title-folder' />
                  </button>
                </li> 
              )
            })
          } 
        </ul>
      </div>
    </Container>
  )
}
