import "./storage.styles.scss";

import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';

import { CustomThemeContext } from '../../../utils/Context';
import { SmcContext } from '../../../context/SmcContext';
import { ContextMenuContext } from '../../../context/ContextMenuContext';

import { IfileNdirList } from './storage.type';
import { StorageContext } from "../../../context/StorageContext";

const Container = styled.div`
    img {
        -webkit-user-drag: none;
    }
`

interface IStorageCard {
    info: IfileNdirList;
    keyword: string;
    index: number;
    setStep?: any;
}

export default function StorageCard({ 
    info, 
    keyword,
    index, 
    setStep = () => {},
}: IStorageCard) {
    const { name, type, thumbnail, updated_at } = info;
    const { theme } = useContext(CustomThemeContext);
    const { setSmcModal } = useContext(SmcContext);
    const { 
        curDir, 
        setCurDir, 
        selected,
        setSelected,
        setSelectedFile,
        setStartIndex,
        setEndIndex,
        setTarget,
    } = useContext(StorageContext);
    const { setContext, setContextOpen } = useContext(ContextMenuContext);

    const navigate = useNavigate();
    const location = useLocation();

    const changeCurDir = () => {
        if (name === '..') {
            let newDir = ``;
            for (let i = 0; i < curDir.split('/').length - 1; i++) {
                newDir += `${i === 0 ? '' : '/'}${curDir.split('/')[i]}`;
            }
            setCurDir(newDir);
            return;
        }
        if (curDir === '') setCurDir(name);
        else setCurDir(`${curDir}/${name}`);
    }

    const handleDoubleClick = (e) => {
        if (type === 'dir') changeCurDir();
        else { // dir 외의 경우
            if (name.includes('py')) {
                const userEmail = localStorage.getItem('email');
                navigate("/vience-canvas/mlops/code-editor", { state: {
                    workspace: {
                        model_path: `/cloud/member/${userEmail}/${curDir}/${name}`,
                        path: curDir,
                        name: name
                    }
                }});
            } else {
                if (curDir === '') { 
                    window.open(`/vience-canvas/image/${name}`, '_blank');
                } else {
                    window.open(`/vience-canvas/image/${curDir}/${name}`, '_blank');
                }
            }
        }
    }

    const doubleClickFineTuner = () => {
        if (type === 'dir') changeCurDir();
        else { // dir 외의 경우
            if (name.includes('py')) {
                const userEmail = localStorage.getItem('email');
                navigate("/vience-canvas/mlops/code-editor", { state: {
                    workspace: {
                        model_path: `/cloud/member/${userEmail}/${curDir}/${name}`,
                        path: curDir,
                        name: name
                    }
                }});
            } else {
                // dataset 설정하기
                setStep(1);
                setSelectedFile({path: curDir, name: name, thumbnail: thumbnail});
            }
        }
    }

    const doubleClickSMC = (e) => {
        if (type === 'dir') changeCurDir();
        else { // dir 외의 경우
            if (name.includes('py')) {
                const userEmail = localStorage.getItem('email');
                navigate("/vience-canvas/mlops/code-editor", { state: {
                    workspace: {
                        model_path: `/cloud/member/${userEmail}/${curDir}/${name}`,
                        path: curDir,
                        name: name
                    }
                }});
            } else {
                // 모달 띄우기
                setSmcModal(true);
                setSelectedFile({path: curDir, name: name, thumbnail: thumbnail});
            }
        }
    }

    let title;
    if (keyword) {
        const index = name.toLowerCase().indexOf(keyword.toLowerCase());
        title = (
            <strong>
                {name.slice(0, index)}
                <span className='keyword-in-title' style={{color: '#8D59FC'}}>
                    {name.slice(index, index + keyword.length)}
                </span>
                {name.slice(index + keyword.length)}
            </strong>
        )
    }

    return (
        <Container
            className={`storage-card${selected.includes(name) ? '-selected' : ''}`}
            id={name}
            draggable={true}
            onClick={(e) => {
                e.stopPropagation();
                if (e.shiftKey) {
                    setEndIndex(index);
                }
                else if (e.ctrlKey) {
                    setSelected([...selected, name])
                }
                else { 
                    setSelected([name]);
                    setSelectedFile({path: curDir, name: name, thumbnail: thumbnail});
                    setStartIndex(index); 
                    setEndIndex(index);
                    setContextOpen(false);
                }
            }}
            onDragStart={(e) => {
                setSelected([name]);
            }}
            onDragEnd={(e) => {
                setSelected([]);
            }}
            onDragOver={(e) => {
                if (type === 'dir') setTarget(name);
            }}
            onDoubleClick={(e) => {
                switch(true) {
                    case location.pathname.includes('storage'): 
                        handleDoubleClick(e);
                        break;
                    case location.pathname.includes('smc'): 
                        doubleClickSMC(e);
                        break;
                    default:
                        doubleClickFineTuner(e);
                        break;
                }
            }}
            onMouseLeave={(e) => { setTarget(undefined) }}
            onMouseDown={(e) => { e.stopPropagation(); }}
            onContextMenu={(e) => { 
                if (index === -1) return; e.preventDefault(); e.stopPropagation(); setSelected([...selected, name]); setContext(e, info) 
            }}
        >
            <div className='storage-card-title'>
                <div className="storage-card-title-left">
                    {
                        {
                            dir: <img draggable='false' src={process.env.PUBLIC_URL + theme === 'dark' ? '/images/datamanage/card/title/data-title-folder.svg' : '/images/datamanage/card/title/data-title-folder-black.svg'} alt="" className='data-title-folder' />,
                            dataset: <img draggable='false' src={process.env.PUBLIC_URL + theme === 'dark' ? '/images/datamanage/card/title/data-title-folder.svg' : '/images/datamanage/card/title/data-title-folder-black.svg'} alt="" className='data-title-folder' />,
                            model: <img draggable='false' src={process.env.PUBLIC_URL + theme === 'dark' ? '/images/datamanage/card/title/data-title-folder.svg' : '/images/datamanage/card/title/data-title-folder-black.svg'} alt="" className='data-title-folder' />,
                            image: <img draggable='false' src={process.env.PUBLIC_URL + theme === 'dark' ? '/images/datamanage/card/title/data-title-img.svg' : '/images/datamanage/card/title/data-title-img-black.svg'} alt="" className='data-title-img' />,
                            weight: <img draggable='false' src={process.env.PUBLIC_URL + theme === 'dark' ? '/images/datamanage/card/title/data-title-file.svg' : '/images/datamanage/card/title/data-title-file-black.svg'} alt="" className='data-title-file' />,
                            file: <img draggable='false' src={process.env.PUBLIC_URL + theme === 'dark' ? '/images/datamanage/card/title/data-title-file.svg' : '/images/datamanage/card/title/data-title-file-black.svg'} alt="" className='data-title-file' />,
                        }[type]
                    }

                    <p>{keyword ? title : name}</p>
                </div>
                <div className="storage-card-title-right">{updated_at.slice(0, 10)}</div>
            </div>
            <div className='storage-card-icon-frame'>
                {
                    {
                        dir: thumbnail === 'empty' ? <img draggable='false' src={process.env.PUBLIC_URL + '/images/datamanage/card/thumbnail/data-folder.svg'} alt="" className='data-type-folder' /> : <img draggable='false' src={process.env.PUBLIC_URL + '/images/datamanage/card/thumbnail/data-folder-file.svg'} alt="" className='data-type-folder-file' />,
                        dataset: <img draggable='false' src={process.env.PUBLIC_URL + '/images/datamanage/card/thumbnail/data-folder-file.svg'} alt="" className='data-type-folder-file' />,
                        model: <img draggable='false' src={process.env.PUBLIC_URL + '/images/datamanage/card/thumbnail/data-folder-file.svg'} alt="" className='data-type-folder-file' />,
                        image: <img draggable='false' src={`data:image/jpeg;base64,${thumbnail}`} alt="" className='data-type-img' />,
                        weight: <img draggable='false' src={process.env.PUBLIC_URL + '/images/datamanage/card/thumbnail/data-file.svg'} alt="" className='data-type-file' />,
                        file: <img draggable='false' src={process.env.PUBLIC_URL + '/images/datamanage/card/thumbnail/data-file.svg'} alt="" className='data-type-file' />,
                    }[type]
                }
                <p className="storage-card-name">{name}</p>
            </div>
        </Container>
    )
}
