import React from 'react';
import styled from 'styled-components';
import TrainingAnimation from '../train-control/TrainingAnimation';

const Container = styled.button<{$finished: boolean}>`
  flex-shrink: 0;
  position: relative;

  width: var(--40px);
  height: var(--40px);
  border-radius: var(--4px);
  background-color: #c00d0d;

  font-size: var(--10px);

  &:hover {
    background-color: #d06363;
  }

  &:disabled {
    color: #ffffff99;
    background-color: #313131;
    border: 1px solid #313131;
  }

  ${props => props.$finished ? `
    background-color: #c4d4d2 !important;
    border: 0 !important;
    color: #565656 !important;
  `: `
  `}

  div.timer-wrapper {
    transform: translateX(100%) translateX(var(--8px));
  }
`

export default function StopTrainBtn({
  handleStopBtn,
  resultData,
}) {
  if (!resultData || !resultData.status) return null;
  return (
    <Container 
      className='stop-train-session-btn'
      onClick={handleStopBtn}
      disabled={resultData?.status.stop_signal || resultData?.status.progress === 100}
      $finished={!resultData?.status.stop_signal && resultData?.status.progress === 100}
    >
      {
        resultData?.status.stop_signal ? `stopped` :
        resultData?.status.progress === 100 ? `finished` :
        <TrainingAnimation />
      }
    </Container>
  )
}
