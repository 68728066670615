import styled from 'styled-components';

const LeftPanel = styled.div`
  overflow: auto;
  width: 21.875rem;
  height: calc( 100vh - 4.5rem ); 
  background: #080821;

  ul {
    display: flex;
    flex-direction: column;
    li {
      &:first-child {
        margin-top: var(--20px);
      }
      &:hover {
        background-color: #8D59FC44;
      }
      width: 100%;
      padding: var(--12px);
      font-size: var(--16px);
      font-weight: 700;
      &.selected {
        color: #8D59FC;
      }
    }
  }

  input {
    display: none;
  }
`

export default LeftPanel;