import React,{ useState } from 'react';
import { MouseEvent } from 'react';
import './landingSearch.scss';

export const LandingSearch:React.FC = () => {
    const [show, setShow] = useState(false);

    const handleClick = (event:MouseEvent) => {

        if(show === true) {
            setShow(false);
        } else {
            setShow(true);
        }
        
    }
    return (
        <>
            <div className="search-frame">
                <div className="inpput-frame"> 
                    <button>
                        <img src={process.env.PUBLIC_URL + '/images/ico-search.svg'} alt="" className=""/>
                    </button>
                    <input type="text" placeholder='Search...'/>
                </div>
                <div className="filter-frame">
                    <button type="button" onClick={handleClick}>
                        <img src={process.env.PUBLIC_URL + '/images/ico-filter.svg'} alt="" className=""/>
                        <span>filter</span>
                    </button>
                    {
                        show &&
                        (<div className={`filter-content-frame`}>
                            <ul>
                                <li>
                                    <span>카테고리</span>
                                </li>
                                <li>
                                    <span>날짜</span>
                                </li>
                                <li>
                                    <span>논문종류</span>
                                </li>
                            </ul>
                        </div>)
                    }

                </div>

            </div>
        </>
    )
}
