import React, { useEffect, useState } from 'react'
import OptionInput from '../PanelOptionComponent/OptionInput';

export default function ReluActivationOptionSection({ nodeId, initOption, option, setOption, loading, setLoading }) {
  const [nodeName, setNodeName] = useState<string>('');
  
  // 각 input에 초기값 세팅되기 전까지 로딩상태여야함
  useEffect(() => {
    setLoading(true);
    setNodeName('');
  }, [nodeId])

  useEffect(() => {
    if (!loading) {
      setOption({
        ...option, 
        name: nodeName,
      })
    }
  }, [
    nodeName,
  ]);

  return (
    <form name='convolution2dOption'>
      <OptionInput initOption={initOption} setValue={setNodeName} id={"name"} label='Name of Node' setLoading={setLoading} type='text'/>
    </form>
  )
}
