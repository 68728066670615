import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from 'react'
import styled from 'styled-components';

import CustomSelect from '../../PanelOptionComponent/CustomSelect'

import { TrainContext } from '../../../../../context/TrainContext';

import { ILayerOption } from '../../../flow';
import { getSelectedKey } from '../../../../../context/trainUtils';
import { useLocation } from 'react-router-dom';
import { WorkspaceContext } from '../../../../../context/WorkspaceContext';

const Container = styled.div`
  margin-top: var(--24px);

  select {
    margin-top: 0;
  }

  div.train-option {
    margin-top: var(--12px);
    padding: var(--4px);
  }
`;

type TrainOptionSelectProps = {
  initOption: {current: ILayerOption};
  selectList: {};
  setSelectList: Dispatch<SetStateAction<{}>>;
  loading: boolean; 
  setLoading: Dispatch<SetStateAction<boolean>>;
}

export default function TrainOptionSelect({ 
  initOption, 
  setSelectList, 
  loading, 
  setLoading 
}: TrainOptionSelectProps ) {
  const [selectedDataset, setSelectedDataset] = useState('');
  const [selectedEpoch, setSelectedEpoch] = useState('');
  const [selectedLearningRate, setSelectedLearningRate] = useState('');
  const [selectedOptimizer, setSelectedOptimizer] = useState('');
  const [trainer_type, setSelectedTrainerType] = useState('');

  const { sharedDataset } = useContext(TrainContext);
  const { selectedWorkspace } = useContext(WorkspaceContext);
  const { pathname } = useLocation();
  const isSample = pathname.includes('sample');

  useEffect(() => {
    if (!loading) {
      setSelectList((prev) => {
        return {
          ...prev,
          selectedDataset,
          selectedEpoch,
          selectedLearningRate,
          selectedOptimizer,
          trainer_type,
        }
      })
    }
  }, [
    selectedDataset, 
    selectedEpoch, selectedLearningRate,
    selectedOptimizer, trainer_type,
  ])

  // shared dataset 받아오기

  const initialSetting = useCallback(async () => {
    // selectedModel, selectedDatasetList 있는지 확인
    const selectedDatasetEl = document.querySelector('select#selectedDataset');
    const trainer_typeEl = document.querySelector('select#trainer_type');
    const epoch_typeEl = document.querySelector('select#selectedEpoch');
    const learningrate_typeEl = document.querySelector('select#selectedLearningRate');

    if (selectedWorkspace && !initOption.current.selectedDataset) { // 선택된 dataset이 없고
      let newSelectedDataset;
      if (isSample) {
        newSelectedDataset = 'denoising_dataset';
      } else {
        newSelectedDataset = getSelectedKey(
          selectedWorkspace.dataset_path, 
          sharedDataset
        )
      }
      
      setSelectedDataset(newSelectedDataset);
      selectedDatasetEl.value = newSelectedDataset;
    }

    if (selectedWorkspace && !initOption.current.trainer_type) {
      setSelectedTrainerType(selectedWorkspace.trainer_type);
      trainer_typeEl.value = selectedWorkspace.trainer_type;
    }

    if (selectedWorkspace && !initOption.current.selectedEpoch) {
      setSelectedEpoch(selectedWorkspace.epoch);
      epoch_typeEl.value = selectedWorkspace.epoch;
    }

    if (selectedWorkspace && !initOption.current.selectedLearningRate) {
      setSelectedLearningRate(selectedWorkspace.learning_rate);
      learningrate_typeEl.value = selectedWorkspace.learning_rate;
    }

  }, []);
  
  useEffect(() => {
    initialSetting();
  }, [initOption.current.nodeId])

  return (
    <Container>
      <div>
        <h5 className='a11y-hidden'>train main options</h5>
        <CustomSelect
          id={'trainer_type'}
          label={'Trainer Type'}
          options={[
            "segmentation(rgb2probability)", 
            "regression(rgb2value)",
            "denoising(grey2grey)", 
            "super_resolution(grey2grey)",
            "autoencoder(rgb2rgb)"
          ]}
          keyword={''}
          setValue={setSelectedTrainerType}
          initOption={initOption} 
          setLoading={setLoading}
        />
        <CustomSelect
          id={'selectedDataset'}
          label={'Dataset'}
          options={isSample ? ['denoising_dataset', 'superresolution_dataset', 'steatosis_progress_dataset', 'steatosis_dataset', 'inflammation_dataset', 'cell_segmentation_dataset'] : sharedDataset?.key ?? []} 
          keyword={''} 
          setValue={setSelectedDataset} 
          initOption={initOption} 
          setLoading={setLoading}
        />
      </div>
      <div className='train-option'>
        <h5 className='a11y-hidden'>train detail options</h5>
        <CustomSelect
          id={'selectedEpoch'}
          label={'Epoch'}
          options={['10', '20', '30', '40', '50', '60', '70', '80', '90', '100','150','200']} 
          keyword={''} setValue={setSelectedEpoch} 
          initOption={initOption} 
          flexDirection='row'
          setLoading={setLoading}
        />
        <CustomSelect
          id={'selectedLearningRate'}
          label={'LearningRate'}
          options={['0.0001', '0.001', '0.01', '0.1']} 
          keyword={''} 
          setValue={setSelectedLearningRate} 
          initOption={initOption} 
          flexDirection='row'
          setLoading={setLoading}
        />
        <CustomSelect
          disabled={true}
          id={'selectedOptimizer'} label={'optimizer'}
          options={[ 'AdamW', 'Adam', 'Adagrad', 'RMSprop', 'Stochastic Gradient Descent(SGD)']} 
          keyword={''} 
          setValue={setSelectedOptimizer} 
          initOption={initOption} 
          flexDirection='row'
          setLoading={setLoading}
        />
      </div>
    </Container>
  )
}
