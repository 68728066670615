import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import { useAPIwithCookies } from "./useApiCookies";

export const useLogin = () => {
  const [error, setError] = useState('');
  const [isPending, setIsPending] = useState(false);
  
  const { dispatch } = useAuthContext();
  const api = useAPIwithCookies();

  const login = async (formData) => {
      setIsPending(true);

      try {
        const response = await api.post('/member/login', formData, {
          headers: {
            'Content-Type': 'multipart/form-data' 
          }
        });

        const user = {
          email: response.data.email, 
          access: response.data.access_token
        };

        localStorage.setItem('access', user.access);
        localStorage.setItem('email', user.email);

        dispatch({ type: 'login', payload: user});
        setIsPending(false);

        if (!user) {
          throw new Error("회원정보를 받아올 수 없습니다.")
        }
        
      } catch (errormsg) {
        setError(JSON.stringify(errormsg));
        setIsPending(false);
      }
  }
  return { error, isPending, login }
}