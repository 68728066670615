import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import TrainResultGraphList from './TrainResultGraphList';
import TrainResultImage from './TrainResultImage';

import { TrainContext } from '../../../../../../context/TrainContext';
import { CustomThemeContext } from '../../../../../../utils/Context';
import ImageItemModal from './ImageItemModal';

const TrainResultBoardDiv = styled.div<{
  $isDetail: boolean;
  $theme: string;
  }>`
  flex-shrink: 0;
  padding: var(--12px);
  
  ${(props) => props.$theme === 'light' ? `
    border: 2px solid #D9D9E5;
    ` : `
    border: 2px solid #1B1B47;
    `};

  ${(props) => props.$isDetail ? `
    display: grid;
    gap: var(--12px);
    overflow: auto;
    grid-template-columns: 2fr 1fr;
  `: `
    width: 100%;
  `}
`
export default function TrainResultBoard() {
  const { pathname } = useLocation();
  const isDetail = pathname.includes('detail');
  const { openDetail, setOpenDetail } = useContext(TrainContext);
  const { theme } = useContext(CustomThemeContext);

  useEffect(() => {
    return () => {
      setOpenDetail(null);
    }
  }, []);

  return (
    <TrainResultBoardDiv 
      $theme={theme}
      $isDetail={isDetail}
      className='train-result' 
    >
      <h4 className='a11y-hidden'>훈련결과 Graph & Image</h4>
      <TrainResultGraphList />
      { isDetail && <TrainResultImage /> }
      { openDetail && <ImageItemModal /> }
    </TrainResultBoardDiv>
  )
}
