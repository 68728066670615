import React, { useState } from 'react'

import CanvasHeader from '../../../components/canvasHeader/CanvasHeader';
import WorkspaceMenu from './WorkspaceMenu';
import WorkspaceCards from './WorkspaceCards';
import { ShareModal } from '../../../rete/workspace-panel.component';
import TemplateModal from './template/TemplateModal';

import { WorkspaceList } from '../../../context/WorkspaceContext';

export default function MlopsWorkspace() {
  const [shareOpen, setShareOpen] = useState<boolean>(false);
  const [templateModal, setTemplateModal] = useState<{} | null>();
  const [workspaceList, setWorkspaceList] = useState<WorkspaceList[]>([]);
  
  return (
    <>
      <div 
        className='workspace-container' 
        onContextMenu={(e) => { e.preventDefault(); }}
      >
        <CanvasHeader />
        <div className="main-content">
          <WorkspaceMenu 
            setWorkspaceList={setWorkspaceList}
          />
          <WorkspaceCards 
            setShareOpen={setShareOpen} 
            setTemplateModalOpen={setTemplateModal}
            workspaceList={workspaceList}
            setWorkspaceList={setWorkspaceList}
          />
        </div>
  
        <ShareModal 
          open={shareOpen} 
          onClose={() => setShareOpen(false)}
          wor_id='' 
        />
      </div >
      { templateModal 
      && <TemplateModal 
        workspace={templateModal} 
        setTemplateModalOpen={setTemplateModal}
      />}
    </>
  )
}
