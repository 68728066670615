import React from 'react'

import CustomModal from '../../common/CustomModal';


export default function PrivacyPolicy() {

    return (
        <div className='login'>
            <CustomModal open={true} onClose={() => {}} bgShown='dark' bgColor='#010116'>
            <h1 className="modalTitle" style={{ textAlign: 'center' }}>Privacy Policy</h1>
                <div className="modalContent">
                    바이언스(이하 ‘당사’)가 취급하는 모든 개인정보는 개인정보 보호법 등 관련 법령상의 개인정보보호 규정을 준수하여 수집·보유·처리되고 있습니다.
                    <br /><br />
                    <strong>제1조. 개인정보의 처리목적</strong><br />

                    당사는 서비스 제공 목적으로 필요에 의한 최소한으로 개인정보를 수집하고 있습니다.

                    <br /><br />

                    <strong>제2조. 개인정보의 처리 및 보유 기간</strong><br />
                    
                    - 처리하는 개인정보 항목: 이메일, 이름, 닉네임, 소속, 직업, 당사 서비스 활용 목적<br />
                    - 보유기간: 회원 탈퇴 시까지
                    
                    <br /><br />

                    <strong>제3조. 개인정보 제3자 제공에 관한 사항</strong><br />

                    당사는 원칙적으로 정보주체의 개인정보를 수집·이용 목적으로 명시한 범위 내에서 처리하며, 정보주체의 사전 동의 없이는 제3자에게 제공하지 않습니다.<br />
                </div>
            </CustomModal>

        </div>
    )
}