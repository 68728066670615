import { useNavigate } from "react-router-dom";
// import "./../routes.styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { faCloud } from "@fortawesome/free-solid-svg-icons";
import { Modal, Button, ButtonToolbar, Placeholder, IconButton } from 'rsuite';
import { useEffect, useState } from "react";
import CheckOutline from '@rsuite/icons/CheckOutline';

const Main = () => {

  return (
    <div>
      <h1 id="main-title" style={{display:"none"}}>
        바이오메디컬영상 AI분석 플랫폼 전문기업, 주식회사 바이언스 (VIENCE)
      </h1>
      <h2 id="keyword" style={{display:"none"}}>
        VIENCE, 바이언스, 의료영상분석, AI분석 플랫폼, MLOps, XROps, CellView, PathoView
      </h2>

      <iframe type="text/html" src="https://vience.io:3305/" style={{width: "99.7%", height: "99vh", background: "white"}}>
      </iframe>
    </div>
  );
};

export default Main;
