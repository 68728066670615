import React, { useContext } from 'react'
import './mode.scss'
import { CustomThemeContext } from '../../utils/Context';

export default function Mode() {
    const { theme, toggleTheme } = useContext(CustomThemeContext);

    return (
        <button
            id="lightMode"
            onClick={toggleTheme}
        >
            { theme === 'dark'
                ? ( <div>
                    <img src={process.env.PUBLIC_URL + '/images/ico-light.svg'} alt="Light Mode" className="icoLight" />
                    <span>LightMode</span>
                </div> )
                : ( <div>
                    <img src={process.env.PUBLIC_URL + '/images/ico-dark.svg'} alt="dark Mode" className="icoDark" />
                    <span>DarkMode</span>
                </div> ) 
            }
        </button>
    )
}
