import React, { Dispatch, SetStateAction, useState } from 'react';

import CodeEditorHeader from './CodeEditorHeader';
import Playground from './Playground';
import CodeCheckButton from './CodeCheckButton';
import Feedback from './Feedback';

import { useAPIwithCookies } from '../../../hooks/useApiCookies';

type CodeEditorProps = {
  code: string;
  setCode: Dispatch<SetStateAction<string>>;
  modelPath: string;
  setModelPath: Dispatch<SetStateAction<string>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

const MemoizedCodeEditorHeader = React.memo(CodeEditorHeader, headerPropsEquals);

function headerPropsEquals(prev, next) {
  return (prev.code === next.code && prev.modelPath === next.modelPath);
}

export default function CodeEditor({
  code, setCode, modelPath, setModelPath, setLoading
}: CodeEditorProps) {

  const api = useAPIwithCookies();

  const [feedback, setFeedback] = useState('');
  const [errorChecking, setErrorChecking] = useState(false);

  const errorCheck = async () => {
    setErrorChecking(true);
    const { data } = await api.post(`/mlops/code_error_check`, {"code": code});
    setFeedback(data);
    setErrorChecking(false);
  }

  return (
    <div style={{position: "relative", display: "flex", flexDirection: "column", width: '100%'}}>
      <MemoizedCodeEditorHeader code={code} modelPath={modelPath} setModelPath={setModelPath} setLoading={setLoading} />
      {feedback.length ? <Feedback feedback={feedback} setFeedback={setFeedback}/> : <></>}
      <CodeCheckButton errorChecking={errorChecking} errorCheck={errorCheck}/>
      <Playground code={code} setCode={setCode}/>
    </div> 
  )
}
