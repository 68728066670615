import React from 'react'

export default function MlopsControlBtnCategoryTitle({
  category="", 
  desc="", 
  step="",
}) {
  return (
    <div className="panel-tool-title">
      <div className="top">
        <strong>{category}</strong>
        <span>STEP {step}</span>
      </div>
      <p>{desc}</p>
    </div>
  )
}
