import { useState } from "react";
import { useAuthContext } from "./useAuthContext";

export const useLogout = () => {
  const [error, setError] = useState('');
  const [isPending, setIsPending] = useState(false);

  const { dispatch } = useAuthContext();

  const logout = () => {
      setIsPending(true);
      try {
        localStorage.removeItem('access');
        localStorage.removeItem('email'); 
        dispatch({ type: 'logout' });
        setIsPending(false);
      } catch (errormsg) {
        setError(JSON.stringify(errormsg))
        setIsPending(false);
        console.error(errormsg);
      }
  }
  
  return { error, isPending, logout }
}