import axios, { AxiosError, AxiosResponse } from "axios";

export default class API {
    access: string | null;
    refresh: string;

    constructor(cookies: any) {
        this.access = localStorage.getItem('access');
        this.refresh = cookies.refresh;
    }

    async default(path: string, getResponse: (url: string, auth: string) => Promise<AxiosResponse<any, any>>) {
        const url = process.env.REACT_APP_VIENCE_API_KEY + path;
        let Auth: string = this.access ? `Bearer ${this.access}`: `Bearer ${process.env.REACT_APP_NO_AUTH_TOKEN}`;

        try {

            const response = await getResponse(url, Auth);
            return response;

        } catch (error) {

            const err = error as AxiosError;
            if (err.response) {
                const errorData = err.response.data as any;

                // token 만료 (401)시 cookie에 저장된 refresh token으로 새 access token 발급
                if (err.response.status == 401 && errorData.detail == 'Expired token') {
                    try {
                        const response_token = await axios.post(process.env.REACT_APP_VIENCE_API_KEY + '/member/regenerate_token', { token: this.refresh });
                        localStorage.setItem('access', response_token.data.detail.access_token);
                        Auth = `Bearer ${response_token.data.detail.access_token}`;
                        const response_new = await getResponse(url, Auth);
                        return response_new;
                    }
                    catch (error) {
                        // refresh token 만료 or 유효하지 않은 경우
                        if (localStorage.getItem('access')) {
                            localStorage.removeItem('access');
                            localStorage.removeItem('email');
                            //쿠키 삭제
                            alert('로그인이 만료되었습니다.');
                            window.location.href = '/login';
                        }
                        throw error;
                    }
                }

                else {
                    throw errorData;
                }

            }
            else {
                throw { detail: 'Internal Server Error' };
            }
        }
        
    }

    async get(path: string, params?: object, header?: object): Promise<any> {
        const getResponse = async (url: string, auth: string) => {
            const response = await axios.get(url, {
                params: params,
                headers: {
                    Authorization: auth,
                    ...header
                }
            })
            return response;
        }
        return await this.default(path, getResponse)
    }

    async post(path: string, data?: object, header?: object): Promise<any> {
        const getResponse = async (url: string, auth: string) => {
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: auth,
                    ...header
                }
            })
            return response;
        }
        return await this.default(path, getResponse)
    }

    async put(path: string, data?: object, header?: object): Promise<any> {
        const getResponse = async (url: string, auth: string) => {
            const response = await axios.put(url, data, {
                headers: {
                    Authorization: auth,
                    ...header
                },
            })
            return response;
        }
        return await this.default(path, getResponse)
    }

    async delete(path: string, params?: object, header?: object): Promise<any> {
        const getResponse = async (url: string, auth: string) => {
            const response = await axios.delete(url, {
                params: params,
                headers: {
                    Authorization: auth,
                    ...header
                },
            })
            return response;
        }
        return await this.default(path, getResponse)
    }
}
