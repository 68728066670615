import React from 'react';
import './CustomComp.scss';

interface CustomTextareaProps extends React.HTMLProps<HTMLTextAreaElement> {
    label?: string;
    onChange: (e: any) => void;
}

export default function CustomTextarea({ onChange, children, label, ...rest }: CustomTextareaProps) {

    return (
        <>
            {label && <p>{label}</p>}
            <textarea className='custom-textarea-input' onChange={(e) => { onChange(e.target.value as string) }} {...rest} />
        </>
    )
}
