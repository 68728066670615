import React from "react";
import { Progress } from "rsuite";
import styled from "styled-components";

const ProgressCircleContainer = styled.div`
  &.circle-bg {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: #00000055;
    z-index: 1000;

    display: flex;
    align-items: center;
    justify-content: center;

    .progress-circle-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        font-weight: 700;
        display: inline-block;
        min-height: var(--30px);
        color: white;
      }
      div {
        width: var(--150px);
        height: var(--150px);
        span {
          color: white;
        }
      }
    }
  }
`
export default function ProgressCircleDiv({label, percentage} : {
  label: string;
  percentage: number;
}) {
  return (
    <ProgressCircleContainer className='circle-bg'>
      <div className='progress-circle-container'>
        <p>{label}</p>
        <Progress.Circle 
          percent={percentage} 
          strokeColor="#8D59FC" 
        />
      </div>
    </ProgressCircleContainer>
  )
}