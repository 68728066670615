// 유효성 검사 - 1 이상의 정수인지 
export const isInt = (num) => {
  if (num < 1) return false;
  if (!Number.isInteger(num)) return false;
  else return true; 
};
  
// 유효성 검사 - 음수 제한
export const isPositive = (num) => {
  if (num < 0) return false;
  else return true;
};

// 유효성 검사 - 0이상의 정수
export const isPositiveInt = (num) => {
  if (num < 0 || !Number.isInteger(num)) return false;
  else return true;
};

// 현재 시각으로 string 만들어주는
export const getTimeString = () => {
  const date = new Date();
  const timeString = [
    date.getMonth() + 1,
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds()
  ].map(time=>time.toString().padStart(2,"0")).join('');

  return timeString;
}

// 
export const getRandomHexa = () => {
  const randomNumber = Math.trunc(Math.random() * 16581375);
  const randomHexa = randomNumber.toString(16);
  return randomHexa;
}