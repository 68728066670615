import React from 'react';
import { IconButton } from 'rsuite';
import CloseIcon from '@rsuite/icons/Close';

export default function AnnotationList({
  shapeList, setShapeList, category
}) {
  const findMinMax = (points) => {
    const xValues = points.map(p => p.x);
    const yValues = points.map(p => p.y);

    const minX = Math.min(...xValues).toFixed(1);
    const maxX = Math.max(...xValues).toFixed(1);
    const minY = Math.min(...yValues).toFixed(1);
    const maxY = Math.max(...yValues).toFixed(1);
  
    return { minX, maxX, minY, maxY };
  }

  const handleDeleteAnnotation = (listId, category) => {
    setShapeList(prev => ({
      ...prev,
      [category]: shapeList[category].filter(v => v[0] !== listId)
    }));
  };
  
  const annotatorListItem : React.ReactElement[] = shapeList[category].map((v) => {
    const bound = findMinMax(v);
    const listId = v[0];
    
    return (
      <li key={listId.x}>
        <p className={`list-item ${category}`}>
          ({bound.minX},{bound.minY}) ~ ({bound.maxX},{bound.maxY})
        </p>
        <DeleteBtn fn={(e) => {handleDeleteAnnotation(listId, category)}}/>
      </li>
    );
  }).reverse();
  
  return (
    <ul>
      {annotatorListItem}
    </ul>
  )
}


function DeleteBtn({fn}) {
  return (
    <div className="btn-delete">
      <IconButton onClick={fn} 
        className="button-1" 
        icon={<CloseIcon />} 
        color="blue" 
        appearance="ghost" 
        size="xs" 
        style={{ background: '#2356ff00'}}
      />
    </div>
  )
}
