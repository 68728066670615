export const categories = [
  {
    name:'Source',
    desc: "data source input",
    step: "01",
  },{
    name:'Layer',
    desc: "network layer",
    step: "02",
  },{
    name:'Resize',
    desc: "network layer",
    step: "02",
  },{
    name:'Sink',
    desc: "trainer",
    step: "03",
  },{
    name:'AddOn',
    desc: "if you want",
    step: "04",
  },
];

type ButtonData = {
  label: string;
  category: string;
  iconSource: string;
}

export const btnData: ButtonData[] = [
  {
    label:"Dataset",
    category: "Source",
    iconSource: "datamanage",
  },  {
    label:"Convolution 2D",
    category: "Layer",
    iconSource: "denoising",

  },  {
    label:"MaxPool 2D",
    category: "Resize",
    iconSource: "denoising",

  },  {
    label:"BatchNorm 2D",
    category: "Layer",
    iconSource: "denoising",

  },  {
    label:"Relu Activation",
    category: "Layer",
    iconSource: "denoising",

  },  {
    label:"ConvTranspose 2D",
    category: "Resize",
    iconSource: "denoising",

  }, {
    label:"Trainer",
    category: "Sink",
    iconSource: "viewer", 
  }, {
    label:"Cat",
    category: "AddOn",
    iconSource: "feature",
  }, {
    label:"Fully Connected",
    category: "Layer",
    iconSource: "denoising",
  }, {
    label:"FineTuner",
    category: "Sink",
    iconSource: "viewer", 
  }, {
    label:"FeatureVisualizer",
    category: "Sink",
    iconSource: "viewer", 
  }
]
