import React from 'react'
import './lineGrid.scss';

export const LineGrid:React.FC = () => {
  return (
    <>
        <div className="line-grid">
            <div className="frame">
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
            </div>
        </div>
    </>
  )
}
