/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

interface Props {
  className: any;
}

export const ResetView1 = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={`reset-view-1 ${className}`}
      fill="none"
      height="44"
      viewBox="0 0 44 44"
      width="44"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_368_439)">
        <path className="path" d="M1 10.6002V1H10.3333" stroke="#65646A" />
        <path className="path" d="M1 33.3998V43H10.3333" stroke="#65646A" />
        <path className="path" d="M33.6667 1L43 1L43 10.6002" stroke="#65646A" />
        <path className="path" d="M33.6667 43L43 43L43 33.3998" stroke="#65646A" />
        <rect className="rect" height="9" rx="1.5" stroke="#65646A" width="3" x="23" y="17.6992" />
        <rect className="rect" height="9" rx="1.5" stroke="#65646A" width="3" x="17.5" y="17.6992" />
        <path className="path" d="M15 22.1992H17" stroke="#65646A" />
        <path className="path" d="M20.5 22.1992H22.5" stroke="#65646A" />
        <path className="path" d="M26.5 22.1992H28.5" stroke="#65646A" />
        <rect className="rect" height="19" rx="1.5" stroke="#65646A" width="3" x="11.5" y="12.6992" />
        <rect className="rect" height="19" rx="1.5" stroke="#65646A" width="3" x="29" y="12.6992" />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_368_439">
          <rect className="rect" fill="white" height="44" width="44" />
        </clipPath>
      </defs>
    </svg>
  );
};
