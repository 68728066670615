import React, { useEffect, useState } from "react";
import { ClassicPreset, NodeId } from "rete";
import { CustomSocket } from "../../sockets";
import { IDataflow } from "../flow";
import { IImageFilter } from "../../filter";
import { EffectorColor } from "../../style/CustomNode";
import CustomControl from "../../style/CustomControl";
import { updateControl } from "../../../reteUtils";
import { NodeSize } from "../../style/CustomNode";
import { useCookies } from "react-cookie";
import API from "../../../../utils/api";
import './Blender.scss';

export class BlenderNode extends ClassicPreset.Node<
    { in1: ClassicPreset.Socket, in2: ClassicPreset.Socket },
    { out: ClassicPreset.Socket },
    { ctrl: BlenderControl }
> {
    color = EffectorColor;
    width = NodeSize.width;
    height = NodeSize.height;

    constructor(process: () => void, nodeId?: NodeId) {

        super("Blender");
        this.addInput('in1', new ClassicPreset.Input(new CustomSocket()));
        this.addInput('in2', new ClassicPreset.Input(new CustomSocket()));
        this.addOutput('out', new ClassicPreset.Input(new CustomSocket()));
        this.addControl('ctrl', new BlenderControl(process, nodeId ? nodeId : this.id));

    }
    data(inputs: { in1: IDataflow[], in2: IDataflow[] }): { out: IDataflow } {
        const input1 = inputs['in1'][0];
        const input2 = inputs['in2'][0];
        const path1 = input1.img_paths[Object.keys(input1.img_paths)[0]];
        const path2 = input2.img_paths[Object.keys(input2.img_paths)[0]];

        console.log(path1, path2)

        if (this.controls.ctrl.props.option.blender && path1 && path2) {
            if (this.controls.ctrl.props.option.blender.path1 !== path1 || this.controls.ctrl.props.option.blender.path2 !== path2) {
                this.controls.ctrl.setValue({ blender: { path1: path1, ratio: 0.5, path2: path2 } })
            }
        }

        return {
            out: {
                img_paths: {
                    ...input1.img_paths,
                    ...input2.img_paths
                },
                effector: {
                    input: [input1.effector, input2.effector],
                    node: {
                        blender: this.controls.ctrl.props.option.blender
                    }
                }
            }
        }
    }
}

export class BlenderControl extends ClassicPreset.Control {
    props: {
        option: IImageFilter;
    }
    contextOpen: boolean;

    constructor(public onChange: () => void, public nodeId: NodeId) {
        super();
        this.props = {
            option: {
                blender: {
                    ratio: 0.5,
                    path1: '',
                    path2: ''
                },

            }
        }
        this.contextOpen = false;
    }

    setValue(val: IImageFilter) {
        this.props.option = val;
        this.onChange();
    }

    setContextOpen = (open: boolean) => {
        this.contextOpen = open;
        updateControl(this.id);
    }
}

export function BlenderComp({ data }: { data: BlenderControl }) {
    return (
        <CustomControl contextOpen={data.contextOpen} setContextOpen={data.setContextOpen} label="Blender" nodeId={data.nodeId} markerSource={'node-effector'} iconSource="blender" />
    )
}

export function BlenderPanel({ ctrl }: { ctrl: BlenderControl }) {
    const [ratio, setRatio] = useState<number>(0.2);
    const [cookies, setCookie, removeCookie] = useCookies(['refresh']);
    const api = new API(cookies);
    const [loading, setLoading] = useState<boolean>(false);
    const [baseImageInfo, setBaseImageInfo] = useState<{ height: number, width: number, ratio: number }>({ ratio: 1, width: 0, height: 0 });
    const [baseImageUrl, setBaseImageUrl] = useState<string>();
    const [overImageInfo, setOverImageInfo] = useState<{ height: number, width: number, ratio: number }>({ ratio: 1, width: 0, height: 0 });
    const [overImageUrl, setOverImageUrl] = useState<string>();
    const [baseImageSize, setBaseImageSize] = useState<number>();
    const [overImageSize, setOverImageSize] = useState<number>();

    // Node에 저장되어 있는 blender Option 불러오기
    const loadData = async () => {
        if (ctrl.props.option.blender) {
            setRatio(ctrl.props.option.blender?.ratio);
            getImage(ctrl.props.option.blender?.path1, 0);
            getImage(ctrl.props.option.blender?.path2, 1);
        }
    }

    // base이미지와 over이미지의 thumbnail 가져오기
    const getImage = async (imagePath: string, index: number) => {
        setLoading(true);
        const length = imagePath.split('/').length;
        let path = '';
        let file = imagePath;
        if (length !== 1) {
            file = ctrl.props.option.path?.split('/')[length - 1];
            for (let i = 0; i < length - 1; i++) {
                path += `/${ctrl.props.option.path?.split('/')[i]}`;
            }
        }
        const response = await api.post('/cloud/image', { cur_dir: path, names: [file] });
        const ratio = response.data.height / response.data.width;
        if (index === 0) {
            setBaseImageInfo({ height: response.data.height, width: response.data.width, ratio: ratio });
            setBaseImageUrl(response.data.base64);
            if (ratio >= 1) setBaseImageSize(450 / ratio);
            else setBaseImageSize(300 * ratio);
        }
        else {
            setOverImageInfo({ height: response.data.height, width: response.data.width, ratio: ratio });
            setOverImageUrl(response.data.base64);
            if (ratio >= 1) setOverImageSize(450 / ratio);
            else setOverImageSize(300 * ratio);
        }
        setLoading(false);
    }

    useEffect(() => {
        loadData();
    }, [ctrl.id])


    useEffect(() => {
        if (ctrl.props.option.blender) {
            ctrl.setValue({ blender: { ...ctrl.props.option.blender, ratio: ratio } });
        }
    }, [ratio])

    return (
        <>
            <div className='side-panel-normal'>
                <p>Blender</p>
                <div className="blender-section">
                    <p>Base Image</p>
                    {/* <img src={process.env.PUBLIC_URL + '/images/crop-test-img.png'} alt="" /> */}
                    <img className="blender-image" src={`data:image/jpeg;base64,${baseImageUrl}`} width={baseImageInfo.ratio >= 1 ? baseImageSize : 300} height={baseImageInfo?.ratio >= 1 ? 450 : baseImageSize} />
                </div>
                <div className="blender-handle">
                    <p>{(1 - ratio).toFixed(1)}</p>
                    <input id="blender-handle" type="range" value={ctrl.props.option.blender?.ratio} max={1} min={0} step={0.1} onChange={(e) => { setRatio(Number(e.target.value)) }} />
                    <p>{ratio.toFixed(1)}</p>
                </div>
                <div className="blender-section">
                    <p>Overlay Image</p>
                    <img className="blender-image" src={`data:image/jpeg;base64,${overImageUrl}`} width={overImageInfo.ratio >= 1 ? overImageSize : 300} height={overImageInfo?.ratio >= 1 ? 450 : baseImageSize} />
                    {/* <img src={process.env.PUBLIC_URL + '/images/crop-test-img.png'} alt="" /> */}
                </div>
            </div>
            <div className='side-panel-expand'>
                <div className="side-panel-expand-description">
                    이미지 합성 노드

                    이미지 크기, 채널 동일 해야함
                </div>
                <div className="side-panel-expand-section">
                    <img src='/images/filter/example_ref.jpg' style={{ width: '50%', height: 'auto' }} />
                    <img src='/images/filter/example_laplacian_13.jpg' style={{ width: '50%', height: 'auto' }} />
                    <img src='/images/filter/example_blend.jpg' style={{ width: '50%', height: 'auto' }} />
                </div>
            </div>
        </>
    )
}
