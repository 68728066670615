import React, { Dispatch, MouseEventHandler, SetStateAction, useEffect, useRef, useState } from 'react'

import LoadingAnimation from '../../../components/loadingAnimation/LoadingAnimation';
import CanvasSearch from '../../../components/canvasSearch/CanvasSearch'
import ContextMenu from '../../datamanage/storage/ContextMenu';
import WorkspaceBtns from './WorkspaceBtns';

import {workspaceCategory} from './WorkspaceMenu'
import { WorkspaceList } from '../../../context/WorkspaceContext';
import { DragAreaPosition, Position } from '../../datamanage/storage/storage.type';

import { useAPIwithCookies } from '../../../hooks/useApiCookies';
import useWorkspaceContext from '../../../hooks/useWorkspaceContext';
import EmptyDiv from '../train-detail/EmptyDiv';

const MemoizedWorkspaceBtns = React.memo(WorkspaceBtns, areBtnPropsEqual);

function areBtnPropsEqual(prev, next) {
  return (prev.workspaceList === next.workspaceList)
}

type WorkspaceCardProps = {
  setShareOpen: (t:boolean) => void;
  setTemplateModalOpen: Dispatch<SetStateAction<boolean>>;
  workspaceList: WorkspaceList[];
  setWorkspaceList:  Dispatch<SetStateAction<WorkspaceList[]>>;
}

function WorkspaceCards({ 
  setShareOpen, 
  setTemplateModalOpen,
  workspaceList,
  setWorkspaceList,
}: WorkspaceCardProps) {
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [contextTarget, setContextTarget] = useState<any>('');
  const [contextOpen, setContextOpen] = useState<boolean>(false);
  const [contextEvent, setContextEvent] = useState<any>();

  const { recommendModelList } = useWorkspaceContext();
  const api = useAPIwithCookies();

  const setContext = (
    e: any, 
    wor_id: string
  ) => {
    setContextOpen(!contextOpen);
    setContextEvent(e);
    setContextTarget(wor_id);
  }

  // api 통신
  const getWorkspaceList = async (category: string) => {
    const { data } = await api.get(`/mlops_workspace/list/${category}`);
    return data;
  }

  const setWorkspaceBtns = async () => {
    const data = await getWorkspaceList('all');
    setWorkspaceList(data);
  }

  const copyWorkspace = async (wor_id: string) => { 
    await api.post(`/mlops_workspace/copy/${wor_id}`);
  }

  const deleteWorkspace = async (wor_id: string) => {
    await api.delete(`/mlops_workspace/delete/${wor_id}`);
  }

  const archiveWorkspace = async (wor_id: string) => { 
    await api.post(`/mlops_workspace/archive/${wor_id}`);
  }

  // context menu에 들어갈 함수 (분기 포함)
  const execute = async (fn, message) => {
    setLoading(true);

    const cnt = selected.length ? selected.length : 1;

    if (!window.confirm(`This action will ${message} ${cnt} workspace(s). 
    ${message} ${cnt} workspace(s)?`)) {
      setLoading(false); 
      return;
    }
    
    if (selected.length) {
      const tempExecute = async () => {
        selected.forEach(s => {
          fn(s)});
      }
      await tempExecute();
      setSelected([]);
    } else {
      fn(contextTarget);
      setContextTarget('');
    }

    setTimeout(() => {
      setWorkspaceBtns();
      setLoading(false);
    }, 50 * cnt)
    return;
  }

  useEffect(() => {
    setWorkspaceBtns();
    const workspaceCardContentEl = document.querySelector('#workspace-card-content') ?? document.body;

    workspaceCardContentEl.addEventListener('scroll', () => {
      setContextOpen(false);
    })
    return () => {
      workspaceCardContentEl.removeEventListener('scroll', () => {
        setContextOpen(false);
      })
    }
  }, [])

  //드래그 기능 구현
  //select item by drag area
  const gridAreaRef = useRef<HTMLDivElement>(null);
  const [dragging, setDragging] = useState<boolean>(false);
  const [positionS, setPositionS] = useState<Position | undefined>(undefined);
  const [dragAreaPos, setDragAreaPos] = useState<DragAreaPosition>();

  //selected
  const [selected, setSelected] = useState<string[]>([]);

  const handleDragStart: MouseEventHandler = (e) => {
    const gridDOM_pos = gridAreaRef.current?.getBoundingClientRect();
    if (!gridDOM_pos) return;

    let isRightButton: boolean;
    if ("which" in e)  // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
      isRightButton = e.which === 3; 
    else if ("button" in e)  // IE, Opera 
      isRightButton = e.button === 2; 

    if (isRightButton) return;
    setSelected([]);
    setDragging(true);
    setPositionS({ top: e.clientY , left: e.clientX  });
  }

  //dragging set drag area
  const handleDragging: MouseEventHandler = (e) => {
    const gridDOM_pos = gridAreaRef.current?.getBoundingClientRect();
    if (!gridDOM_pos) return;
    
    if (dragging && positionS) {
      //Mouse absolute position
      const { clientX, clientY } = e;
      //Mouse relative position to card list
      
      const [mouseTop, mouseLeft] = [clientY , clientX ];
      const top = Math.min(mouseTop, positionS.top);
      const left = Math.min(mouseLeft, positionS.left);
      const height = Math.abs(mouseTop - positionS.top);
      const width = Math.abs(mouseLeft - positionS.left);
      setDragAreaPos({ top: top , left: left, height: height, width: width });
    }
  }

  //drag end get selected item by drag area
  const handleDragEnd: MouseEventHandler = () => {
    if (!dragging) return;

    setDragging(false);

    const contentWrapper = document.querySelector('#workspace-card-content');
    const scrollTop = contentWrapper?.scrollTop ?? 0;

    let dragAreaSelected: string[] = [];
    
    for (let item of workspaceList) {
      const dom = document.getElementById(item.wor_id);
      if (!dom) return;
      const [left, top, height, width] = [dom.offsetLeft + 350, dom.offsetTop - scrollTop + 50, dom.offsetHeight, dom.offsetWidth];
      const centerPos: Position = {
        top: top + height / 2,
        left: left + width / 2
      }

      if (!dragAreaPos) return;
      if (
        dragAreaPos.left < centerPos.left 
        && (dragAreaPos.left + dragAreaPos.width) > centerPos.left 
        && dragAreaPos.top < centerPos.top 
        && (dragAreaPos.top + dragAreaPos.height) > centerPos.top
      ) {
        // dom.classList.add('selected');
        dragAreaSelected.push(item.wor_id);
      }
    }

    setSelected(dragAreaSelected);
    setPositionS(undefined);
    setDragAreaPos(undefined);
  }

  useEffect(() => {
    if (selected.length) {
      selected.forEach(v => {
        const nodes = document.querySelectorAll('li.workspace-btn');
        nodes.forEach(n => {
          if (n.id === v) {
            n?.classList.add('selected');
          }
        })
      });
    } else {
      const nodes = document.querySelectorAll('li.workspace-btn');
        nodes.forEach(n => {
          n?.classList.remove('selected');
        })
    }
  }, [selected])

  const [filteredList, setFilteredList] = useState<WorkspaceList[]>([]);

  useEffect(() => {
    if (!keyword.length) {
      setWorkspaceBtns();
    } else {
      setFilteredList(
        workspaceList.filter( v => v.title?.toLowerCase().includes(keyword?.toLowerCase()) )
      );
    }
  }, [keyword])

  const [selectedCategory, setSelectedCategory] = useState<string[]>([]);

  useEffect(() => {
    setFilteredList(
      workspaceList.filter( v => selectedCategory.includes(v.category.toLowerCase()))
    )
  }, [selectedCategory])

  return (
    <div 
      className="workspace-card-right"
    >
      {loading && <LoadingAnimation label={'working...'}/>}
      <h2 className='a11y-hidden'>workspace card list</h2>
      <div 
        id="workspace-card-container" 
        className="inner"
        onClick={() => setContextOpen(false)}
      >
        <CanvasSearch 
          setKeyword={setKeyword}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
        <div 
          className="workspace-card-content" 
          id='workspace-card-content' 
          onContextMenu={(e)=>{setContextOpen(true)}}
          onMouseDown={handleDragStart}
          onMouseUp={handleDragEnd}
          onMouseMove={handleDragging}
          onMouseLeave={() => { setDragging(false); setPositionS(undefined); setDragAreaPos(undefined); }}
          ref={gridAreaRef}
        >
          <div className='recommend-cards'>
            <h3>Recommended</h3>
            <ul>
              <MemoizedWorkspaceBtns 
                setContext={setContext}
                workspaceList={recommendModelList ?? []}
                setTemplateModalOpen={setTemplateModalOpen}
              />
            </ul>
          </div>

          <div className='all-cards' id='all-cards'>
            <h3>{workspaceCategory}</h3>
              {
                (keyword.length || selectedCategory.length)
                ? 
                  filteredList.length
                  ?
                  <ul>
                    <MemoizedWorkspaceBtns 
                      setContext={setContext}
                      workspaceList={filteredList}
                      keyword={keyword}
                    />
                  </ul>
                  : <EmptyDiv label='검색결과 없음' /> 
                : workspaceList.length
                  ?
                  <ul>
                    <MemoizedWorkspaceBtns 
                      setContext={setContext}
                      workspaceList={workspaceList}
                    />
                  </ul>
                  : <EmptyDiv label="워크스페이스 없음" />
              }
            {contextOpen &&
              <ContextMenu 
                containerId='workspace-card-container' 
                event={contextEvent} 
                onClose={() => { 
                  setContextOpen(false) 
                }}
                functions={[
                  { label: 'delete', on: () => { execute(deleteWorkspace, "delete"); }},
                  { label: 'copy', on: () => { execute(copyWorkspace, "copy"); }},
                  { label: 'archive', on: () => {  execute(archiveWorkspace, "archive") }},
                  { label: 'share', on: () => { setShareOpen(true) }}
                ]} 
              />
            }
          </div>
          
          {/* Drag Area */}
          <div 
            className='drag-area' 
            style={{ 
              background: 'rgba(141, 89, 252, 0.2666666667)',
              width: dragAreaPos?.width, 
              height: dragAreaPos?.height, 
              position: 'fixed', 
              top: dragAreaPos?.top, 
              left: dragAreaPos?.left 
            }} 
          />
        </div>
        
      </div>
    </div>
  )
}

export default WorkspaceCards;