import React from 'react';
import * as d3 from "d3";
import { AxisLeft } from './AxisLeft';
import { AxisBottom } from './AxisBottom';

type FVScatterPlotProps = {
  width: number;
  height: number;
  data: { x: number; y: number }[];
};

const MARGIN = { top: 30, right: 30, bottom: 30, left: 30 };

export default function FVScatterPlot({ width, height, data }: FVScatterPlotProps) {
  // Layout. The div size is set by the given props.
  // The bounds (=area inside the axis) is calculated by substracting the margins
  const boundsWidth = width - MARGIN.right - MARGIN.left;
  const boundsHeight = height - MARGIN.top - MARGIN.bottom;

  let [min, max] = d3.extent(data, (d) => d.y);
  [min, max] = [min || 0 , max || 10];
  let [xMin, xMax] = d3.extent(data, (d) => d.x);
  [xMin, xMax] = [xMin || 0 , xMax || 10];

  // Scales
  const yScale = d3.scaleLinear().domain([min - (max * 0.2), max  + (max * 0.2)]).range([boundsHeight, 0]);
  const xScale = d3.scaleLinear().domain([xMin - (xMax * 0.2), xMax  + (xMax * 0.2)]).range([0, boundsWidth]);

  // Build the shapes
  const allShapes = data.map((d, i) => {
    return (
      <circle
        key={i}
        r={13}
        cx={xScale(d.y)}
        cy={yScale(d.x)}
        opacity={1}
        stroke="#cb1dd1"
        fill="#cb1dd1"
        fillOpacity={0.2}
        strokeWidth={1}
      />
    );
  });

  return (
    <div>
      <svg width={width} height={height}>
        <g
          width={boundsWidth}
          height={boundsHeight}
          transform={`translate(${[MARGIN.left, MARGIN.top].join(',')})`}
        >
          {/* Y axis */}
          <AxisLeft yScale={yScale} pixelsPerTick={40} width={boundsWidth} />

          {/* X axis, use an additional translation to appear at the bottom */}
          <g transform={`translate(0, ${boundsHeight})`}>
            <AxisBottom
              xScale={xScale}
              pixelsPerTick={40}
              height={boundsHeight}
            />
          </g>

          {/* Circles */}
          {allShapes}
        </g>
      </svg>
    </div>
  );
  
}
