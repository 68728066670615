import React from 'react'
import { Link } from "react-router-dom";
import './landingFooter.scss';

export const LandingFooter:React.FC = () => {
  return (
    <>
        <footer>
            <Link to="/vience-canvas">
                <img src={process.env.PUBLIC_URL + '/images/logo-footer.svg'} alt="vience canvas" />
            </Link>
            <address>
                <p>643, Korea University R&D Center, Anam-ro 145, Seongbuk-gu, Seoul, Republic of Korea</p>
                <p>Email : contact@vience.co.kr</p>
            </address>
        </footer>
    </>
  )
}
