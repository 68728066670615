import React from 'react'
import CustomModal from '../../../common/CustomModal'
import { Link } from 'react-router-dom'

export type WorkspaceModalProps = {
  workspaceModalOpen: boolean;
  handleWorkspaceModal: () => void;
}

export default function WorkspaceModal({ 
  workspaceModalOpen, 
  handleWorkspaceModal 
}: WorkspaceModalProps) {

  const wor_id = 'asdfasdff' + Math.ceil(Math.random()*1000);

  return (
    <CustomModal open={workspaceModalOpen} onClose={handleWorkspaceModal} bgShown='blur' bgColor='#010116'>
      <div className='workspace-option-frame'>
        <strong>Workspace option</strong>
          <Link to="/vience-canvas/mlops/template">
            <button className='fill-white-outline'>Start with Template</button>
          </Link>
        <div className='workspace-link-btn'>
          <Link to={`/vience-canvas/mlops/${wor_id}`}>
            <button className='fill-primary'>Start New Workspace</button>
          </Link>
        </div>
      </div>
    </CustomModal>
  )
}
