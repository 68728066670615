export const categoryList = ['All', 'My', 'Shared', 'Archived', 'Trashed'];

export const recommendWorkspaceList = [
  {
    category: "classification",
    description: `
    \n Model: UNet.py (inchannel=3, classes=2) 
    \n Dataset: patch_512 (812 patch)
    \n ○Evaluation metrics: Loss, Accuracy
    \n 
    \n ##  학습 파라미터
    \n 1. epoch = 100
    \n 2. learning rate = 0.001
    \n 3. batch size = 2
    \n 4. loss function: BCE(Binary Cross Entropy) Loss
    \n 5. optimizer: AdamWIssue) class imbalance
    `,
    nodes: "",
    title: "지방간 영역분할",
    thumbnail: "지방간2.png"
  }, 
  {
    category: "classification",
    description: `
    \n ○UNet.py  모델 사용(inchannel=1, classes=1)
    \n ○Dataset: tem(128) -> Gaussian Noise (standard deviation = 25)
    \n Evaluation metrics: Loss, PSNR(Peak Signal-to-Noise Ratio), SSIM(Structural Similar-
    `,
    nodes: "",
    title: "의료영상 노이즈제거",
    thumbnail: "denoise.png"
  }, 
  {
    category: "classification",
    description: `
    \n ○UNet.py  모델 사용(inchannel=1, classes=1)
    \n ○Dataset: tem(128) -> Gaussian Noise (standard deviation = 25)
    \n Evaluation metrics: Loss, PSNR(Peak Signal-to-Noise Ratio), SSIM(Structural Similar-
    `,
    nodes: "",
    title: "지방간 진행정도 예측",
    thumbnail: "지방간.png"
  }, 
  {
    category: "classification",
    description: `
    \n Model: UNet.py (inchannel=3, classes=2) 
    \n Dataset: patch_512 (812 patch)
    \n ○Evaluation metrics: Loss, Accuracy
    \n 
    \n ##  학습 파라미터
    \n 1. epoch = 100
    \n 2. learning rate = 0.001
    \n 3. batch size = 2
    \n 4. loss function: BCE(Binary Cross Entropy) Loss
    \n 5. optimizer: AdamWIssue) class imbalance
    `,
    nodes: "",
    title: "의료영상 해상도 개선",
    thumbnail: "resolution.png"
  }, 
]