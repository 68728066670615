import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { WorkspaceContext } from '../../../context/WorkspaceContext';
import SecondaryButton from '../../../components/button/SecondaryButton';

export default function NewWorkspaceBtn() {
  const navigate = useNavigate();
  const { functions } = useContext(WorkspaceContext);
  const { 
    createNewWorkspace
  } = functions; 

  const handleClickNew = async () => {
    const wor_id = await createNewWorkspace();
    navigate(`/vience-canvas/mlops/${wor_id}`);
  }
  
  return (
    <SecondaryButton 
      type="button"
      className="btn-secondary"
      onClick={handleClickNew}
    >
      Start New Workspace
    </SecondaryButton>
  )
}
