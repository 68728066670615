import React from 'react'
import MlopsControlBtn from './MlopsControlBtn'
import MlopsControlBtnCategoryTitle from './MlopsControlBtnCategoryTitle'

export default function MlopsControlBtnCategory({categoryData, btnData}) {

  return (
    <li className="control-container">
      <div className="inner">

        <MlopsControlBtnCategoryTitle 
          category={categoryData.name} 
          desc={categoryData.desc} 
          step={categoryData.step} 
        />
        <ul> 
        { btnData.map(data => (
          <li key={data.label}>
            <MlopsControlBtn data={data}/>
          </li>
        ))}
        </ul>
        
      </div>
    </li>
  )
}
