import React, { useContext, useState, Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';

import { categoryList } from './mockData';  

import styled from 'styled-components';
import NewWorkspaceBtn from './NewWorkspaceBtn';

import { CustomThemeContext} from '../../../utils/Context';
import { WorkspaceList } from '../../../context/WorkspaceContext';
import { useAPIwithCookies } from '../../../hooks/useApiCookies';
import LeftPanel from '../../../components/leftPanel/LeftPanel';
import PrimaryButton from '../../../components/button/PrimaryButton';

const Container = styled(LeftPanel)<{$theme: 'light' | 'dark'}>`
  .btn-secondary {
    margin-bottom: var(--8px);
  }
  .btn-primary {
    margin-top: var(--8px);
  }
  .train-result-access {
    button {
      width: 100%;
      padding: var(--12px);
      padding-right: var(--24px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: ${props => props.$theme === 'light' ? '#080821' : '#fff'} !important; 
      font-weight: 700;
      span {
        transition: all 0.3s;
      }
      ${(props) => props.$theme === 'light' ? `
        border-top: 1px solid #D9D9E5;
        border-bottom: 1px solid #D9D9E5;
      ` : `
        border-top: 1px solid #322686;
        border-bottom: 1px solid #322686;
      `}
    }

    &:hover {
      background-color: #8D59FC44;

      span {
        transform: translateX(var(--12px));
      }
    }
  }
`

export var workspaceCategory: string = 'All';

type WorkspaceMenuProps = {
  setWorkspaceList: Dispatch<SetStateAction<WorkspaceList[]>>
}

function WorkspaceMenu({setWorkspaceList}: WorkspaceMenuProps) {
  const api = useAPIwithCookies();
  const { theme } = useContext(CustomThemeContext);
  const [w_category, setWCategory] = useState('All');

  const getWorkspaceList = async (category: WorkspaceList) => {
    const { data } = await api.get(`/mlops_workspace/list/${category}`);
    return data;
  }

  const handleCategory = async (category) => {
    workspaceCategory = category;
    setWCategory(category);
    const workspaceInCategory = await getWorkspaceList(category.toLowerCase());
    setWorkspaceList(workspaceInCategory);
  }

  return (
    <Container className='workspace-menu left' $theme={theme}>
      <h2 className='a11y-hidden'>workspace menu list</h2>
      <div className='new-workspace-buttons'>
        <h3 className='a11y-hidden'>new workspace buttons</h3>
        <NewWorkspaceBtn />
        <Link to={`/vience-canvas/mlops/code-editor`}>
          <PrimaryButton 
            type="button"
            className="btn-primary"
          >
            Start with My Code
          </PrimaryButton>
        </Link>
      </div>

      <div className='train-result-access'>
        <Link to={`/vience-canvas/mlops/_/detail`}>
          <button 
            type="button"
            className="view-train-session-list"
          >
            <p>View Train Session List</p>
            <span>&gt;</span>
          </button>
        </Link>
      </div>

      <div className='workspace-category-container'>
        <h3 className='a11y-hidden'>new workspace category buttons</h3>
        <ul>
          {categoryList.map(category => (
            <li key={category}
            className={ category === w_category ? 'selected' : ''}>
              <button
                className='workspace-category-card'
                id={`workspace-category-${category}`}
                onClick={() => handleCategory(category)}
              >
                {category} Workspace
              </button>
            </li>
          ))}
        </ul>
      </div>
    </Container>
  )
}

export default React.memo(WorkspaceMenu);