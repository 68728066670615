import { Link } from "react-router-dom";
import "./front.styles.scss";
import React, { useEffect, useState } from "react";
import NavigateButton from "./NavigateButton";
import { btnData } from "./btnData";
import { IconButton } from 'rsuite';
import Home from '@rsuite/icons/legacy/Home';

const Front = () => {
  // 호버 함수
  const [hoverTarget, setHoverTarget] = useState(null);
  const handleEnter = (e) => {
    setHoverTarget(e.target);
  }
  const handleLeave = (e) => {
    setHoverTarget(null);
  }

  // hoverTarget 바뀔때마다 배경 바뀌게
  const [background, setBackground] = useState({backgorund: '#222'});
  useEffect(() => {
    if (!hoverTarget) {
      setBackground({backgorund: '#222'});
      return;
    }
    if (hoverTarget.classList.contains("CANVAS")) {
      setBackground({backgroundImage: 'url(./canvas.png)'});
    } else if (hoverTarget.classList.contains("PathoView")) {
      setBackground({backgroundImage: `url(./pathoview.png)`});
    } else if (hoverTarget.classList.contains("CellView")) {
      setBackground({backgroundImage: `url(./cellview.png)`});
    }
  }, [hoverTarget]);

 // 버튼 새로 만들기
  const navigateButtons = btnData.map((data, index) => {
    return (
      <li key={index}>
        <NavigateButton 
          data={data} 
          handleEnter={handleEnter} 
          handleLeave={handleLeave}
        />
      </li>
      )
  });

  return (
    <>
    <div className="front-background" style={background}>
      <div className="dim">
        <header className="about-vience a11y-hidden" >
          <h1 id="main-title">
            바이오메디컬영상 AI분석 플랫폼 전문기업, 주식회사 바이언스 (VIENCE)
          </h1>
          <h2 id="keyword">
            VIENCE, 바이언스, 의료영상분석, AI분석 플랫폼, MLOps, XROps, CellView, PathoView
          </h2>
        </header>

        <section className="btn-section">
          <div className="divide-line"/>    
          <ul className="button-container">
            {navigateButtons}
          </ul>
          <div className="divide-line"/>
        </section>

        <footer className="front-title-box">
          <div className="front-title-left">
            <Link to={"/main"} className="logo-link">
              <img src="footer-logo.png" className="logo-img" alt="viecne-logo"/>
            </Link>
            <div className="front-sub-title">
              <p className="sub-title-en">Bridging Computers and Sciences</p>
              <p className="sub-title-kr">바이오메디컬영상 AI분석 플랫폼 전문기업 | 주식회사 바이언스</p>
            </div>
          </div>

          <div className="vertical-divide-line"></div>

          <div className="front-info-conatiner">
            <Link to={"/main"} className="home-link">
              <img src="icon-home.png" className="home-link-img" alt="home-link"/>
            </Link>
            <div className="front-info">
              <div className="front-info-phone">
                <img src="icon-phone.png" alt="icon-phone" />
                <p>(02) 3290 - 3849</p>
              </div>
              <div className="front-info-address">
                <img src="icon-location.png" alt="icon-location"/>
                <p>(02841) 서울특별시 성북구 안암로 145 <br />
                고려대학교 R&D센터 643호</p>
              </div>
            </div>
          </div>

        </footer>
      </div>
    </div>
    </>
  );
};

export default Front;
