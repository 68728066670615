import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';

import OptionInput from '../../PanelOptionComponent/OptionInput'
import TrainOptionSelect from './TrainOptionSelect';

import { type ILayerOption } from '../../../flow';
import { type TrainerControl } from '../../../nodes/TrainerNode';
import { WorkspaceContext } from '../../../../../context/WorkspaceContext';

type TrainOptionProps = {
  ctrl: TrainerControl; 
  initOption: {current: ILayerOption};
  setOption: Dispatch<SetStateAction<ILayerOption>>;
  loading: boolean; 
  setLoading: Dispatch<SetStateAction<boolean>>;
}

export default function TrainOption({ 
  ctrl,
  initOption,
  setOption,
  loading,  setLoading
}: TrainOptionProps) {
  const [modelName, setModelName] = useState('');
  const [newWeightName, setNewWeightName] = useState('');
  const [selectList, setSelectList] = useState({});
  const { selectedWorkspace } = useContext(WorkspaceContext);
  const location = useLocation();

  // option 값 바뀔 때마다 실행
  useEffect(() => {
    if (!loading) {
      setOption((prev: ILayerOption) => {
        return {
          ...prev,
          ...selectList,
          modelName: modelName.replace(/ /g, ''),
          newWeightName: newWeightName.replace(/ /g, '')
        }
      })
    }
  }, [modelName, newWeightName, selectList]);

  useEffect(() => {
    if (initOption.current.modelName?.includes('untitled_modelFlow') && (selectedWorkspace?.title ?? location.state?.title)) {
      const newModelName = selectedWorkspace?.title ?? location.state.title;
      setOption((prev) => {
        return {
          ...prev,
          modelName: newModelName
        }
      });
      setModelName(newModelName);
      const inputEl = document.querySelector('input#modelName');
      inputEl.value = newModelName;
    }
  }, [initOption.current.modelName]);

  return (
    <div className='train-option'>
      <h4 className='a11y-hidden'>Train Option</h4>
      <div className='train-identity'>
        <h5 className='a11y-hidden'>train Id</h5>
        <OptionInput
          id={'modelName'}
          type='text'
          label='Model Name'
          setValue={setModelName}
          initOption={initOption}
          setLoading={setLoading} 
        />
        <OptionInput
          id={'newWeightName'}
          type='text'
          label='New Weight Name = Train Session Key'
          setValue={setNewWeightName}
          initOption={initOption}
          setLoading={setLoading}
        />
      </div>
      <TrainOptionSelect
        initOption={initOption}
        selectList={selectList}
        setSelectList={setSelectList}
        loading={loading}
        setLoading={setLoading} 
      />
    </div>
  )
}
