/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

interface Props {
  className: any;
}

export const DynamicTrue2 = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={`dynamic-true-2 ${className}`}
      fill="none"
      height="44"
      viewBox="0 0 44 44"
      width="44"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect className="rect" height="15" rx="1.5" stroke="#65646A" width="6" x="5.5" y="4.5" />
      <path className="path" d="M12 13L14.6667 13" stroke="#65646A" />
      <path className="path" d="M21 8L23.6667 8" stroke="#65646A" />
      <path className="path" d="M21 17L23.6667 17" stroke="#65646A" />
      <path className="path" d="M30 8L32 8" stroke="#65646A" />
      <path className="path" d="M21 27L23 27" stroke="#65646A" />
      <path className="path" d="M21 36L23 36" stroke="#65646A" />
      <rect className="rect" height="6" rx="1.5" stroke="#65646A" width="6" x="23.5" y="4.5" />
      <rect className="rect" height="15" rx="1.5" stroke="#65646A" width="6" x="14.5" y="4.5" />
      <rect className="rect" height="6" rx="1.5" stroke="#65646A" width="15" x="5.5" y="23.5" />
      <rect className="rect" height="6" rx="1.5" stroke="#65646A" width="15" x="23.5" y="13.5" />
      <rect className="rect" height="6" rx="1.5" stroke="#65646A" width="15" x="5.5" y="32.5" />
      <rect className="rect" height="15" rx="1.5" stroke="#65646A" width="15" x="23.5" y="23.5" />
      <rect className="rect" height="6" rx="1.5" stroke="#65646A" width="6" x="32.5" y="4.5" />
    </svg>
  );
};
