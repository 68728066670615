import React from "react";
import { ClassicPreset, NodeId } from "rete";
import { CustomSocket, NodeSize, ResizeColor } from "../../style";
import CustomControl from "../../style/CustomControl";
import { ILayerOption, IMlopsflow } from "../flow";

export class MaxPool2dNode extends ClassicPreset.Node<
  { in: ClassicPreset.Socket },
  { out: ClassicPreset.Socket },
  { ctrl: MaxPool2dControl }
> {
  color = ResizeColor;
  width = NodeSize.width;
  height = NodeSize.height;
  
  constructor(
    process: () => void, 
    update: (control: MaxPool2dControl) => void, 
    nodeId: NodeId
  ) {
    super('MaxPool 2D');
    this.id = nodeId === "" ? this.id : nodeId;
    this.addInput('in', new ClassicPreset.Input(new CustomSocket()));
    this.addOutput('out', new ClassicPreset.Output(new CustomSocket()));
    this.addControl('ctrl', new MaxPool2dControl(
      process, 
      update,
      this.id,
      ));
  }

  data(input): { out?: IMlopsflow | undefined } {
    let output: IMlopsflow = {
      datasetPath: {},
      layer: {
        input: [],
        node: {
          "maxPool2d": this.controls.ctrl.props.option
        },
      }
    };

    if (input.in) {
      const inputNode = input.in[0]

      if (inputNode){
        const data1_key = Object.keys(inputNode.layer.node)[0];
        if(data1_key!=="dataset"){
          if(inputNode.layer.node[data1_key].outChannel){
            this.controls.ctrl.props.option.numFeatures = inputNode.layer.node[data1_key].outChannel;
          }
          else if(inputNode.layer.node[data1_key].numFeatures){
            this.controls.ctrl.props.option.numFeatures = inputNode.layer.node[data1_key].numFeatures;
          }
        }
      }

      if (inputNode && inputNode.datasetPath) {
        const data1_key = Object.keys(inputNode.layer.node)[0];
        let convertedResolution = 
          inputNode.layer.node[data1_key].datasetResolution 
          / Number(this.controls.ctrl.props.option.kernelSize ? this.controls.ctrl.props.option.kernelSize: 3);
        
        if (Number.isNaN(convertedResolution)) {
          convertedResolution = 66
        }

        this.controls.ctrl.props.option.datasetResolution = convertedResolution;
        this.controls.ctrl.datasetResolution = convertedResolution;
        if (localStorage.getItem('dynamic') === 'true') {
          this.height = convertedResolution < 66 ? 66 : convertedResolution;
            this.width = (
              50 * Math.log2(Number(this.controls.ctrl.props.option.numFeatures))) < 222 
            ? 222 
          : (50 * Math.log2(Number(this.controls.ctrl.props.option.numFeatures)));
        }
        output = {
          datasetPath: inputNode?.datasetPath ?? "",
          layer: {
            input: [inputNode?.layer],
            node: {
              "maxPool2d": this.controls.ctrl.props.option
            }
          }
        }
        
        if (this.controls.ctrl.modelFlow != output){
          this.controls.ctrl.modelFlow = output;
        }
      }
    }

    return {
      out: output
    }
  }
}

export class MaxPool2dControl extends ClassicPreset.Control {
  contextOpen: boolean;
  props: {
    option: ILayerOption;
  };
  modelFlow: IMlopsflow;
  datasetResolution: number;

  constructor(
    public onChange: () => void, 
    public update: (c: MaxPool2dControl) => void, 
    public nodeId: NodeId,
    ) {
    super();
    this.contextOpen = false;
    this.props = {
      option: {
        nodeId,
        name: 'MaxPool2d',
        kernelSize: 2,
        numFeatures: 1,
      }
    };
    this.modelFlow = {
      datasetPath: {undefined},
      layer: undefined,
    };
    this.datasetResolution = 66;
  }

  setValue(data: ILayerOption) {
    this.props.option = data;
    this.onChange();
  }

  setContextOpen = (open: boolean) => {
    this.contextOpen = open;
    this.update(this);
  }
}

export function MaxPool2dComponent({ data }: { 
  data: MaxPool2dControl 
}) {
  if (!data.nodeId) return <p>Error</p>;

  return (
    <CustomControl 
      nodeId={data.nodeId}
      label="MaxPool 2D" 
      iconSource="denoising" 
      markerSource='node-effector' 
      contextOpen={data.contextOpen}
      setContextOpen={data.setContextOpen}
    />
  )
}