import React, { useEffect, useState } from 'react'
import OptionInput from '../PanelOptionComponent/OptionInput';
import { isPositive } from '../PanelOptionComponent/inputUtils';

export default function BatchNormOptionSection({ nodeId, initOption, option, setOption, loading, setLoading }) {
  const [nodeName, setNodeName] = useState<string>('');
  const [numFeatures, setNumFeatures] = useState<number>(0);
  
  // 각 input에 초기값 세팅되기 전까지 로딩상태여야함
  useEffect(() => {
    setLoading(true);
    setNodeName('');
    setNumFeatures(0);
  }, [nodeId])

  useEffect(() => {
    if (!loading) {
      setOption({
        ...option, 
        name: nodeName,
        numFeatures, 
      })
    }
  }, [
    nodeName,
    numFeatures, 
  ]);

  return (
    <form name='convolution2dOption'>
      <OptionInput initOption={initOption} setValue={setNodeName} id={"name"} label='Name of Node' setLoading={setLoading} type='text'/>
      <OptionInput initOption={initOption} setValue={setNumFeatures} id={"numFeatures"} label='Number of Features' setLoading={setLoading} isValidInput={isPositive}/>
    </form>
  )
}
