import React, { ReactNode, useContext } from 'react';
import { Modal } from 'rsuite';
import styled, { css } from 'styled-components';
import { CustomThemeContext } from '../utils/Context';

type TBackgroundType = 'blur' | 'transparent' | 'dark' | 'none' | 'light';

const backgroundByType = (bgShonw: TBackgroundType, bgColor: string) => {

    let background;

    switch (bgShonw) {
        case "blur":
            background = `
                backdrop-filter: blur(3px);
                background: ${bgColor};
                opacity: 0.6;
            `;
            break;
        case "dark":
            background = `
            background-color: ${bgColor}
            `;
            break;
        case "transparent":
            background = `
            background-color: ${bgColor};
            opacity: 0.7;
            `;
            break;
        case "none":
            break;
    }

    return css`
        ${background}
            `
}


const Background = styled.div<{ bgShown: TBackgroundType, bgColor: string }>`
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    ${props => backgroundByType(props.bgShown, props.bgColor)};
`
const ModalContainer = styled.div`
    position: fixed;
    display: flex;
    left: 0;
    top: 0;
    background: red;
    z-index: 105;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const ModalChildrenWrapper = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-height: 300px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    z-index: 110;
`

interface CustomModalProps {
    children: ReactNode;
    open: boolean;
    onClose: () => void;
    bgShown?: TBackgroundType;
    bgColor?: string;
    className?: string;
}

export default function CustomModal({ open, onClose, children, bgShown = 'dark', bgColor = '#010116', className='' }: CustomModalProps) {
    const { theme } = useContext(CustomThemeContext);
    return (
        <ModalContainer>
            {
                open &&
                <>
                    <Background bgShown={bgShown} bgColor={theme === 'dark' ? 'rgb(1, 1, 22)' : 'rgb(240, 242, 254)'} onClick={onClose} />
                    <ModalChildrenWrapper className={className}>
                        {children}
                    </ModalChildrenWrapper>
                </>
            }
        </ModalContainer>
    )
}
