import React, { useContext } from 'react'
import LeftPanel from '../../../components/leftPanel/LeftPanel';
import PrimaryButton from '../../../components/button/PrimaryButton';
import { StorageContext } from '../../../context/StorageContext';

export default function StorageLeftPanel({
  storageTypeList,
  storageType,
  setStorageType,
}) {
  const { handleUpload } = useContext(StorageContext);
  
  return (
    <LeftPanel id='storage-grid-left-panel' className="workspace-menu left">

      <PrimaryButton>
        <input id='storage-upload' multiple type='file' onChange={handleUpload} />
        <label className='btn-primary' htmlFor='storage-upload'>Data Upload</label>
      </PrimaryButton>

      <ul id='storage-type-list'>
        {storageTypeList.map(data => {
          return (
            <li className={`storage-type ${storageType === data ? 'selected' : ''}`}>
              <button 
                type="button"
                onClick={() => { setStorageType(data) }}
              >
                {data}
              </button>
            </li>
          )
        })}
      </ul>
  </LeftPanel>
  )
}
