import React from "react";
import { ClassicPreset, NodeId } from "rete";
import { CustomSocket, EffectorColor_step3, NodeSize } from "../../style";
import CustomControl from "../../style/CustomControl";
import { ILayerOption, IMlopsflow } from "../flow";

export class ReluActivationNode extends ClassicPreset.Node<
  { in: ClassicPreset.Socket },
  { out: ClassicPreset.Socket },
  { ctrl: ReluActivationControl }
> {
  color = EffectorColor_step3;
  width = NodeSize.width;
  height = NodeSize.height;
  error = '';
  
  constructor(
    process: () => void, 
    update: (control: ReluActivationControl) => void, 
    nodeId: NodeId
  ) {
    super('Relu Activation');
    this.id = nodeId === "" ? this.id : nodeId;
    this.addInput('in', new ClassicPreset.Input(new CustomSocket()));
    this.addOutput('out', new ClassicPreset.Output(new CustomSocket()));
    this.addControl('ctrl', new ReluActivationControl(
      process, 
      update,
      this.id,
      ));
  }

  data(input): { out?: IMlopsflow | undefined } {
    let output: IMlopsflow = {
      datasetPath: {},
      layer: {
        input: [],
        node: {
          "reluActivation": this.controls.ctrl.props.option
        },
      }
    };

    if (input.in) {
      const inputNode = input.in[0]

      if (inputNode){
        const data1_key = Object.keys(inputNode.layer.node)[0];
        if (data1_key !== "dataset"){
          if(inputNode.layer.node[data1_key].outChannel){
            this.controls.ctrl.props.option.numFeatures = inputNode.layer.node[data1_key].outChannel;
          }
          else if(inputNode.layer.node[data1_key].numFeatures){
            this.controls.ctrl.props.option.numFeatures = inputNode.layer.node[data1_key].numFeatures;
          }
        } 
        // if (data1_key === 'batchNorm2d') {
        //   this.error = '';
        // } else {
        //   this.error = 'Nodes NOT Connected in the Recommended Order';
        // }
      }

      if (inputNode && inputNode.datasetPath) {
        const data1_key = Object.keys(inputNode.layer.node)[0];

        let convertedResolution = 
          inputNode.layer.node[data1_key].datasetResolution;
        
          if (Number.isNaN(convertedResolution)) {
          convertedResolution = 66
        }

        this.controls.ctrl.props.option.datasetResolution = convertedResolution;
        this.controls.ctrl.datasetResolution = convertedResolution;
        if (localStorage.getItem('dynamic') === 'true') {
          this.height = convertedResolution < 66 ? 66 : convertedResolution;
          this.width = (
            50 * Math.log2(Number(this.controls.ctrl.props.option.numFeatures))) < 222 
          ? 222 
          : (50 * Math.log2(Number(this.controls.ctrl.props.option.numFeatures)));
        }
        else{
          this.color = { base: '#296871', sub1: '#2F7781', sub2: '#226D78' };
        }

        output = {
          datasetPath: inputNode?.datasetPath,
          layer: {
            input: [
              inputNode?.layer
            ],
            node: {
              "reluActivation": this.controls.ctrl.props.option
            }
          }
        }
        
        if (this.controls.ctrl.modelFlow != output){
          this.controls.ctrl.modelFlow = output;
        }
      }
    }
    
    return {
      out: output
    }
  }
}

export class ReluActivationControl extends ClassicPreset.Control {
  contextOpen: boolean;
  props: {
    option: ILayerOption;
  };
  modelFlow: IMlopsflow;
  datasetResolution: number;

  constructor(
    public onChange: () => void, 
    public update: (c: ReluActivationControl) => void, 
    public nodeId: NodeId,
    ) {
    super();
    this.contextOpen = false;
    this.props = {
      option: {
        nodeId,
        name: 'ReLU',
        numFeatures: 1,
      }
    };
    this.modelFlow = {
      datasetPath: {undefined},
      layer: undefined,
    };
    this.datasetResolution = 66;
  }

  setValue(data: ILayerOption) {
    this.props.option = data;
    this.onChange();
  }

  setContextOpen = (open: boolean) => {
    this.contextOpen = open;
    this.update(this);
  }
}

export function ReluActivationComponent({ data }: { 
  data: ReluActivationControl 
}) {
  if (!data.nodeId) return <p>Error</p>;

  return (
    <CustomControl 
      nodeId={data.nodeId}
      label="Relu Activation" 
      iconSource="denoising" 
      markerSource='node-effector' 
      contextOpen={data.contextOpen}
      setContextOpen={data.setContextOpen}
    />
  )
}