/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

interface Props {
  className: any;
}

export const DynamicFalse = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={`dynamic-false ${className}`}
      fill="none"
      height="44"
      viewBox="0 0 44 44"
      width="44"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect className="rect" height="5" rx="1.5" stroke="#65646A" width="7" x="23.8335" y="5.5" />
      <rect className="rect" height="5" rx="1.5" stroke="#65646A" width="7" x="34.5005" y="5.5" />
      <rect className="rect" height="5" rx="1.5" stroke="#65646A" width="7" x="2.5" y="5.5" />
      <rect className="rect" height="5" rx="1.5" stroke="#65646A" width="7" x="13.166" y="5.5" />
      <path className="path" d="M20.6665 7.99609L23.3332 7.99609" stroke="#65646A" />
      <path className="path" d="M9.99951 7.99609L12.6662 7.99609" stroke="#65646A" />
      <path className="path" d="M31.334 7.99609L34.0007 7.99609" stroke="#65646A" />
      <rect className="rect" height="5" rx="1.5" stroke="#65646A" width="7" x="23.8335" y="14.5" />
      <rect className="rect" height="5" rx="1.5" stroke="#65646A" width="7" x="34.5005" y="14.5" />
      <rect className="rect" height="5" rx="1.5" stroke="#65646A" width="7" x="2.5" y="14.5" />
      <rect className="rect" height="5" rx="1.5" stroke="#65646A" width="7" x="13.166" y="14.5" />
      <path className="path" d="M20.6665 16.9961L23.3332 16.9961" stroke="#65646A" />
      <path className="path" d="M9.99951 16.9961L12.6662 16.9961" stroke="#65646A" />
      <path className="path" d="M31.334 16.9961L34.0007 16.9961" stroke="#65646A" />
      <rect className="rect" height="5" rx="1.5" stroke="#65646A" width="7" x="23.8335" y="23.5" />
      <rect className="rect" height="5" rx="1.5" stroke="#65646A" width="7" x="34.5005" y="23.5" />
      <rect className="rect" height="5" rx="1.5" stroke="#65646A" width="7" x="2.5" y="23.5" />
      <rect className="rect" height="5" rx="1.5" stroke="#65646A" width="7" x="13.166" y="23.5" />
      <path className="path" d="M20.6665 25.9961L23.3332 25.9961" stroke="#65646A" />
      <path className="path" d="M9.99951 25.9961L12.6662 25.9961" stroke="#65646A" />
      <path className="path" d="M31.334 25.9961L34.0007 25.9961" stroke="#65646A" />
      <rect className="rect" height="5" rx="1.5" stroke="#65646A" width="7" x="23.8335" y="32.5" />
      <rect className="rect" height="5" rx="1.5" stroke="#65646A" width="7" x="34.5005" y="32.5" />
      <rect className="rect" height="5" rx="1.5" stroke="#65646A" width="7" x="2.5" y="32.5" />
      <rect className="rect" height="5" rx="1.5" stroke="#65646A" width="7" x="13.166" y="32.5" />
      <path className="path" d="M20.6665 34.9961L23.3332 34.9961" stroke="#65646A" />
      <path className="path" d="M9.99951 34.9961L12.6662 34.9961" stroke="#65646A" />
      <path className="path" d="M31.334 34.9961L34.0007 34.9961" stroke="#65646A" />
    </svg>
  );
};
