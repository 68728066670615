import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import TrainResultBoard from './train-result-board/TrainResultBoard';
import TrainResultProgress from './train-result-progress/TrainResultProgress';
import TrainResultStepSlider from './train-result-board/TrainResultStepSlider';
import { CustomThemeContext } from '../../../../../utils/Context';
import { TrainContext } from '../../../../../context/TrainContext';

const TrainResultTabDiv = styled.div<{
  $theme: 'light' | 'dark';
}>`
  width: 100%;
  min-height: calc(100vh - var(--72px) - 55px - var(--44px));
  overflow-y: auto;
  overflow-x: hidden;
  
  &::-webkit-scrollbar {
    display: none;
  }

  div.train-progress {
    display: flex;
    flex-direction: column;
    align-items: center;

    position: sticky;
    z-index: 100;
    top: 0;
    
    width: 100%;
    padding: 0 var(--16px);
    background-color: ${(props) => props.$theme === 'light' ? '#E9EBF8' : '#080821'};
    border-bottom: 2px solid #D9D9E5;

    ${(props) => props.$theme === 'light' ? `
      background-color: #E9EBF8;
      border: 2px solid #D9D9E5;
      ` : `
      background-color: #080821;
      border: 2px solid #1B1B47;
      `};
  }
`

export default function TrainResultTab() {
  const { wor_id } = useParams();
  const { pathname } = useLocation();

  const { 
    stepIndex, setStepIndex, 
    isTraining, trainerControl, 
    selected, setSelected, 
    functions 
  } = useContext(TrainContext);
  const { getTrainInfo } = functions;
  const { theme } = useContext(CustomThemeContext);

  const isSample = pathname.includes('sample');
  const isDetail = pathname.includes('detail');
  
  const navigate = useNavigate();

  // ---- 받아오는 중임 돌아가는 표시 ---- 
  useEffect(() => {
    let inTraining = setInterval(() => {
      if (!isDetail && isTraining) {
        getTrainInfo(trainerControl?.props.option.newWeightName);
      } 
    }, 3_000)

    return () => clearInterval(inTraining);
  }, [isTraining, isDetail])


  useEffect(() => {
    if ((selected?.status?.progress ?? 100) === 100) {
      return;
    }

    let inTraining = setInterval(async () => {
      if (isDetail && isTraining) {
        const resultData = await getTrainInfo(selected?.key);
        if (resultData) {
          setSelected({
            key: selected?.key,
            status: resultData
          });
        } 
      }
    }, 3_000);
    
    return () => clearInterval(inTraining);
  }, [
    setSelected,
    selected?.key, 
    selected?.status?.progress, 
    getTrainInfo, 
    isTraining, isDetail
  ])
  // ---- 받아오는 중임 돌아가는 표시 끝 ---- 


  useEffect(() => {
    return () => setSelected({});
  }, [])

  return (
    <TrainResultTabDiv $theme={theme}>
      <div className='train-progress'>
        { !isDetail && !isSample && <button 
          type='button' 
          onClick={() =>{
            navigate(`/vience-canvas/mlops/${wor_id}/detail`);
          }}
          className='trainer-view-detail-btn'
        >
          Move to detail view
        </button> }
        <TrainResultProgress />
        { isDetail && <TrainResultStepSlider 
          stepIndex={stepIndex}
          setStepIndex={setStepIndex}
          imgNum={selected?.status?.cur_step} 
        />}
      </div>
      
      <TrainResultBoard />
    </TrainResultTabDiv>
  )
}
