import React, { useEffect, useState } from "react";
import { ClassicPreset } from "rete";
import { AddonSocket } from "../../sockets";
import { Button, Modal } from "rsuite";
import { useCookies } from "react-cookie";
import API from "../../../../utils/api";
import { AnnotationSocket, IAnnoList, IPatchesAnnoDB } from "../../../viewer/annotation";
import { IGridSize } from "../../../viewer/viewer";
import { SinkColor } from "../../style/CustomNode";

export class AnnotationNode extends ClassicPreset.Node<
    {},
    { 'addon': AddonSocket },
    { 'ctrl': AnnotationControl }
> {
    color = SinkColor;

    constructor(process: () => void) {
        super('Annotation');
        this.addOutput('addon', new ClassicPreset.Output(new AddonSocket()));
        this.addControl('ctrl', new AnnotationControl(process));
    }
    data(): { addon: AnnotationSocket } {
        return {
            addon:
            {
                enable: true,
                saved: { ...this.controls.ctrl.props }
            }
        }
    }
}

export class AnnotationControl extends ClassicPreset.Control {
    props: {
        patches: IPatchesAnnoDB[];
        name: string;
        size: IGridSize;
    }
    constructor(public onChange: () => void) {
        super();
        this.props = {
            patches: [],
            name: '',
            size: 64
        }
    }

    setValue(patches: IPatchesAnnoDB[], name: string, size: IGridSize) {
        this.props.patches = patches;
        this.props.name = name;
        this.props.size = size;
    }
}

export function AnnotationComp({ data }: { data: AnnotationControl }) {
    const [cookies, setCookie] = useCookies(['refresh']);
    const api = new API(cookies);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [annoList, setAnnoList] = useState<IAnnoList[]>([]);

    const getAnnotations = async () => {
        try {
            const response = await api.get('/annotation/list');
            setAnnoList(response.data);
            console.log(response.data);
        }
        catch (err) {

        }
    }

    const getPatches = async (name: string, size: IGridSize) => {
        console.log('eee')
        try {
            const response = await api.get('/annotation/patches', { anno_seq: 55 });
            data.setValue(response.data, name, size);
            data.onChange();
        }
        catch (err) {

        }
    }

    const onClose = () => {
        setModalOpen(false);
    }

    useEffect(() => {
        if (modalOpen) {
            getAnnotations();
        }
    }, [modalOpen])

    return (
        <div
            onPointerDown={(e) => e.stopPropagation()}
            onDoubleClick={(e) => e.stopPropagation()}
        >
            <Button onClick={() => { setModalOpen(true); }}>load</Button>
            <Modal open={modalOpen} onClose={onClose}>
                {
                    annoList.map(data =>
                        <p onClick={() => { getPatches(data.name, data.size) }}>{data.name}</p>
                    )
                }
            </Modal>
        </div>
    )
}
