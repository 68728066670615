import React from 'react'

export default function MlopsSidePanelArrow({ 
  theme, 
  panelMode, 
  setPanelMode
}) {
  const handlePanelMode = () => {
    switch (panelMode) {
        case 'normal':
            setPanelMode('expand');
            break;
        case 'expand':
            setPanelMode('shrink');
            break;
        case 'shrink':
            setPanelMode('normal');
            break;
    }
  }

  return (
    <span className='side-panel-arrow'>
      <img
        src={process.env.PUBLIC_URL + theme === 'dark' 
        ? '/images/canvas-panel-arrow.svg' 
        : '/images/canvas-panel-arrow-white.svg'}
        alt=""
        onClick={handlePanelMode}
        style={{
          transform: panelMode === 'expand' ? 'rotate(180deg)' : '',
          animation: panelMode === 'shrink' ? `glow 2s infinite` : '',
          paddingRight: panelMode === 'expand' ? '9px' : '',
          paddingLeft: panelMode === 'normal' ? '5px' : '',
        }}
      />
    </span>
  )
}
