import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import File from './File';
import { IfileNdirList, filedirName } from '../../../../routes/datamanage/storage/storage.type';
import { useCookies } from 'react-cookie';
import API from '../../../../utils/api';
import { CustomThemeContext } from '../../../../utils/Context';

interface DirectoryProps {
name: string;
path: string;
depth: number;
selected?: {path: filedirName, name: filedirName, thumbnail: string };
setSelected: Dispatch<SetStateAction<{ path: filedirName, name: filedirName, thumbnail: string }>>;
}

export default function Directory({ name, path, depth, selected, setSelected }: DirectoryProps) {
    const [cookies, setCookie, removeCookie] = useCookies(['refresh']);
    const [listOpen, setListOpen] = useState<boolean>(false);
    const api = new API(cookies);
    const [fileNdirList, setFileNdirList] = useState<IfileNdirList[]>([]);
    const inputDir = `${path}/${name}`;
    const [bgColor, setBgColor] = useState<string>();
    const { theme } = useContext(CustomThemeContext);

    const handleListOpen = () => {
        setListOpen(!listOpen);
        setSelected({
            path: path,
            name: name,
            thumbnail: '',
        });
    };

    const getFileDirList = async () => {
        try {
            const response = await api.get('/cloud/list', { cur_dir: inputDir });
            setFileNdirList(response.data);
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        if (listOpen) getFileDirList();
    }, [listOpen]);

    useEffect(() => {
        let selectedPath = `${selected?.path}/${selected?.name}`; 

        if (selectedPath !== "" && selectedPath === inputDir) {
            setBgColor(theme === 'dark' ? '#181837' : '#E3e3F1' );
        } else {
            setBgColor('');
        }
    }, [theme, selected]);

    return (
        <li style={{ marginLeft: depth !== 0 ? 26 : 0, backgroundColor: bgColor }}>
            <div className="depth-directory" onClick={handleListOpen}>
                <img src={process.env.PUBLIC_URL + (listOpen ? '/images/datamanage-icon-arrow-open.svg' : '/images/datamanage-icon-arrow-close.svg')} alt="" />
                <div className="datamanage-file-name">
                    <img src={process.env.PUBLIC_URL + '/images/datamanage-icon-folder.svg'} alt="" />
                    <span>{name}</span>
                </div>
            </div>
            {
                listOpen &&
                <ul>
                    {fileNdirList.map(data => {
                        if (data.type === 'dataset' || data.type === 'dir' || data.type === 'model') return <Directory depth={depth + 1} name={data.name} path={inputDir} selected={selected} setSelected={setSelected} />
                        else return <File key={data.name} thumbnail={data.thumbnail} path={inputDir} depth={depth + 1} name={data.name} selected={selected} setSelected={setSelected} />
                    })}
                </ul>
            }
        </li>
    )
}
