import * as React from "react";
import { ClassicPreset } from "rete";
import styled from "styled-components";
import { editor } from "../../rete";
import { AddonColor, TNodeColor } from "./CustomNode";

const Styles = styled.div<{ color: TNodeColor }>`
  display: inline-block;
  cursor: pointer;
  z-index: 2;
  box-sizing: border-box;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px ${props => props.color.sub1} solid;
  background: ${props => props.color.sub2};
  transform: rotate(90deg);
`;

export function CustomSocketComp<T extends ClassicPreset.Socket>(props: {
  data: T;
  nodeId: string;
}) {
  const parentNode = editor.getNode(props.nodeId);
  return <Styles color={parentNode.color} title={props.data.name} />;
}

const StylesAddon = styled.div<{ color: string }>`
  display: inline-block;
  cursor: pointer;
  z-index: 2;
  box-sizing: border-box;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px ${AddonColor.sub1} solid;
  background: ${AddonColor.sub2};
  transform: rotate(90deg);
`;


export function AddonSocketComp<T extends ClassicPreset.Socket>(props: { data: T }) {
  return <StylesAddon color="white" title={props.data.name} />
}