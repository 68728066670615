import React, { useContext, useEffect, useState } from 'react'
import { styled } from 'styled-components';

import { TrainContext } from '../../../../../../context/TrainContext';
import { handleImgError } from '../../../../../../utils/function';

const Container = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #00000095;
  transition: all 1s;
  
  div.detail-img-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    padding: var(--24px);
    background-color: #131336;
    border-radius: var(--20px);

    h6 {
      font-size: var(--24px);
    }
    
    img.detail-img {
      margin-top: var(--24px);
      width: 70vw;
      height: 70vh;
    }

    div.img-wrapper {
      width: 70vw;
      height: 70vh;
      overflow: hidden;
    }
  }
`

export default function ImageItemModal () {
  const { openDetail, setOpenDetail } = useContext(TrainContext);

  // scale 조정
  const [scale, setScale] = useState(1);
  
  const imgStyle = {
    transform: `scale(${scale})`,
  };

  function handleWheel(e) {
    e.preventDefault();
    const delta = e.deltaY * -0.0005;
    // 스케일 업데이트, 최소값과 최대값 설정
    setScale(scale => Math.min(Math.max(.5, scale + delta), 10));
  }

  useEffect(() => {
    const imgEl = document.querySelector('div.detail-img-container') ?? document.body;
    imgEl.addEventListener('wheel', handleWheel, {passive: false});
    return () => {
      imgEl.removeEventListener('wheel', handleWheel)
    }
  }, [])

  return (
    <Container 
      onClick={() => {setOpenDetail(null)}}
    >
      <div className='detail-img-container' onClick={(e) => {e.stopPropagation();}}>
        <h6>{openDetail.label}</h6>
        <div className='img-wrapper'>
          <img 
            className='detail-img'
            src={`data:image/jpeg;base64,${openDetail.data}`} 
            alt=""
            style={imgStyle} 
            onError={handleImgError}
            />
        </div>
      </div>
    </Container>
  )
}