import React, { useContext, useEffect, useState } from 'react';

import Playground from '../../../../../routes/mlops/code-editor/Playground';
import { TrainContext } from '../../../../../context/TrainContext';


export default function DataConvertTab() {
  const [modelFlow, setModelFlow] = useState('');
  const [code, setCode] = useState('');

  const {
    trainerControl, 
    functions,
    trainData,
  } = useContext(TrainContext);

  const {
    getCode,
    postModelFlow,
  } = functions;

  // modelName으로 이미 저장된 코드가 있으면 불러오기
  useEffect(() => {
    const newModelFlow = JSON.stringify(trainerControl?.modelFlow, null, 2);
    if (trainerControl?.prevModelFlow !== newModelFlow) { // 다르면 새로 넣고,
      setModelFlow(newModelFlow);
      setCode('# Model flow changed');
    } else { // 같은데 있으면 이전 거 넣기
      setModelFlow(trainerControl?.prevModelFlow);
      getCode().then((data)=>{
        setCode(data);
      }).catch((err)=>{ // 같은데 없으면 아직 변환 하지 않음!
        setCode('# not Converted Yet')
      })
    }
    
    if (trainerControl) {
      trainerControl.prevModelFlow = JSON.stringify(trainerControl?.modelFlow, null, 2);
    }
  }, [trainerControl?.nodeId])

  useEffect(() => {
    setCode(trainData?.convertResult.code ?? '');
  }, [trainData?.convertResult.code])


  // converted code tab 들어갈때마다 model flow랑 code 업데이트 
  useEffect(() => {
    postModelFlow();
    const newModelFlow = JSON.stringify(trainerControl?.modelFlow, null, 2);
    setModelFlow(newModelFlow);
    getCode().then((data)=>{
      setCode(data);
    }).catch((err)=>{ // 같은데 없으면 아직 변환 하지 않음!
      setCode('# not Converted Yet')
    })

    if (trainerControl) {
      trainerControl.prevModelFlow = JSON.stringify(trainerControl?.modelFlow, null, 2);
    }
  }, [])

  return (
    <div>
      <h3 className='a11y-hidden'>data convert tab</h3>
      {/* <div>
        <h4>Model Flow</h4>
        <Playground code={modelFlow} height='30vh' readOnly={true}/>
      </div> */}

      <div>
        <h4>Model Code (Pytorch)</h4>
        <Playground code={code} height='60vh' readOnly={true}/>
      </div>
    </div>
  )
}
