import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

const ToggleLabel = styled.label`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ToggleBG = styled.div`
  display: inline-block;
  margin-top: 8px;
  background-color: #343452;
  width: 65px;
  height: 40px;
  padding: 4px;
  border-radius: 100px;
  transition: all 0.5s;
`

const ToggleBall = styled.div`
  display: inline-block;
  background-color: #c9bee1;
  box-shadow: 0 0 4px #000000;
  height: 100%;
  aspect-ratio: 1/1;
  border-radius: 100%;
  transform: translate(0);
  transition: all 0.5s;
`

export default function OptionInputToggle({ 
  id,
  label,
  initOption, 
  setValue, 
}) {

  const inputRef = useRef<HTMLInputElement>(null);
  const [toggleStyle, setToggleStyle] = useState({});
  const [toggleBGStyle, setToggleBGStlye] = useState({});
  
  const handleCheckboxChange = () => {
    if (inputRef.current?.checked) {
      setToggleStyle({
        "transform": "translateX(25px)",
      })
      setToggleBGStlye({
        "backgroundColor": "#7c55d1",
      })
    } else {
      setToggleStyle({
        "transform": `
        translateX(0px)
      `})
      setToggleBGStlye({
        "backgroundColor": "",
      })
    }
    setValue(inputRef.current?.checked)
  }

  useEffect(() => {
    if (inputRef.current) inputRef.current.checked = initOption.current[`${id}`];
    handleCheckboxChange();
  }, [initOption.current]);

  return (
    <div className='input-for-boolean'>
      <ToggleLabel htmlFor={`toggle-${id}`}>
        <p>{label}</p>
        <ToggleBG style={toggleBGStyle} className='tog'>
          <ToggleBall style={toggleStyle}/>
        </ToggleBG>
      </ToggleLabel>

      <input 
        ref={inputRef} 
        type="checkbox" 
        style={{display: "none"}} 
        id={`toggle-${id}`} 
        onChange={handleCheckboxChange}
      />
    </div>
  )
}
