import React from "react";
import { ClassicPreset, NodeId } from "rete";
import { CustomSocket } from "../../../sockets";
import { IDataflow } from "../../flow";
import { EffectorColor } from "../../../style/CustomNode";
import CustomControl from "../../../style/CustomControl";
import { updateControl } from "../../../../reteUtils";
import { NodeSize } from "../../../style/CustomNode";

export class DenoisingNode extends ClassicPreset.Node<
    { in: ClassicPreset.Socket },
    { out: ClassicPreset.Socket },
    { ctrl: ClassicPreset.Control }
> {
    color = EffectorColor;
    width = NodeSize.width;
    height = NodeSize.height;
    type = 'deeplearning';

    constructor(process: () => void, nodeId?: NodeId) {
        super('Denoising');
        this.addInput('in', new ClassicPreset.Input(new CustomSocket()));
        this.addOutput('out', new ClassicPreset.Output(new CustomSocket()));
        this.addControl('ctrl', new DenoisingControl(nodeId ? nodeId : this.id));
    }
    data(inputs: { in: IDataflow[] }): { out: IDataflow } {
        const value = inputs['in'][0];

        return {
            out: {
                img_paths: value.img_paths,
                effector: {
                    input: [value.effector],
                    node: {
                        denoising: ''
                    }
                }
            }
        }
    }
}

export class DenoisingControl extends ClassicPreset.Control {
    contextOpen: boolean;

    constructor(public nodeId: NodeId) {
        super();
        this.contextOpen = false;
    }

    setContextOpen = (open: boolean) => {
        this.contextOpen = open;
        updateControl(this.id);
    }
}

export function DenoisingComp({ data }: { data: DenoisingControl }) {
    return (
        <CustomControl contextOpen={data.contextOpen} setContextOpen={data.setContextOpen} label="Denoising" nodeId={data.nodeId} markerSource={'node-effector'} iconSource="denoising" />
    )
}