import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import ContextMenu from "../routes/datamanage/storage/ContextMenu";
import { filedirName, IfileNdirList } from "../routes/datamanage/storage/storage.type";
import { IModelList, TDeeplearningType } from "../rete/components/nodes/effector/deeplearning/deeplearning";
import { useAPIwithCookies } from "../hooks/useApiCookies";
import { StorageContext } from "./StorageContext";

export const ContextMenuContext = createContext<{
  setContext: (e: any, target: IfileNdirList | undefined) => void;
  setContextOpen: (b: boolean) => void;
}>({
  setContext: () => {},
  setContextOpen: (b: boolean) => {},
});

export default function ContextMenuContextProvider({ children }: { children: ReactNode }) {
  const api = useAPIwithCookies();
  const { curDir, getDirectory, selected, createDirectory } = useContext(StorageContext);
  
  //context menu
  const [contextOpen, setContextOpen] = useState<boolean>(false);
  const [contextEvent, setContextEvent] = useState<any>();
  const [contextTarget, setContextTarget] = useState<IfileNdirList | undefined>(undefined);

  const setContext = (e: any, target: IfileNdirList | undefined) => {
    setContextOpen(true);
    setContextEvent(e);
    setContextTarget(target);
  }

  const handleDataset = async (target: IfileNdirList) => {
    await api.post('/deep_learning/dataset/switch', {
        path: target.path,
        name: target.name
    })
  }

  const handleModel = async (target: IfileNdirList, node: TDeeplearningType) => {
    await api.post('/deep_learning/model/switch', {
        path: target.path,
        dmo_name: target.name,
        node_name: node,
    })
  }

  const handleWeight = async (target: IfileNdirList, dmo_seq: number) => {
    await api.post('/deep_learning/weight/switch', {
        path: target.path,
        wei_name: target.name,
        dmo_seq: dmo_seq
    })
  }

  const renameFileDir = async (target: filedirName) => {
    const newName = prompt(target);
    if (target === undefined) return;
    try {
        await api.post('/cloud/rename', { cur_dir: curDir, names: [target], change: newName })
    }
    catch (error: any) {
        if (error.detail === 'Conflict detected') {
            alert('이미 존재하는 이름입니다')
        }
    }
    finally {
        getDirectory();
    }
  }

  const deleteFileDir = async () => {
    if (!window.confirm("delete?")) return;
    try {
        await api.post('/cloud/delete', { cur_dir: curDir, names: selected });
    }
    catch (err) {
        console.log(err);
    }
    finally {
        getDirectory();
    }
  }

  const arrangeFileDir = async (mode: string) => {
    try {
        await api.post('/cloud/arrange', { arrange: mode });
    }
    catch (err) {
        console.log(err);
    }
    finally {
        getDirectory();
    }
  }

  const [modelList, setModelList] = useState<{ [key: string]: IModelList[] }>({});

  const getModelList = async () => {
    const response = await api.get('/deep_learning/model_list');
    setModelList(response.data);
  }
  
  // get Model list
  useEffect(() => {
    getModelList();
  }, [])


  const ContextMenufileNdir = () => {
    if (contextTarget === undefined) return null
    switch (contextTarget.type) {
      case 'dir':
      case 'dataset':
      case 'model':
        return (
          <ContextMenu
            event={contextEvent}
            containerId='storage-card-frame'
            onClose={setContextOpen}
            functions={[
              { label: 'Delete', on: deleteFileDir },
              { label: 'Rename', on: () => { renameFileDir(contextTarget.name) } },
              {
                label: 'Category', sub: [
                  { label: 'Add Dataset', on: () => { handleDataset(contextTarget) } },
                  {
                    label: 'Add Model', sub: [
                      { label: '2D Segmentation', on: () => { handleModel(contextTarget, 'segmentation_2d') } },
                      { label: '3D Segmentation', on: () => { handleModel(contextTarget, 'segmentation_3d') } },
                      { label: 'Classification', on: () => { handleModel(contextTarget, 'classification') } },
                      { label: 'Denoising', on: () => { handleModel(contextTarget, 'denoising') } }
                    ]
                  }
                ]
              }
            ]}
          />)
      case 'file':
      case 'weight':
        return (
          <ContextMenu
            event={contextEvent}
            containerId='storage-card-frame'
            onClose={setContextOpen}
            functions={[
              { label: 'Delete', on: deleteFileDir },
              { label: 'Rename', on: () => { renameFileDir(contextTarget.name) } },
              {
                label: 'Category', sub: [
                  {
                    label: 'Add Weight',
                    sub: [
                      {
                        label: '2D Segmentation', sub: modelList['segmentation_2d'].map(data => {
                          return ({ label: data.dmo_name, on: () => { handleWeight(contextTarget, data.dmo_seq) } })
                        })
                      },
                      {
                        label: '3D Segmentation', sub: modelList['segmentation_3d'].map(data => {
                          return ({ label: data.dmo_name, on: () => { handleWeight(contextTarget, data.dmo_seq) } })
                        })
                      },
                      {
                        label: 'Classification', sub: modelList['classification'].map(data => {
                          return ({ label: data.dmo_name, on: () => { handleWeight(contextTarget, data.dmo_seq) } })
                        })
                      },
                      {
                        label: 'Denoising', sub: modelList['denoising'].map(data => {
                          return ({ label: data.dmo_name, on: () => { handleWeight(contextTarget, data.dmo_seq) } })
                        })
                      }
                    ],
                  }
                ]
              }
            ]}
          />
        )
      default:
        return (
          <ContextMenu
            event={contextEvent}
            containerId='storage-card-frame'
            onClose={setContextOpen}
            functions={[
              { label: 'Delete', on: deleteFileDir },
              { label: 'Rename', on: () => { renameFileDir(contextTarget.name) } }
            ]}
          />
        )
    }
  }

  useEffect(() => {
    console.log(contextOpen, contextTarget)
  }, [contextOpen, contextTarget])
  
  return (
    <div 
      onContextMenu={(e) => { setContext(e, undefined) }}
      onClick={(e) => {
        setContextOpen(false)
      }}
    >
      <ContextMenuContext.Provider 
        value={{
          setContext,
          setContextOpen
        }}
      >
        {children}
      </ContextMenuContext.Provider>
      {
      contextOpen &&  
        (
        contextTarget === undefined
        ?
        <ContextMenu
          event={contextEvent}
          containerId='storage-card-frame'
          onClose={setContextOpen}
          functions={[
            { label: 'Make Directory', on: createDirectory },
            {
              label: 'Sort By',
              sub: [
                { label: 'name_asc', on: () => { arrangeFileDir('name_asc') } },
                { label: 'name_desc', on: () => { arrangeFileDir('name_desc') } },
                // { label: 'type_asc', on: () => { arrangeFileDir('type_asc') } },
                // { label: 'type_desc', on: () => { arrangeFileDir('type_desc') } },
              ]
            }
          ]} 
        />
        : 
        <ContextMenufileNdir />
        )
      }
    </div>
  )
}