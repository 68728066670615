import React, { ReactNode, createContext, useState } from "react";

export type SmcModal = {
  [k in string]: string;
}

export const initSmcContext = {
  smcModal: false,
  setSmcModal: (a: boolean) => {},
};

type SmcContextValue = typeof initSmcContext;

export const SmcContext = createContext<SmcContextValue>(initSmcContext);

function SmcContxtProvider({children}: { children: ReactNode }) {
  const [smcModal, setSmcModal] = useState(false);

  return (
    <SmcContext.Provider value={{smcModal, setSmcModal}}>
        {children}
    </SmcContext.Provider>
);
}

export default SmcContxtProvider