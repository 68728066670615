import './ContextMenu.scss';

import React, { SetStateAction, Dispatch, useState } from 'react';

export interface IContextFnc {
    label: string;
    on?: () => void;
    sub?: IContextFnc[];
}

interface ContextMenuProps {
    event: MouseEvent;
    functions: IContextFnc[];
    onClose: Dispatch<SetStateAction<boolean>>;
    containerId: string;
}

export default function ContextMenu({ event, functions, onClose, containerId }: ContextMenuProps) {
    if (!event) return;
    const { clientX, clientY } = event;
    const gridDOM = document.getElementById(containerId);
    const gridDOM_pos = gridDOM?.getBoundingClientRect();
    const scrollTop = gridDOM?.scrollTop ? gridDOM.scrollTop : 0;

    if (gridDOM_pos) {
        return (
            <div
                className='context-menu-container' 
                style={{ top: clientY - gridDOM_pos.y + scrollTop, left: clientX - gridDOM_pos.x }}
            >
                {
                    functions.map((data, index) =>
                        <ContextItem index={index} sub={data.sub} data={data} onClose={onClose} />
                    )
                }
            </div>
        )
    }
    else return null
}

const ContextItem = ({ 
    sub, 
    data, 
    onClose, 
    index 
}: { 
    data: IContextFnc, 
    onClose: Dispatch<SetStateAction<boolean>>, 
    sub?: IContextFnc[], 
    index: number 
}) => {
    const [subOpen, setSubOpen] = useState<boolean>(false);
    return (
        <p 
            className='context-menu' 
            onMouseDown={(e) => { e.stopPropagation(); }} 
            onMouseUp={(e) => { e.stopPropagation(); }} 
            onClick={(e) => {
                e.stopPropagation();
                if (sub) {
                    setSubOpen(true);
                }
                else {
                    if (!data.on) return;
                    data.on(); 
                    onClose(false);
                }
            }}
        >
            {data.label}
            {
                subOpen 
                && 
                <div 
                    style={{ top: index * 40 }} 
                    className='context-menu-subcontext-container'
                >
                    {
                        sub 
                        && 
                        sub.map((data, index) => 
                        <ContextItem 
                            index={index} 
                            sub={data.sub} 
                            data={data} 
                            onClose={onClose} 
                        />
                        )
                    }
                </div>
            }
        </p>
    )
}