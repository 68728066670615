
const Recruit = () => {

  return (
    <div>
      <img style={{display:"flex", width: "60%", height: "auto", margin:"auto"}} src="바이언스 채용 공고 - 240702.png" alt="바이언스 2024 채용공고" />
    </div>
  );
};

export default Recruit;
