import React, { CSSProperties, ChangeEvent } from 'react';

interface SelectBoxProps {
    options: { id: string, name: any }[];
    optionStyle?: CSSProperties;
    containerStyle?: CSSProperties;
    children: string;
    onChange: (e: any) => void
}

export default function SelectBox({ options, optionStyle, containerStyle, children, onChange }: SelectBoxProps) {
    return (
        <div style={containerStyle}>
            <select onChange={(e) => { onChange(e.target.value as any) }} style={{ border: '0', width: '100%' }}>
                <option value="" disabled selected>{children}</option>
                {options.map(data => <option style={optionStyle} key={data.id} value={data.name}>{data.name}</option>)}
            </select>
        </div>
    )
}
