import React from 'react'
import styled from 'styled-components'

const CodeCheckFloatingButton = styled.button.attrs(() => ({ type: 'button' }))`
  display: block;

  width: var(--90px);
  aspect-ratio: 1/1;
  border-radius: 100%;
  background-color: #b47ef7;

  position: fixed;
  top: 85vh;
  right: 2vw;
  z-index: 10;

  box-shadow: var(--4px) var(--4px) var(--8px) #00000025;

  div.circle-outline {
    width: var(--90px);
    aspect-ratio: 1/1;
    border-radius: 100%;
    
    outline: var(--4px) dashed #b47ef7;
    outline-offset: var(--4px);

    position: absolute;
    top: 0;
    left: 0;

    box-shadow: var(--4px) var(--4px) var(--8px) #00000025;

    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      } 100% {
        transform: rotate(360deg);
      }
    }

    animation: rotate 4s linear infinite alternate;
  }
`

export default function CodeCheckButton({errorChecking, errorCheck}) {
  
  return (
    <CodeCheckFloatingButton 
      onClick={errorCheck}
    >
      {errorChecking &&<div className='circle-outline'></div>}
      <strong>Code Check</strong>
    </CodeCheckFloatingButton>
  )
}
