import { dirType } from "./storage.type";

export const uploadChunkFile = (file: File, path: dirType, promises: Promise<any>[], api, callback?: (res?: any) => void, isSmc=false) => {
    //CHUNK_SIZE 10Mb
    const CHUNK_SIZE = 10 * 1024 * 1024;

    // total Chunks 전체 파일 사이즈를 지정한 한 덩어리의 사이즈인 10mb 로 나눈 값
    const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
    
    // 전체 파일을 10mb로 몇 번 곱해야 되는 지
    for (let idx = 0; idx < totalChunks; idx++) {
        // n번 실행된다고하면, n번째 업로드에는
        // n * CHUNK_SIZE 부터 n * CHUNK_SIZE + CHUNK_SIZE 까지
        const start = idx * CHUNK_SIZE;
        const end = Math.min(file.size, start + CHUNK_SIZE);
        
        // file을 잘라서 chunk에 담아.
        const chunk = file.slice(start, end);
    
        // 새로운 formdata임
        // 들어가는 data : file이름, 경로, 잘린 file, 어디까지 잘랐는지, idx / total
        const formData = new FormData();
        formData.append('filename', file.name);
        formData.append('path', path);
        formData.append('upfile', chunk);
        formData.append('idx', `${idx}`);
        formData.append('total', `${totalChunks}`);
    
        // post 메소드로 '/cloud/chunk' url로 formdata를 담아, 
        // Content-Type (리퀘스트 헤더) : mlutipart/form-data 정보도 담아 전송
        const url = isSmc ? '/smc/chunk' : '/cloud/chunk';
        const response = api.post(url, formData, {
            "Content-Type": "multipart/form-data",
        }).then(res => {
            // 응답이 오면 res 정보를 가지고 setProgress 함수 실행
            res.filename = 'Data Uploading: ' + res.filename
            if (callback) callback(res);
        })
    
        // promises에 api.post를 실행시킨 Promise객체들을 넣는다.
        // 답변이 오도록 기다리지 않고 배열에 다 넣어놓기!
        promises.push(response);
    }
}