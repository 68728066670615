import React from 'react';
import './CustomComp.scss';

interface CustomSelectProps extends React.InputHTMLAttributes<HTMLSelectElement> {
    label: string;
    options: string[];
    keyword?: string;
    onChange?: (e) => void;
}

export default function CustomSelect({ label, options, keyword, onChange, ...rest }: CustomSelectProps) {
    return (
        <div className='custom-select'>
            <p>{label}</p>
            <select onChange={(e) => onChange && onChange(e.target.value)} style={{ width: '100%' }} {...rest}>
                {options.map(data => <option key={data.toString()}>{keyword ? data[keyword] : data}</option>)}
            </select>
        </div>
    )
}
