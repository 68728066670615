import "./home.styles.scss";
import AOS from "aos";
import "aos/dist/aos.css";

import React,{useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { LandingHeader } from "../../components/landingHeader/LandingHeader";
import { LineGrid } from "../../components/lineGrid/LineGrid";
import { LandingFooter } from "../../components/landingFooter/LandingFooter";
import { useAuthContext } from '../../hooks/useAuthContext';

import { useAPIwithCookies } from "../../hooks/useApiCookies";

export default function Home() {
    const navigate = useNavigate();
    const landingPage = useRef(null);
    const [isHoveringMenu1, setIsHoveringMenu1] = useState(0);
    let toggleClassOnPlay = isHoveringMenu1 ? ' on':'';
    const { user } = useAuthContext();

    const api = useAPIwithCookies();
    const [isSmc, setIsSmc] = useState(false);
    const check_account = async () => {
      const response = await api.get('/smc_proofreading/check_account/'+user?.email);
      if (response.data === 1) {
        setIsSmc(true);
      }
      else{
        setIsSmc(false);
      }
    }
    useEffect(() => {
      check_account();
    }, [user])


    useEffect(() => {
        AOS.init({ duration: 1500 });
    },[]);

    const videoRef = useRef(null);
    return (
        <div className="home-background" ref={landingPage}>
            <div className="video-bg-frame" >
                <video preload="none" muted autoPlay loop>
                    <source src={process.env.PUBLIC_URL + '/test03.mp4' } type="video/mp4" />
                </video>
                <div className="mask"></div>
            </div>

            <LineGrid />
            <div className="frame">
                <LandingHeader />
            </div>

            <main>
                <section>
                    <p data-aos="fade-up">Visual Programming-based<br />Visual Analytics Platform for Biomedical Data</p>
                    <div className="button-frame" data-aos="fade-up" data-aos-delay="400">
                        <button type="button">Try tutorial</button>
                        <button 
                            onClick={() => {
                                 if(isSmc){
                                    navigate('/vience-canvas/smc') 
                                }
                                 else{
                                     navigate('/vience-canvas/workspace') 
                                 }
                            }} 
                            type="button">
                                Get Started
                        </button>
                    </div>
                    <div className="video-frame">
                        <div className="inner">
                            <video muted loop autoPlay 
                                poster={process.env.PUBLIC_URL + '/images/mainVideo.png'} 
                                controls       
                                ref={videoRef}
                                className="video"
                                onCanPlayThrough={() => {
                                    // Do stuff
                                }}
                                onMouseOver={() => setIsHoveringMenu1(1)}
                                onMouseOut={() => setIsHoveringMenu1(0)}
                                >
                                <source src={process.env.PUBLIC_URL + '/canvas-demo4.mp4' } type="video/mp4" />
                            </video>
                        </div>

                        <button className={`icoPlay ${toggleClassOnPlay }`} 
                            onMouseOver={() => setIsHoveringMenu1(1)}
                            onMouseOut={() => setIsHoveringMenu1(0)}
                        >
                            {/* <img src={process.env.PUBLIC_URL + '/images/ico-videoPlay.svg'} alt="" className="icoPlay"/> */}
                        </button>
                    </div>
                </section>
                <section>
                    <div className="contentTop">
                        <div className="inner">
                            <div className="frame">
                                <div className="left" data-aos="zoom-in-left">
                                    <strong>
                                        Cloud-Based <br />
                                        Large Data <br />
                                        Managemnet
                                    </strong>
                                    <span>
                                        Cloud-Based Large Data Management and <br /> Sharing for Efficient Collaborative Research <br /> and Cost Savings
                                    </span>
                                </div>
                                <div className="right" data-aos="zoom-in-right" data-aos-delay="400">
                                    <div className="imgFrame01">
                                        <img src={process.env.PUBLIC_URL + '/images/bg-cloudbased01.png'} alt="" className="" />
                                    </div>
                                    <div className="imgFrame02">
                                        <img src={process.env.PUBLIC_URL + '/images/bg-cloudbased02.png'} alt="" className="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="contentMiddle">
                        <div className="inner">
                            <div className="frame">
                                <div className="top" data-aos="fade-up"  data-aos-delay="400"> 
                                    <strong>
                                        No Code <br />
                                        Programming
                                    </strong>
                                    <span>
                                        No-Code Programming Enabling Solution Creation <br /> through Simple Web-Based Interactions
                                    </span>
                                </div>
                                <div className="bottom" data-aos="fade-up" data-aos-delay="400">
                                    <div className="imgFrame01">
                                        <img src={process.env.PUBLIC_URL + '/images/bg-nocode.png'} alt="" className="" />
                                        <div className="bg"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="contentBottom">
                        <div className="inner">
                            <div className="frame">
                                <div className="left" data-aos="zoom-in-left"  data-aos-delay="400">
                                    <div className="imgFrame01">
                                        <img src={process.env.PUBLIC_URL + '/images/bg-advanced01.png'} alt="" className="" />
                                        <div className="bg"></div>
                                    </div>
                                    <div className="imgFrame02">
                                        <img src={process.env.PUBLIC_URL + '/images/bg-advanced02.png'} alt="" className="" />
                                        <div className="bg"></div>
                                    </div>
                                </div>
                                <div className="right" data-aos="zoom-in-right" data-aos-delay="900">
                                    <strong>
                                        Advanced Deep <br />
                                        Learning & Visualizations
                                    </strong>
                                    <span>
                                        Providing the Latest Deep Learning,<br /> Visualization, and Parallel Computing<br /> Modules through an App Market
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <div data-aos="fade-up"  data-aos-delay="400">
                <LandingFooter />
            </div>
        </div>
    );
};
