export const mockRecommendedModel = {
  category: "",
  dataset_path: "",
  title: "",
  description: {},
  thumbnail: "",
  model_path: "",
  trainer_type: "",
  epoch: "",
  learning_rate: "",
};

export type RecommendedModel =  typeof mockRecommendedModel;

export type ReqBody = {
  "key": string;
  "model_path": string;
  "weight_name": string;
  "dataset_path": string;
  "options": {
    trainer_type: string;
    epoch: string;
    learning_rate: string;
    batch_size: string;
    loss_function: string;
    optimizer: string;
  }
}

// utils
export function getSelectedPath(givenKey: string | undefined, listName: {key: string[], path: string[]} | undefined) {
  const selectedIndex = listName?.key.findIndex((key) => key === givenKey);
  const selectedPath =  listName?.path[selectedIndex ?? ''];
  return selectedPath;
}

export function getSelectedKey(givenPath: string | undefined, listPath: {key: string[], path: string[]} | undefined) {
  const selectedIndex = listPath?.path.findIndex((key) => key === givenPath);
  const selectedKey =  listPath?.key[selectedIndex ?? ''];
  return selectedKey;
} 
