import React, { ReactNode, createContext, useEffect, useReducer, useState } from 'react';

import { useAPIwithCookies } from '../hooks/useApiCookies';
import { RecommendedModel, mockRecommendedModel } from './trainUtils';

// type 
export const mockWorkspaceList = {
  category: "",
  created_at: null,
  description: "",
  edit_yn: false,
  mem_id: "",
  nodes: "",
  title: "",
  updated_at: "",
  wor_id: "",
};

export type WorkspaceList = typeof mockWorkspaceList;

export const WorkspaceContext = createContext<{ 
  functions: {
    // workspace
    createNewWorkspace?: any,
    getWorkspaceList?: any,
    getWorkspaceItem?: any,
    saveWorkspace?: any,
    // code
    getModelFlowFromCode?: any,
    updateCode?: any,
    chageTitle?: any,
    creaetNewFile?: any,
  };
  dispatch?: any;
  recommendModelList?: RecommendedModel[];
  // template 정보 전달
  selectedWorkspace?: RecommendedModel; 
  setSelectedWorkspace?: any;
}>({
  functions: {},
});

const workspaceReducer = (state, action) => {
  switch (action.type) {
      case 'loading':
          return {...state, recommendModelList: null, loading: true};
      case "error":
          return {...state, recommendModelList: null};
      case "fetch":
          return {...state, recommendModelList: action.payload};
      default:
          return state;
  }
}

export function WorkspaceContextProvider({ children }: { children: ReactNode }) {
  const api = useAPIwithCookies();

  const [state, dispatch] = useReducer(workspaceReducer, {
      recommendModelList: null,
      loading: false
  });

  useEffect(() => {
    if (state.recommendModelList) return;
    
    api.get(`/mlops/all_recommendation_models`).then((res) => {
      const data = res.data; 
      const combined = Object.values(data);
      dispatch({type: 'fetch', payload: combined})
    });
  }, [])

  // workspace CRUD
  const createNewWorkspace = async () => {
    const { data } = await api.post(`/mlops_workspace/new`);
    return data;
  }

  const getWorkspaceList = async (category: WorkspaceList) => {
    const { data } = await api.get(`/mlops_workspace/list/${category}`);
    return data;
  }

  const getWorkspaceItem = async (wor_id: string) => {
    const { data } = await api.get(`/mlops_workspace/${wor_id}`);
    return data;
  }

  const saveWorkspace = async (jsonString: WorkspaceList) => {
    await api.put(`/mlops_workspace/save`, jsonString);
  }


  // 코드에디터 파일 CRUD 관련
  const getModelFlowFromCode = async ( newModelPath: string ) => {
    const { data } = await api.get(`/mlops/model_flow_from_code/${newModelPath}`);
    return data;
  }

  const updateCode = async ( modelPath: string, code: string ) => {
    const { data } = await api.post(`/mlops/update_code/`, { // 기존 경로에 내용 수정 저장,
      "path": modelPath,
      "code": code,
    });
    return data;
  }

  const chageTitle = async ( modelPath: string, prevTitle: string, newTitle: string ) => {
    await api.post(`/mlops/change_code_title/${prevTitle}/to/${newTitle}`); 
    const newModelPath = modelPath.replace(prevTitle, newTitle);
    return newModelPath;
  }

  const creaetNewFile = async ( title: string, code: string ) => {
    const {data} = await api.post(`/mlops/new_code/${title}`, {
      "code": code
    });
    const newModelPath = data;

    if(newModelPath==='already exists'){
      alert(`fail: '${title}' already exists`);
    }

    alert(`create new python file in '${newModelPath}'`);

    return newModelPath;
  }

  // 모음
  const functions = {
    getWorkspaceList,
    createNewWorkspace,
    getModelFlowFromCode,
    updateCode,
    chageTitle,
    creaetNewFile,
    saveWorkspace,
    getWorkspaceItem,
  }

  // template workspace 정보
  const [selectedWorkspace, setSelectedWorkspace] = useState<RecommendedModel>(mockRecommendedModel);


  return (
    <WorkspaceContext.Provider value={{ 
      functions, 
      ...state, 
      dispatch,
      selectedWorkspace, setSelectedWorkspace,
      }} >
      {children}
    </WorkspaceContext.Provider>
  )
}
