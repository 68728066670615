
const IntroVideo = () => {

  return (
    <div>
      <iframe type="text/html" src="http://117.52.72.211:3303/sub/about/intro.php" style={{width: "100%", height: "99vh"}}>
      </iframe>
    </div>
  );
};

export default IntroVideo;
