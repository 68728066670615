import React, { useState, useEffect, useRef, ReactNode } from 'react';
import { useCookies } from 'react-cookie';
import API from '../../../../utils/api';
import { ClassicPreset } from 'rete';
import { AddonColor, NodeSize } from '../../style/CustomNode';
import { NodeId } from 'rete';
import { AddonSocket } from '../../sockets';
import CustomControl from '../../style/CustomControl';
import { updateControl } from '../../../reteUtils';
import CustomSelect from '../../../../common/CustomSelect';

import './Feature.scss';

type TFeatureProps = {
    layer: string;
    visualization: string[];
}

export class FeatureNode extends ClassicPreset.Node
    <
        {},
        { addon: ClassicPreset.Socket },
        { ctrl: FeatureControl }
    >
{
    color = AddonColor;
    width = NodeSize.width;
    height = NodeSize.height;
    // nodeTheme = 'dark';

    constructor(process: () => void, nodeId?: NodeId) {
        super('Feature');
        this.addOutput('addon', new ClassicPreset.Input(new AddonSocket()));
        this.addControl('ctrl', new FeatureControl(process, nodeId ? nodeId : this.id));
    }
    data(): { addon: { name: string, option: TFeatureProps } } {

        console.log('feature output => ', this.controls.ctrl.props.option);
        return ({
            addon: {
                name: 'feature',
                option: {
                    layer: this.controls.ctrl.props.option.layer,
                    visualization: this.controls.ctrl.props.option.visualization
                }
            }
        })
    }
    // setTheme(theme: 'dark' | 'light') {
    //     this.nodeTheme = theme;
    //     updateNode(this.id);
    // }
}

export class FeatureControl extends ClassicPreset.Control {
    props: {
        option: TFeatureProps;
    }
    contextOpen: boolean;
    // theme: 'dark' | 'light';
    type: string;

    constructor(public onChange: () => void, public nodeId: NodeId) {
        super();
        this.props = {
            option: {
                layer: '',
                visualization: [],
            }
        }
        this.contextOpen = false;
        this.type = 'FeatureControl';
        // this.theme = 'dark';
    }

    setValue({ layer, visualization }: TFeatureProps) {
        this.props.option = {
            layer: layer,
            visualization: visualization
        }
        this.onChange();
    }

    setContextOpen = (open: boolean) => {
        this.contextOpen = open;
        updateControl(this.id);
    }
    // setTheme = (theme: 'dark' | 'light') => {
    //     this.theme = theme;
    //     updateControl(this.id);
    // }
}

export function FeatureComp({ data }: { data: FeatureControl }) {
    return (
        <CustomControl contextOpen={data.contextOpen} setContextOpen={data.setContextOpen} label="Feature" nodeId={data.nodeId} markerSource={'node-addon'} iconSource="feature" />
    )
}
interface FeaturePanelProps {
    ctrl: FeatureControl;
}



export const FeaturePanel = ({ ctrl }: FeaturePanelProps) => {
    const [cookies, setCookie] = useCookies(['refresh']);
    const api = new API(cookies);

    const [visualization, setVisualization] = useState<string[]>(['2D Scatter Plot', 'Parallel Coordinate Plot']);
    const plotTypes = ['parallel', 'scatter'];

    useEffect(() => {
        ctrl.setValue({
            layer: 'features',
            visualization: visualization,
        })
    }, [visualization])


    // const saveGear = () => {
    //     ctrl.setValue({ ...gearOption, save: true });
    // }

    // const getGearList = async () => {
    //     const response = await api.get('/Feature/list');
    //     setGearList(response.data);
    // }

    // useEffect(() => {
    //     setGearOption(ctrl.props.option);
    // }, [])

    // useEffect(() => {
    //     getGearList();
    // }, [])


    return (
        <>
            <div className="side-panel-normal">
                <div>
                    <p>Feature</p>
                    <div>
                        <CustomSelect options={['features']} label='Layer' onChange={(e) => { console.log(e) }} />
                        <div id='visualization-list'>
                            <CustomSelect options={['2D Scatter Plot', 'Parallel Coordinate Plot']} label='Visualization' value={visualization[0]} />
                            <CustomSelect options={['Parallel Coordinate Plot', '2D Scatter Plot']} label='' value={visualization[1]} />
                        </div>
                    </div>
                </div>
            </div>

            {/* Feature 삭제 추가 필요 */}
            <div className="side-panel-expand">
                <div id='feature-container'>
                    {plotTypes.map(data =>
                        <div className="feature-card">
                            <img src={process.env.PUBLIC_URL + `/images/feature/plot_${data}.png`} />
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}