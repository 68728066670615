import { NodeEditor, NodeId } from "rete";
import { Schemes, area, editor, engine } from "./rete";

import { NodeTypes } from "./rete";
import { DataManageNode } from "./components/nodes/source/DataManagement";
import { ImageCropNode } from "./components/nodes/effector/ImageCrop";
import { EdgeDetectNode } from "./components/nodes/effector/EdgeDectection";
import { DenoisingNode } from "./components/nodes/effector/deeplearning/Denoising";
import { ViewerNode } from "./components/nodes/sink/Viewer";
import { BlenderNode } from "./components/nodes/effector/Blender";
import { GearNode } from "./components/nodes/addon/Gear";


export function getConnectionSockets(
    editor: NodeEditor<Schemes>,
    connection: Schemes["Connection"]
) {
    const source = editor.getNode(connection.source);
    const target = editor.getNode(connection.target);

    const output =
        source &&
        (source.outputs as Record<string, any>)[connection.sourceOutput];
    const input =
        target && (target.inputs as Record<string, any>)[connection.targetInput];

    return {
        source: output?.socket,
        target: input?.socket
    };
}

//각 Node의 data() 실행
export const process = async () => {
    engine.reset();

    await editor
        .getNodes()
        .filter((n) => engine.fetch(n.id));
}

// Control update
export const updateControl = async (controlId: string) => {
    await area.update('control', controlId);
}

// Node update, Node update시 control도 자동으로 update 되는 것으로 추측
export const updateNode = async (nodeId: NodeId) => {
    await area.update('node', nodeId);
}

// Node가 가지고 있는 모든 ContextMenu 닫기
export const closeAllContext = async () => {
    await editor
        .getNodes()
        .filter((n) => {
            // @ts-ignore
            n.controls.ctrl?.setContextOpen(false);

        });
}

// Node 삭제, Conn제거 => Node 제거
export const deleteNode = async (nodeId: NodeId) => {
    await removeConnection(nodeId);
    await editor.removeNode(nodeId);
}

// target Node에 연결된 모든 Conn 제거
const removeConnection = async (nodeId: NodeId) => {
    const connections = editor.getConnections();
    for (let connection of connections) {
        if (connection.source === nodeId || connection.target === nodeId) {
            await editor.removeConnection(connection.id);
        }
    }
}

// target Node 복사
export const copyNode = async (nodeId: NodeId) => {
    const targetNode = editor.getNode(nodeId);
    const newNode = createNode(targetNode);

    if (newNode) {
        await editor.addNode(newNode);
    }
}

// target Node 복사 생성
const createNode = (node: any): NodeTypes | undefined => {
    let newNode;
    if ('ctrl' in node.controls) {
        const ctrl = node.controls['ctrl'];
        if (node.label === 'DataManage') {
            newNode = new DataManageNode(process);
            newNode.controls.ctrl.setValue(ctrl.props.option);
        }
        else if (node.label === 'ImageCrop') {
            newNode = new ImageCropNode(process);
            newNode.controls.ctrl.setValue(ctrl.props.option)
        }
        else if (node.label === 'Edge Detection') {
            newNode = new EdgeDetectNode(process);
            newNode.controls.ctrl.setValue(ctrl.props.option)
        }
        else if (node.label === 'Denoising') {
            newNode = new DenoisingNode(process);
        }
        else if (node.label === 'Viewer') {
            newNode = new ViewerNode(node.id);
            newNode.controls.ctrl.setValue(ctrl.props.option);
        }
        else if (node.label === 'Blender') {
            newNode = new BlenderNode(process);
            newNode.controls.ctrl.setValue(ctrl.props.option)
        }
        else if (node.label === 'Gear') {
            newNode = new GearNode(process);
            newNode.controls.ctrl.setValue(ctrl.props.option);
        }
    }
    else {
    };

    return newNode;
}
