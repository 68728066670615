// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blender-section {
  width: 100%;
  height: 45%;
  display: flex;
  flex-direction: column;
}
.blender-section > p {
  color: #c5bfcd;
  padding-bottom: var(--20px);
}

.blender-handle {
  flex: 1 1;
  margin-bottom: var(--10px);
  padding: var(--15px) 0;
  border: 1px #1B1B47 solid;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: var(--10px);
}
.blender-handle p {
  color: #c5bfcd;
}

#blender-handle {
  width: 80%;
}

.blender-image {
  align-self: center;
}

body.light .blender-section > p {
  color: #38373a;
}
body.light .blender-handle p {
  color: #38373a;
}
body.light .blender-handle {
  border: 1px #babac0 solid;
}`, "",{"version":3,"sources":["webpack://./src/rete/components/nodes/effector/Blender.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;AACJ;AAAI;EACI,cAAA;EACA,2BAAA;AAER;;AAEA;EACI,SAAA;EACA,0BAAA;EACA,sBAAA;EACA,yBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,0BAAA;AACJ;AAAI;EACI,cAAA;AAER;;AAEA;EACI,UAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAIQ;EACI,cAAA;AADZ;AAGQ;EACI,cAAA;AADZ;AAGQ;EACI,yBAAA;AADZ","sourcesContent":[".blender-section {\n    width: 100%;\n    height: 45%;\n    display: flex;\n    flex-direction: column;\n    > p {\n        color: #c5bfcd;\n        padding-bottom: var(--20px);\n    }\n}\n\n.blender-handle {\n    flex: 1;\n    margin-bottom: var(--10px);\n    padding: var(--15px) 0;\n    border: 1px #1B1B47 solid;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    border-radius: var(--10px);\n    p {\n        color: #c5bfcd;\n    }\n}\n\n#blender-handle {\n    width: 80%;\n}\n\n.blender-image {\n    align-self: center;\n}\n\nbody {\n    &.light {      \n        .blender-section > p {\n            color: #38373a;\n        }\n        .blender-handle p {\n            color: #38373a;\n        }\n        .blender-handle {\n            border: 1px #babac0 solid;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
