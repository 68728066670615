import { useNavigate } from "react-router-dom";
// import "./../routes.styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { faCloud } from "@fortawesome/free-solid-svg-icons";
import { Modal, Button, ButtonToolbar, Placeholder, IconButton } from 'rsuite';
import { useEffect, useState } from "react";
import CheckOutline from '@rsuite/icons/CheckOutline';


const CellView = () => {

  return (
    <div>
      <h1 id="main-title" style={{display:"none"}}>
        CellView: A Visual Programming and Workflow Management Framework for Cell Analytics 
      </h1>
      <h2 id="keyword" style={{display:"none"}}>
      CellView
      </h2>

      <iframe type="text/html" src="https://vience.io:8030/" style={{width: "99.7%", height: "99vh"}}>
      </iframe>
    </div>
  );
};

export default CellView;
