import React, { useContext, useEffect, useRef, useState } from 'react';

import FineTunerOptionSection from './FineTunerOptionSection';

import { FineTunerControl } from '../../nodes/FineTunerNode';

import { ILayerOption } from '../../flow';
import styled from 'styled-components';
import TrainResultTab from '../Viewer/train-result/TrainResultTab';
import FineTunerCorrectionTab from './FineTunerCorrectionTab';
import { CustomThemeContext } from '../../../../utils/Context';
import { TrainContext } from '../../../../context/TrainContext';
import { useLocation } from 'react-router-dom';
import EmptyDiv from '../../../../routes/mlops/train-detail/EmptyDiv';

const Container = styled.div`
  button[class^=fine-tuner],
  button[class^=trainer] {
    width: 100%;
    padding: var(--12px);
    margin-top: var(--12px);
    margin-bottom: var(--48px);
    border-radius: 8px;

    background-color: ${(props) => props.$theme === 'light' ? "#fff" : "#181839"};
    font-weight: 700;
    transition: all 0.2s;
    
    &:disabled {
      ${({$theme})=> $theme === 'light' ? `
        color: #7b7b7b;
        background-color: #c1c1c1 ;
        ` : `
        color: #525252;
        background-color:#232335;
      `};
    }
  }

  button.start-btn {
    background-color: #b472f7;
    color: white;
  }  

  header {
    display: flex;
    gap: 10px;
    padding: 0;
    ${(props) => props.$theme === 'light' ? `
      border: 2px solid #D9D9E5;
    ` : `
      border: 2px solid #1B1B47;
    `};

    button.fine-tuner-tab-header-btn {
      background-color: transparent;
      margin: 0;
      transition: all 0.4s;
    }

    button.fine-tuner-tab-header-btn.active {
      ${(props) => props.$theme === 'light' ? `
      background-color: #D9D9E5;
      ` : `
      background-color: #1B1B47;
      `};
      color: #b472f7;
      border-radius: 0;
    }
  }
`

export function FineTunerPanelContent({ ctrl, setPanelMode }: {
  ctrl: FineTunerControl;
  setPanelMode?: any;
}) {
  const [loading, setLoading] = useState(true);
  const initOption = useRef<ILayerOption>({});
  const [option, setOption] = useState<ILayerOption>({});
  const [selectedTab, setSelectedTab] = useState('correction');
  const { setTrainerControl } = useContext(TrainContext);

  const { theme } = useContext(CustomThemeContext);
  
  // 선택된 ctrl.id 바뀔때마다 ctrl에 저장된 옵션으로 바꾸기
  useEffect(() => {
    setLoading(true);

    setOption(ctrl.props.option);
    initOption.current = ctrl.props.option;
    setTrainerControl(ctrl);

    setLoading(false);
  }, [ctrl.nodeId]);

  // 옵션이 바뀌면 ctrl에 저장
  useEffect(()=>{
    if (!loading) {
      ctrl.setValue(option);
      console.log(option);
    }
  },[option]);
  
  // sample page에서 사용 못하게 막기
  const { pathname } = useLocation();

  return (
    pathname.includes('sample') ? 
    <>
      <div className='side-panel-normal'>
        <EmptyDiv label='login required' />
      </div>  
      <div className='side-panel-expand'>
        <EmptyDiv label='login required' />
      </div>  
    </>
    : 
    <>
      <Container className='side-panel-normal'>
        <h3 className='a11y-hidden'>normal: option section</h3>
        <FineTunerOptionSection 
          nodeId={ctrl.nodeId} 
          initOption={initOption} 
          option={option} 
          setOption={setOption} 
          loading={loading} 
          setLoading={setLoading} 
          setPanelMode={setPanelMode}
          setSelectedTab={setSelectedTab}
        />
      </Container >
      <Container className='side-panel-expand' $theme={theme}>
        <h3 className='a11y-hidden'>expand: correction and result</h3>
        <header className='trainer-tab-header'>
          <button 
            type="button" 
            className={`fine-tuner-tab-header-btn correction ${selectedTab === "correction" ? 'active' : ''}`} 
            onClick={() => setSelectedTab("correction")}
          >
            Correction
          </button>
          <button 
            type="button" 
            className={`fine-tuner-tab-header-btn trainResult ${selectedTab === "trainResult" ? 'active' : ''}`} 
            onClick={() => setSelectedTab("trainResult")}
          >
            Train Result
          </button>
        </header>

        {
          selectedTab === "correction" 
          ? <FineTunerCorrectionTab />
          : <TrainResultTab />
        }
      </Container>
    </> 
  )
}
