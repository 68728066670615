import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import API from "../../../../utils/api";
import './viewer.styles.scss';
import { IUSAKey } from "../../../viewer/viewer";
import { default as OSD } from "openseadragon";
import { ViewerControl } from "./Viewer";
import { useParams } from "react-router-dom";
import { editor } from "../../../rete";
import { GearNode, TGearProps } from "../addon/Gear";
import { ParallelCoordinateChart, ScatterChart } from '../../charts';
import '../../../side-panel/side-panel.styles.scss';

export function ViewerPanel({ ctrl, blend }: { ctrl: ViewerControl, blend?: boolean }) {
    const [viewer, setViewer] = useState<OSD.Viewer>();
    const [tiledImage, setTiledImage] = useState<OSD.TiledImage>();
    const [cookies, setCookie] = useCookies(['refresh']);
    const params = useParams();
    const api = new API(cookies);

    const [usaSeq, setUsaSeq] = useState<IUSAKey>(0);
    const [modSeq, setModSeq] = useState<number>(0);

    const [trainFeature, setTrainFeature] = useState<number[][]>([]);
    const [trainFeature_pca, setTrainFeature_pca] = useState<number[][]>([]);
    const [trainLabel, setTrainLabel] = useState<number[]>([]);

    const [testFeature, setTestFeature] = useState<number[][]>([]);
    const [testFeature_pca, setTestFeature_pca] = useState<number[][]>([]);
    const [testLabel, setTestLabel] = useState<number[]>([]);

    const [inputThumbnail, setInputThumbnail] = useState(null);
    const [outputThumbnail, setOutputThumbnail] = useState(null);

    const getViewerKey = async () => {
        try {
            const modRes = await api.post(`/module/mod_seq`, {
                wor_id: params.wor_id,
                effector: ctrl.props.option?.effector
            });
            const modSeq = modRes.data;
            const usaRes = await api.post(`/module/usa_seq`, {
                mod_seq: modSeq,
                img_paths: ctrl.props.option?.img_paths,
            });
            const usaSeq = usaRes.data;
            setModSeq(modSeq);
            setUsaSeq(usaSeq);
            if (ctrl.addon.enable && ctrl.addon.name === 'feature')
                await getFeature(usaSeq);
            else
                await getThumbnail(usaSeq);
        } catch (err) {
        }
    }

    //feature 생성 API
    const getFeature = async (seq) => {
        const response = await api.get('/viewer/feature/' + seq);
        setTrainFeature(response.data.train_feature);
        setTrainFeature_pca(response.data.train_feature_pca);
        setTrainLabel(response.data.train_label);
        setTestFeature(response.data.test_feature);
        setTestFeature_pca(response.data.test_feature_pca);
        setTestLabel(response.data.test_label);
    }

    const getThumbnail = async (seq) => {
        const response = await api.get('/viewer/thumbnail/' + seq);
        setInputThumbnail(response.data.input_thumbnail.base64);
        setOutputThumbnail(response.data.output_thumbnail.base64);
    }

    useEffect(() => {
        setInputThumbnail(null);
        setOutputThumbnail(null);
        getViewerKey();
    }, [ctrl.nodeId])

    useEffect(() => {
        if (usaSeq === 0) return;
        const prefix = blend ? "/viewer/blend/" : "/viewer/";
        const src = process.env.REACT_APP_VIENCE_API_KEY + prefix + usaSeq + "/img.dzi";
        const osdViewer = new OSD.Viewer({
            id: "osd-viewer",
            prefixUrl: "openseadragon-images/",
            tileSources: src,
            crossOriginPolicy: "Anonymous",
            animationTime: 0.5,
            blendTime: 0.1,
            constrainDuringPan: true,
            maxZoomPixelRatio: 50,
            minZoomLevel: 0.1,
            visibilityRatio: 1,
            zoomPerClick: 1,
            zoomPerScroll: 2,
            showNavigator: true,
            navigatorWidth: '250px',
            navigatorHeight: '250px',
            showFullPageControl: false,
            showHomeControl: false,
            showZoomControl: false,
        });

        osdViewer.addHandler('canvas-contextmenu', (e) => { e.originalEvent.preventDefault(); })
        osdViewer.addHandler('open', (e) => {
            setTiledImage(e.eventSource.world.getItemAt(0));
        })
        osdViewer.addHandler('open-failed', (e) => {
            if (e.message.includes('402')) {
                console.log('402');
            }
        }
        )

        setViewer(osdViewer);
        return () => osdViewer.destroy();
    }, [usaSeq]);

    return (
        <>
            <div className="side-panel-normal">
                {
                    (ctrl.addon.enable && ctrl.addon.name === 'feature') ?
                        //plot 들어갈 곳 ppt 참고
                        <div className="viewer-panel-image-container">
                            {testLabel.length > 0 && <ParallelCoordinateChart width={400} height={600} train_data={trainFeature} test_data={testFeature} train_labels={trainLabel} test_labels={testLabel} />}
                            {testLabel.length > 0 &&
                                <ScatterChart
                                    width={400}
                                    height={400}
                                    train_data={trainFeature_pca.map((feature) => ({ x: feature[0], y: feature[1] }))}
                                    test_data={testFeature_pca.map((feature) => ({ x: feature[0], y: feature[1] }))}
                                    train_labels={trainLabel}
                                    test_labels={testLabel}
                                />
                            }
                        </div>
                        :
                        (inputThumbnail !== null && outputThumbnail !== null) ?
                            // 기본 이미지, 결과 이미지 ppt 참고
                            <div className="viewer-panel-image-container">
                                <div className="viewer-panel-image">
                                    <img src={`data:image/jpeg;base64,${inputThumbnail}`} style={{ objectFit: 'contain' }} alt="input 썸네일 이미지"/>
                                </div>
                                <div className="viewer-panel-image">
                                    <img src={`data:image/jpeg;base64,${outputThumbnail}`} style={{ objectFit: 'contain' }} alt="output 썸네일 이미지"/>
                                </div>
                            </div>
                            :
                            <div>
                            </div>
                }
            </div >
            <div className="side-panel-expand">
                <div id="osd-viewer" />
            </div>
        </>
    );
}
