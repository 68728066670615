/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const AlignUnet1 = ({ className }) => {
  return (
    <svg
      className={`align-unet-1 ${className}`}
      fill="none"
      height="44"
      viewBox="0 0 44 44"
      width="44"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect className="rect" height="19" rx="1.5" stroke="#65646A" width="3" x="9.5" y="10.5" />
      <rect className="rect" height="9" rx="1.5" stroke="#65646A" width="3" x="23.5" y="24.5" />
      <rect className="rect" height="9" rx="1.5" stroke="#65646A" width="3" x="16.5" y="24.5" />
      <rect className="rect" height="19" rx="1.5" stroke="#65646A" width="3" x="30.5" y="10.5" />
      <path className="path" d="M13 20L30 20" stroke="#65646A" />
      <path className="path" d="M20 29L23 29" stroke="#65646A" />
      <path
        className="path"
        d="M12 20C13.8462 20 14.4615 21.0588 14.4615 22.6471C14.4615 24.2353 14.4615 25.6647 14.4615 27.1471C14.4615 28.6294 15.4872 29 16 29"
        stroke="#65646A"
      />
      <path
        className="path"
        d="M30 20C28.6154 20 28.1538 21.0588 28.1538 22.6471C28.1538 24.2353 28.1538 25.6647 28.1538 27.1471C28.1538 28.6294 27.3846 29 27 29"
        stroke="#65646A"
      />
    </svg>
  );
};
