// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.node-context-menu {
  position: absolute;
  width: 150px;
  border-radius: 10px;
  padding: 15px;
  background: #0d0d31;
  right: -150px;
  z-index: 10;
}

body.light .node-context-menu {
  background: #e9ebf7;
  color: #424242;
}
body.light .node-context-menu:hover {
  color: #4A17B6;
}
body.light .context-menu-container {
  border: 1px #D9D9E5 solid;
  background-color: #F5F6FC;
}
body.light .context-menu-subcontext-container {
  border: 1px #D9D9E5 solid;
  background-color: #F5F6FC;
}`, "",{"version":3,"sources":["webpack://./src/rete-mlops/style/CustomNode.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,YAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,aAAA;EACA,WAAA;AACJ;;AAIQ;EACI,mBAAA;EACA,cAAA;AADZ;AAEY;EACI,cAAA;AAAhB;AAIQ;EACI,yBAAA;EACA,yBAAA;AAFZ;AAKQ;EACI,yBAAA;EACA,yBAAA;AAHZ","sourcesContent":[".node-context-menu {\n    position: absolute;\n    width: 150px;\n    border-radius: 10px;\n    padding: 15px;\n    background: #0d0d31;\n    right: -150px;\n    z-index: 10;\n}\n\nbody {\n    &.light {\n        .node-context-menu {\n            background: #e9ebf7;\n            color: #424242;\n            &:hover {\n                color: #4A17B6;\n            }\n        }\n        \n        .context-menu-container {\n            border: 1px #D9D9E5 solid;\n            background-color: #F5F6FC;\n        }\n        \n        .context-menu-subcontext-container {\n            border: 1px #D9D9E5 solid;\n            background-color: #F5F6FC;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
